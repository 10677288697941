import React from "react";
import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from "@material-ui/core";
import QrCodesListItem from "./QrCodesListItem";
import PropTypes from "prop-types";

const QrCodesList = ({ handleEditItem, handleDeleteItem, qrCodes }) =>
  qrCodes.length === 0 ? (
    <Paper className="p-4 text-center">لا يوجد</Paper>
  ) : (
    <TableContainer component={Paper} className="table-responsive">
      <Table className="table table-fixed">
        <TableHead>
          <TableRow className="admins__theader">
            <TableCell align="center">#</TableCell>
            <TableCell align="center">الاسم</TableCell>
            <TableCell align="center">الرابط</TableCell>
            <TableCell align="center">QR Code</TableCell>
            <TableCell align="center">افعال</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {qrCodes.map((item, index) => (
            <QrCodesListItem
              key={item.id}
              index={index + 1}
              qrCode={item}
              handleEditItem={() => handleEditItem(item.id)}
              handleDeleteItem={() => handleDeleteItem(item.id)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
QrCodesList.propTypes = {
  handleDeleteItem: PropTypes.func.isRequired,
  handleEditItem: PropTypes.func.isRequired,
  qrCodes: PropTypes.array.isRequired,
};

export default QrCodesList;
