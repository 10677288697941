import React, { Component } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CloseButton from "./CloseButton";
import { handleCreateCountry } from "../../actions/countries";

class AddCountryModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  state = {
    country_ar: "",
    country_en: "",
    dialCode: "",
    symbol: "",
    submitDisabled: true,
  };

  handleSubmitDisabled = () => {
    const { country_ar, country_en, submitDisabled, dialCode, symbol } = this.state;
    if (!country_ar || !country_en || !dialCode || (!symbol && !submitDisabled))
      this.setState({ submitDisabled: true });
    if (country_ar && country_en && dialCode && symbol && submitDisabled) this.setState({ submitDisabled: false });
  };
  handleCountryArChange = (e) => {
    this.setState({ country_ar: e.target.value }, this.handleSubmitDisabled);
  };
  handleCountryEnChange = (e) => {
    this.setState({ country_en: e.target.value }, this.handleSubmitDisabled);
  };
  handleDialCodeChange = (e) => {
    this.setState({ dialCode: e.target.value }, this.handleSubmitDisabled);
  };
  handleSymbolChange = (e) => {
    this.setState({ symbol: e.target.value }, this.handleSubmitDisabled);
  };

  handleSubmit = () => {
    const { createCountry, handleClose } = this.props;
    const { country_ar, country_en, dialCode, symbol } = this.state;
    const data = {
      name: {
        ar: country_ar,
        en: country_en,
      },
      dial_code: dialCode,
      symbol,
    };
    createCountry(data);
    handleClose();
  };

  render() {
    const { open, handleClose } = this.props;
    const { country_ar, country_en, submitDisabled, dialCode, symbol } = this.state;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: "dialog",
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <CloseButton handleClose={handleClose} />
        <DialogTitle id="responsive-dialog-title" className="text-center">
          إضافة دولة
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <TextField
                  type="text"
                  placeholder="اسم الدولة بالعربية"
                  className="form-control"
                  required
                  value={country_ar}
                  onChange={this.handleCountryArChange}
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <TextField
                  type="text"
                  placeholder="اسم الدولة بالانجليزية"
                  className="form-control"
                  required
                  value={country_en}
                  onChange={this.handleCountryEnChange}
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <TextField
              type="text"
              placeholder="رمز الاتصال"
              className="form-control"
              required
              value={dialCode}
              onChange={this.handleDialCodeChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              type="text"
              placeholder="رمز الدولة"
              className="form-control"
              required
              value={symbol}
              onChange={this.handleSymbolChange}
              variant="outlined"
              size="small"
            />
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center align-items-center">
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
            className="dialog__btn"
            disabled={submitDisabled}
          >
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createCountry: (data) => dispatch(handleCreateCountry(data)),
});

export default connect(null, mapDispatchToProps)(AddCountryModal);
