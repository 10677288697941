import { showLoading, hideLoading } from "react-redux-loading-bar";
import {
  getJobApplications,
  destroyJobApplication,
  getJobApplicationDetails,
  getJobApplicationsPage,
} from "../utils/api";
import { showMessageAlert } from "./messageAlert";

export const RECEIVE_JOB_APPLICATIONS = "RECEIVE_JOB_APPLICATIONS";
export const DELETE_JOB_APPLICATION = "DELETE_JOB_APPLICATION";
export const GET_JOB_APPLICATION_DETAILS = "GET_JOB_APPLICATION_DETAILS";
export const PAGINATE_JOB_APPLICATIONS = "PAGINATE_JOB_APPLICATIONS";

const receiveJobApplications = (jobApplications) => ({
  type: RECEIVE_JOB_APPLICATIONS,
  jobApplications,
});

const deleteJobApplication = (id) => ({
  type: DELETE_JOB_APPLICATION,
  id,
});

const receiveJobApplicationDetails = (jobApplication) => ({
  type: GET_JOB_APPLICATION_DETAILS,
  jobApplication,
});

const paginateJobApplications = (jobApplications) => ({
  type: PAGINATE_JOB_APPLICATIONS,
  jobApplications,
});

export const handleReceiveJobApplications =
  (key = null, value = null) =>
  (dispatch) => {
    dispatch(showLoading());
    return getJobApplications(key, value)
      .then((res) => res.json())
      .then((res) => {
        dispatch(receiveJobApplications(res.data));
        dispatch(hideLoading());
        return res;
      })
      .catch((e) => {
        dispatch(showMessageAlert(e.message, "error"));
        dispatch(hideLoading());
      });
  };

export const handlePaginateJobApplications =
  (page, key = null, value = null) =>
  (dispatch) => {
    dispatch(showLoading());
    return getJobApplicationsPage(page, key, value)
      .then((res) => res.json())
      .then((res) => {
        dispatch(paginateJobApplications(res.data));
        dispatch(hideLoading());
        return res;
      })
      .catch((e) => {
        dispatch(showMessageAlert(e.message, "error"));
        dispatch(hideLoading());
      });
  };

export const handleDeleteJobApplication = (id) => (dispatch) => {
  dispatch(showLoading());
  destroyJobApplication(id)
    .then((res) => res.json())
    .then((res) => {
      dispatch(deleteJobApplication(id));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم حذف الطلب بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleReceiveJobApplicationDetails = (id) => (dispatch) => {
  dispatch(showLoading());
  getJobApplicationDetails(id)
    .then((res) => res.json())
    .then((res) => {
      dispatch(receiveJobApplicationDetails(res.data));
      dispatch(hideLoading());
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};
