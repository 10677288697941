import React from "react";
import ImageUpload from "./ImageUpload";
import PropTypes from "prop-types";

const ProjectPictures = ({ imagePaths, files, imageLoaders, handleChangeFirstPicture, handleChangeSecondPicture }) => (
  <div className="row p-3 justify-content-center align-items-center">
    <div className="col-12 mb-4 text-center">
      <h5 className="text-theme-primary mb-0 page-subtitle-lead">صور المشروع</h5>
    </div>
    <div className="col-12 col-md-6 col-lg-4 image-upload-container mb-4">
      <ImageUpload
        image={imagePaths ? imagePaths[0] : null}
        files={files[0]}
        setFiles={handleChangeFirstPicture}
        loading={imageLoaders[0]}
      />
    </div>
    <div className="col-12 col-md-6 col-lg-4 image-upload-container mb-4">
      <ImageUpload
        image={imagePaths ? imagePaths[1] || null : null}
        files={files[1]}
        setFiles={handleChangeSecondPicture}
        loading={imageLoaders[1]}
      />
    </div>
  </div>
);

ProjectPictures.propTypes = {
  files: PropTypes.array.isRequired,
  handleChangeFirstPicture: PropTypes.func.isRequired,
  handleChangeSecondPicture: PropTypes.func.isRequired,
};

export default ProjectPictures;
