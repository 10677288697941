import React, { Component } from "react";
import Layout from "../base/Layout";
// import ConfirmDeleteModal from "../modals/ConfirmDeleteModal"
import AddAdminModal from "../modals/AddAdmin";
import EditAdminModal from "../modals/EditAdminModal";
import AdminsList from "../admins/adminsList";
import { Pagination } from "@material-ui/lab";
import { connect } from "react-redux";
import {
  handleReceiveAdmins,
  // handleDeleteAdmin,
  handlePaginateAdmin,
} from "../../actions/admins";
import { handleReceiveRoles } from "../../actions/roles";

class Admins extends Component {
  state = {
    openAddDialog: false,
    openDeleteDialog: false,
    deleteId: null,
    openEditDialog: false,
    editId: null,
    pagination: {
      per_page: 15,
      current_page: 1,
      last_page: 1,
    },
    paginationDisabled: false,
  };
  handleOpenAddDialog = () => {
    this.setState({ openAddDialog: true });
  };
  handleCloseAddDialog = () => {
    this.setState({ openAddDialog: false });
  };
  handleOpenDeleteDialog = (id) => {
    this.setState({ openDeleteDialog: true, deleteId: id });
  };
  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDialog: false, deleteId: null });
  };
  handleOpenEditDialog = (id) => {
    this.setState({ openEditDialog: true, editId: id });
  };
  handleCloseEditDialog = () => {
    this.setState({ openEditDialog: false, editId: null });
  };

  handleConfirmDelete = () => {
    const { deleteAdmin } = this.props;
    const { deleteId } = this.state;
    deleteAdmin(deleteId);
  };

  handlePaginationChange = (e, current_page) => {
    const { last_page } = this.state.pagination;
    const { getAdminsPagination } = this.props;
    if (last_page >= current_page) {
      this.setState({ paginationDisabled: true });
      getAdminsPagination(current_page).then(() => {
        this.setState((prevState) => ({
          pagination: { ...prevState.pagination, current_page },
          paginationDisabled: false,
        }));
      });
    }
  };

  componentDidMount() {
    const { getAdmins, getRoles } = this.props;
    getAdmins().then((res) => {
      if (res) {
        this.setState({
          pagination: { ...this.state.pagination, last_page: res.meta.last_page },
        });
      }
    });
    getRoles();
  }

  render() {
    const {
      openAddDialog,
      openDeleteDialog,
      openEditDialog,
      editId,
      pagination: { current_page, last_page, per_page },
      paginationDisabled,
    } = this.state;
    const {
      admins,
      user: { permissions, is_super_admin },
    } = this.props;
    return (
      <Layout
        title="مشرفي الموقع"
        addBtnText="إضافة مشرف"
        showAddBtn={is_super_admin || permissions.includes(2)}
        handleBtnClicked={this.handleOpenAddDialog}
      >
        <AdminsList
          // handleDeleteItem={this.handleOpenDeleteDialog}
          handleEditItem={this.handleOpenEditDialog}
          admins={admins}
        />
        {admins && (
          <div className="my-3 d-flex justify-content-center align-items-center flex-grow-1">
            <Pagination
              variant="outlined"
              shape="rounded"
              count={last_page}
              page={current_page}
              boundaryCount={2}
              onChange={this.handlePaginationChange}
              disabled={paginationDisabled}
            />
          </div>
        )}
        {is_super_admin || permissions.includes(2) ? (
          <AddAdminModal open={openAddDialog} handleClose={this.handleCloseAddDialog} />
        ) : (
          ""
        )}

        <EditAdminModal open={openEditDialog} handleClose={this.handleCloseEditDialog} editId={editId} />
        {/* <ConfirmDeleteModal
          open={openDeleteDialog}
          handleClose={this.handleCloseDeleteDialog}
          text="هل تريد حذف المدير"
          handleDelete={this.handleConfirmDelete}
        /> */}
      </Layout>
    );
  }
}

const mapStateToProps = ({ admins, user }) => ({ admins, user });

const mapDispatchToProps = (dispatch) => ({
  getAdmins: () => dispatch(handleReceiveAdmins()),
  getRoles: () => dispatch(handleReceiveRoles()),
  // deleteAdmin: (id) => dispatch(handleDeleteAdmin(id)),
  getAdminsPagination: (page) => dispatch(handlePaginateAdmin(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admins);
