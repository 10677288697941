import { showLoading, hideLoading } from "react-redux-loading-bar";
import {
  getPriceRequestNotes,
  storePriceRequestNote,
  destroyPriceRequestNote,
  updatePriceRequestNote,
} from "../utils/api";
import { showMessageAlert } from "./messageAlert";

export const RECEIVE_PRICE_REQUEST_NOTES = "RECEIVE_PRICE_REQUEST_NOTES";
export const CREATE_PRICE_REQUEST_NOTE = "CREATE_PRICE_REQUEST_NOTE";
export const EDIT_PRICE_REQUEST_NOTE = "EDIT_PRICE_REQUEST_NOTE";
export const DELETE_PRICE_REQUEST_NOTE = "DELETE_PRICE_REQUEST_NOTE";

const receivePriceRequestNotes = (priceRequestId, notes) => ({
  type: RECEIVE_PRICE_REQUEST_NOTES,
  priceRequestId,
  notes,
});

const createPriceRequestNote = (priceRequestId, note) => ({
  type: CREATE_PRICE_REQUEST_NOTE,
  priceRequestId,
  note,
});

const editPriceRequestNote = (priceRequestId, noteId, note) => ({
  type: EDIT_PRICE_REQUEST_NOTE,
  priceRequestId,
  noteId,
  note,
});

const deletePriceRequestNote = (priceRequestId, noteId) => ({
  type: DELETE_PRICE_REQUEST_NOTE,
  priceRequestId,
  noteId,
});

export const handleReceivePriceRequestNotes = (priceRequestId) => (dispatch) => {
  dispatch(showLoading());
  getPriceRequestNotes(priceRequestId)
    .then((res) => res.json())
    .then((res) => {
      dispatch(receivePriceRequestNotes(priceRequestId, res.data));
      dispatch(hideLoading());
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleCreatePriceRequestNote = (data) => (dispatch) => {
  dispatch(showLoading());
  storePriceRequestNote(data)
    .then((res) => res.json())
    .then((res) => {
      const { pricing_request, content, created_at, id } = res.data;
      dispatch(
        createPriceRequestNote(pricing_request.id, {
          id,
          content,
          created_at,
        })
      );
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم إضافة الملاحظة بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleEditPriceRequestNote = (priceRequestId, noteId, note) => (dispatch) => {
  dispatch(showLoading());
  updatePriceRequestNote(noteId, note)
    .then((res) => res.json())
    .then((res) => {
      const { pricing_request, content, created_at, id } = res.data;
      dispatch(
        editPriceRequestNote(pricing_request.id, id, {
          id,
          content,
          created_at,
        })
      );
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم تعديل الملاحظة بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleDeletePriceRequestNote = (priceRequestId, noteId) => (dispatch) => {
  dispatch(showLoading());
  destroyPriceRequestNote(noteId)
    .then((res) => res.json())
    .then((res) => {
      dispatch(deletePriceRequestNote(priceRequestId, noteId));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم حذف الملاحظة بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};
