import {
  RECEIVE_PROJECTS,
  RECEIVE_PROJECT,
  CREATE_PROJECT,
  EDIT_PROJECT,
  DELETE_PROJECT,
  PAGINATE_PROJECTS,
} from "../actions/projects";
import sortBy from "sort-by";

export default function projects(state = [], action) {
  switch (action.type) {
    case RECEIVE_PROJECTS:
      return action.projects;
    case RECEIVE_PROJECT: {
      const stateSlice = state.slice();
      stateSlice.push(action.project);
      return stateSlice;
    }
    case PAGINATE_PROJECTS: {
      return action.projects;
    }
    case CREATE_PROJECT:
      return [action.project, ...state];
    case EDIT_PROJECT:
      return state.map((project) => (project.id === action.id ? Object.assign(project, action.data) : project));
    case DELETE_PROJECT:
      return state.filter((project) => project.id !== action.id);
    default:
      return state;
  }
}
