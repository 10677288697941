import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";

const UploadedImage = ({ image }) => (
  <div className="position-relative">
    <img src={image} className="media-dropzone__preview-item" alt="news upload" />
  </div>
);

function ArticlesImageUpload({ files, setFiles, loading, image }) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div key={file.preview} className="position-relative">
      <img src={file.preview} className="media-dropzone__preview-item" alt="news upload" />
      {loading && (
        <div className="thumb-uploader">
          <div className="thumb-uploader__inner">
            <CircularProgress size={10} />
          </div>
        </div>
      )}
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <div>
      <div {...getRootProps({ className: "media-dropzone" })}>
        <input {...getInputProps()} name="news-image" />
        <p className="media-dropzone__help-text">اختر صورة؛ أو إسحب وافلت الصورة</p>
      </div>
      <aside className="media-dropzone__preview">
        {image && !files.length ? <UploadedImage image={image} /> : thumbs}
      </aside>
    </div>
  );
}

ArticlesImageUpload.propTypes = {
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired,
};

export default ArticlesImageUpload;
