import { RECEIVE_STATISTICS } from "../actions/statistics";

export default function statistics(state = {}, action) {
  switch (action.type) {
    case RECEIVE_STATISTICS:
      return action.statistics;
    default:
      return state;
  }
}
