import React, { Component } from "react";
import { Button, Table, TableRow, TableCell, TableBody } from "@material-ui/core";
import EditJobModal from "../modals/EditJobModal";
import { connect } from "react-redux";
import { handleReceiveJobDetails } from "../../actions/jobs";

class JobDetails extends Component {
  state = {
    openEditDialog: false,
  };

  handleOpenEditDialog = () => {
    this.setState({ openEditDialog: true });
  };
  handleCloseEditDialog = () => {
    this.setState({ openEditDialog: false });
  };

  componentDidMount() {
    const { targetJob, jobId, getJobDetails } = this.props;
    if (!targetJob) getJobDetails(jobId);
  }

  render() {
    const { openEditDialog } = this.state;
    const {
      title,
      description,
      location,
      jobId,
      user: { permissions, is_super_admin },
    } = this.props;

    return (
      <div className="pb-5 container overflow-hidden">
        <div className="row px-3 pt-3 main-header">
          <div className="col-12">
            <h2 className="page-title">الوظائف</h2>
          </div>
        </div>
        <div className="row p-3">
          <div className="col d-flex align-items-center">
            <h5 className="mb-0 ml-3 page-subtitle-lead">تفاصيل الوظيفة</h5>

            {is_super_admin || permissions.includes(21) ? (
              <Button color="primary" variant="text" onClick={this.handleOpenEditDialog}>
                تعديل
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          <div className="d-flex px-3">
            <Table className="table-borderless table-responsive job-details__table card__subtitle">
              <TableBody>
                <TableRow>
                  <TableCell align="right">عنوان الوظيفة</TableCell>
                  <TableCell align="right">{title || "لوريم ايبسوم"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right">تفاصيل الوظيفة</TableCell>
                  <TableCell align="right">
                    {description ||
                      `لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على
                    العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم
                    مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج
                    مواقع انترنت`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right">المكان</TableCell>
                  <TableCell align="right">{location || "المملكة العربية السعودية"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
        <EditJobModal
          open={openEditDialog}
          handleClose={this.handleCloseEditDialog}
          title="تعديل الوظيفة"
          editId={jobId}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ jobs, user }, props) => {
  const jobId = parseInt(props.match.params.id);
  const targetJob = jobs.find((item) => item.id === jobId);
  const title = targetJob ? targetJob.title.ar : "";
  const description = targetJob ? targetJob.desc.ar : "";
  const location = targetJob ? targetJob.location.ar : "";
  return { targetJob, title, description, location, jobId, user };
};

const mapDispatchToProps = (dispatch) => ({
  getJobDetails: (id) => dispatch(handleReceiveJobDetails(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobDetails);
