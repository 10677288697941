import { showLoading, hideLoading } from "react-redux-loading-bar";
import { getArticles, storeArticle, updateArticle, destroyArticle } from "../utils/api";
import { showMessageAlert } from "./messageAlert";

export const RECEIVE_ARTICLES = "RECEIVE_ARTICLES";
export const CREATE_ARTICLE = "CREATE_ARTICLE";
export const EDIT_ARTICLE = "EDIT_ARTICLE";
export const DELETE_ARTICLE = "DELETE_ARTICLE";

const receiveArticles = (articles) => ({
  type: RECEIVE_ARTICLES,
  articles,
});

const createArticle = (article) => ({
  type: CREATE_ARTICLE,
  article,
});

const editArticle = (id, data) => ({
  type: EDIT_ARTICLE,
  id,
  data,
});

const deleteArticle = (id) => ({
  type: DELETE_ARTICLE,
  id,
});

export const handleReceiveArticles = (page) => (dispatch) => {
  dispatch(showLoading());
  return getArticles(page)
    .then((res) => res.json())
    .then((res) => {
      dispatch(receiveArticles(res.data));
      dispatch(hideLoading());
      return res;
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleCreateArticle = (data) => (dispatch) => {
  dispatch(showLoading());
  storeArticle(data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(createArticle(res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم إضافة المقال بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleEditArticle = (id, data) => (dispatch) => {
  dispatch(showLoading());
  updateArticle(id, data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(editArticle(id, res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم تعديل المقال بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleDeleteArticle = (id) => (dispatch) => {
  dispatch(showLoading());
  destroyArticle(id)
    .then((res) => res.json())
    .then((res) => {
      dispatch(deleteArticle(id));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم حذف الخبر بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};
