import React, { Component } from "react";
import {
  Assignment,
  FilterList,
  /* AssignmentTurnedIn,
  AssignmentLate,
  Code, */
} from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import JobsCard from "../jobs/JobsCard";
// import JobsRequestsSearch from "../job-requests/JobRequestsSearch"
import { Paper, Menu, MenuItem } from "@material-ui/core";
import PriceRequestsTable from "../price-requsets/PriceRequestsTable";
//import CardDropdown from "../jobs/CardDropdown"
//import PriceCardDropdownList from "../price-requsets/PriceCardDropdownList"
import { connect } from "react-redux";
import { handleReceivePriceRequests, handlePaginatePriceRequests } from "../../actions/priceRequests";
import PropTypes from "prop-types";

// from frontend
const services = [
  "اسم النطاق",
  "استشارة",
  "استضافة المواقع",
  "تطبيقات الهواتف الذكية",
  "برمجة البوابات الالكترونية",
  "اختبار",
  "أخرى",
];

class PriceRequests extends Component {
  state = {
    pagination: {
      per_page: 15,
      current_page: 1,
      last_page: 1,
      total: 0,
    },
    paginationDisabled: false,
    // the diff between searchVal and search_term is that searchVal is the search input value
    // on the other hand search_term is the actual search submitted to the backend
    searchVal: "",
    search_term: "",
    // filter menu anchor element
    anchorEl: null,
    filterBy: null,
  };

  handlePaginationChange = (e, current_page) => {
    const { last_page } = this.state.pagination;
    const { filterBy, search_term } = this.state;
    const { getPriceRequestsPagination } = this.props;
    if (last_page >= current_page) {
      this.setState({ paginationDisabled: true });

      // paginate based on filter or search if any of them were applied
      let key = null,
        value = null;
      if (filterBy) {
        key = "service_name";
        value = filterBy;
      } else if (search_term) {
        key = "key";
        value = search_term;
      }

      getPriceRequestsPagination(current_page, key, value).then(() => {
        this.setState((prevState) => ({
          pagination: { ...prevState.pagination, current_page },
          paginationDisabled: false,
        }));
      });
    }
  };

  componentDidMount() {
    const { getPriceRequests } = this.props;
    getPriceRequests().then((res) => {
      if (res) {
        const {
          meta: { per_page, current_page, last_page, total },
        } = res;
        this.setState({
          pagination: {
            per_page,
            current_page,
            last_page,
            total,
          },
        });
      }
    });
  }

  handleInputChange = (evt, callback = null) => {
    this.setState(
      {
        [evt.target.name]: evt.target.value,
      },
      callback
    );
  };

  handleSearchChange = (evt) => {
    function callback() {
      // reset price-requests data when searchVal is cleared
      if (this.state.searchVal === "") {
        this.setState({ search_term: "" });
        this.componentDidMount();
      }
    }
    this.handleInputChange(evt, callback);
  };

  handleSearchSubmit = (evt) => {
    evt.preventDefault();
    const { getPriceRequests } = this.props;
    const { searchVal } = this.state;

    // don't send request if there is no searchVal
    if (!searchVal.trim()) {
      return;
    }
    const search_term = searchVal;
    getPriceRequests("key", search_term).then((res) => {
      if (res) {
        const {
          meta: { per_page, current_page, last_page, total },
        } = res;
        this.setState({
          search_term,
          pagination: {
            per_page,
            current_page,
            last_page,
            total,
          },
        });
      }
    });
    // reset any filter if there any were applied to not confuse the user
    if (this.state.filterBy) {
      this.setState({ filterBy: "" });
    }
  };

  handleMenuClose = (evt) => {
    this.setState({ anchorEl: null });
  };

  handleMenuOpen = (evt) => {
    this.setState({ anchorEl: evt.currentTarget });
  };

  handleFiltering = (evt) => {
    const { getPriceRequests } = this.props;
    const serviceName = evt.target.textContent;
    if (serviceName === this.state.filterBy) {
      // reset price-requests data when clicking on an active filter
      this.setState({ filterBy: null });
      this.componentDidMount();
    } else {
      this.setState({ filterBy: serviceName });
      getPriceRequests("service_name", serviceName).then((res) => {
        if (res) {
          const {
            meta: { per_page, current_page, last_page, total },
          } = res;
          this.setState({
            pagination: {
              per_page,
              current_page,
              last_page,
              total,
            },
          });
        }
      });
      // reset any search behaviour
      if (this.state.searchVal) {
        this.setState({ searchVal: "", search_term: "" });
      }
    }
    this.handleMenuClose();
  };

  render() {
    const { priceRequests } = this.props;
    const {
      pagination: { current_page, last_page, total },
      paginationDisabled,
      searchVal,
      anchorEl,
      filterBy,
    } = this.state;

    // const paginatedPriceRequests =
    //   priceRequests.length >= current_page * per_page
    //     ? priceRequests.slice(
    //       (current_page - 1) * per_page,
    //       current_page * per_page - 1
    //     )
    //     : priceRequests.slice(-per_page);

    return (
      <div className="pb-5 container overflow-hidden">
        <div className="row px-3 pt-3 main-header">
          <div className="col-12">
            <h2 className="page-title">طلبات الاسعار</h2>
          </div>
        </div>
        <div className="row p-3">
          <h3 className="mb-3 page-subtitle">نظرة عامة</h3>
        </div>
        <div className="row p-3">
          <JobsCard title="عدد طلبات الاسعار" count={total}>
            <Assignment fontSize="large" />
          </JobsCard>
          {/* <JobsCard title="عدد الطلبات التي تم الرد عليها" count={150}>
            <AssignmentTurnedIn fontSize="large" />
          </JobsCard>
          <JobsCard title="عدد الطلبات التي لم يتم الرد عليها" count={150}>
            <AssignmentLate fontSize="large" />
          </JobsCard>
          <CardDropdown title="عدد طلبات برمجة صفحات الويب" count={150}>
            <Code fontSize="large" />
            <PriceCardDropdownList />
          </CardDropdown> */}
        </div>
        <div className="row p-3">
          <div className="col-12">
            <h5 className="card__subtitle">طلبات الاسعار</h5>
          </div>
        </div>
        <div className="px-3">
          <Paper className="search-paper" square>
            <div className="jobs-requests__search">
              <form className="col-12 col-md-6" onSubmit={this.handleSearchSubmit}>
                <input
                  type="search"
                  placeholder="ابحث عن الاسم او البريد الالكتروني او الهاتف"
                  className="form-control"
                  value={searchVal}
                  name="searchVal"
                  onChange={this.handleSearchChange}
                />
              </form>
              <div className="col-12 col-md-6">
                <button
                  className="btn btn-primary jobs-requests__filter"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={this.handleMenuOpen}
                >
                  <FilterList /> <span className="mr-2">ترشيح</span>
                </button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={this.handleMenuClose}
                >
                  {services.map((val) => (
                    <MenuItem key={val} onClick={this.handleFiltering} selected={filterBy === val}>
                      {val}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </div>
          </Paper>
        </div>
        <div className="row px-3 pb-3">
          <div className="col-12">
            <PriceRequestsTable priceRequests={priceRequests} pagination={this.state.pagination} />
          </div>
        </div>
        {priceRequests && (
          <div className="my-3 d-flex justify-content-center align-items-center flex-grow-1">
            <Pagination
              variant="outlined"
              shape="rounded"
              count={last_page}
              page={current_page}
              boundaryCount={2}
              onChange={this.handlePaginationChange}
              disabled={paginationDisabled}
            />
          </div>
        )}
      </div>
    );
  }
}

PriceRequests.propTypes = {
  priceRequests: PropTypes.array.isRequired,
  getPriceRequests: PropTypes.func.isRequired,
};

const mapStateToProps = ({ priceRequests }) => ({ priceRequests });

const mapDispatchToProps = (dispatch) => ({
  getPriceRequests: (key, value) => dispatch(handleReceivePriceRequests(key, value)),
  getPriceRequestsPagination: (page, key, value) => dispatch(handlePaginatePriceRequests(page, key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PriceRequests);
