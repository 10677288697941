import React from "react";
import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from "@material-ui/core";
import ProjectsTableRow from "./ProjectsTableRow";
import PropTypes from "prop-types";

const ProjectsTable = ({ handleEditItem, handleDeleteItem, projects }) =>
  projects.length === 0 ? (
    <Paper className="p-4 text-center">لا توجد مشاريع</Paper>
  ) : (
    <TableContainer component={Paper} className="table-responsive">
      <Table className="table table-fixed slider__list">
        <TableHead>
          <TableRow className="last-news__theader">
            <TableCell align="center">#</TableCell>
            <TableCell align="center">اسم المشروع</TableCell>
            <TableCell align="center">الموقع الإلكتروني</TableCell>
            <TableCell align="center">افعال</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.map((project, index) => (
            <ProjectsTableRow
              key={project.id}
              {...project}
              index={index + 1}
              handleEditItem={() => handleEditItem(project.id)}
              handleDeleteItem={() => handleDeleteItem(project.id)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

ProjectsTable.propTypes = {
  handleEditItem: PropTypes.func.isRequired,
  handleDeleteItem: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired,
};

export default ProjectsTable;
