import React from "react";
import { Paper } from "@material-ui/core";
import PropTypes from "prop-types";

const HomeSliderItem = ({ children, title, count }) => (
  <div className="p-4">
    <Paper className="p-3 w-100 h-100">
      {children}
      <h5 className="my-3 card__subtitle">{title}</h5>
      <h6 className="card__subtitle">{count || 0}</h6>
    </Paper>
  </div>
);

HomeSliderItem.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number,
};

export default HomeSliderItem;
