import { RECEIVE_ROLES, PAGINATE_ROLES, SHOW_ROLE, CREATE_ROLE, EDIT_ROLE, DELETE_ROLE } from "../actions/roles";

export default function roles(state = [], action) {
  switch (action.type) {
    case RECEIVE_ROLES:
      return action.roles;
    case PAGINATE_ROLES:
      return action.roles;
    case SHOW_ROLE: {
      const stateSlice = state.slice();
      const roleId = action.role.id;
      const oldRoleIndex = stateSlice.findIndex((item) => item.id === roleId);
      // if it was already there then just replace it
      if (oldRoleIndex > -1) {
        stateSlice[oldRoleIndex] = action.role;
      } else {
        stateSlice.push(action.role);
      }
      return stateSlice;
    }
    case CREATE_ROLE:
      return [action.role, ...state];
    case EDIT_ROLE:
      return state.map((role) => (role.id === action.id ? Object.assign(role, action.data) : role));
    case DELETE_ROLE:
      return state.filter((role) => role.id !== action.id);
    default:
      return state;
  }
}
