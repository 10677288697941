import React from "react";
import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from "@material-ui/core";
import PriceRequestsTableRow from "./PriceRequestsTableRow";
import PropTypes from "prop-types";

const PriceRequestsTable = ({ priceRequests, pagination }) =>
  priceRequests.length === 0 ? (
    <Paper className="p-4 text-center">لا توجد طلبات</Paper>
  ) : (
    <TableContainer component={Paper} className="table-responsive">
      <Table>
        <TableHead>
          <TableRow className="last-news__theader">
            <TableCell align="center">#</TableCell>
            <TableCell align="center">الأسم</TableCell>
            <TableCell align="center">البريد الالكتروني</TableCell>
            <TableCell align="center">نوع الطلب</TableCell>
            <TableCell align="center">التاريخ</TableCell>
            <TableCell align="center">الاستجابة</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {priceRequests.map((priceRequest, index) => (
            <PriceRequestsTableRow key={priceRequest.id} {...priceRequest} index={index + 1} pagination={pagination} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

PriceRequestsTable.propTypes = {
  priceRequests: PropTypes.array.isRequired,
};

export default PriceRequestsTable;
