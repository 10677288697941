import React from "react";
import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from "@material-ui/core";
import ArticlesTableRow from "./ArticlesTableRow";
import PropTypes from "prop-types";

const ArticlesTable = ({ handleEditItem, handleDeleteItem, articles, pagination }) =>
  articles.length === 0 ? (
    <Paper className="p-4 text-center">لا توجد مقالات</Paper>
  ) : (
    <TableContainer component={Paper} className="table-responsive">
      <Table className="table table-fixed">
        <TableHead>
          <TableRow className="last-news__theader">
            <TableCell align="center">#</TableCell>
            <TableCell align="center">عنوان المقال</TableCell>
            <TableCell align="center">النص</TableCell>
            <TableCell align="center">افعال</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {articles.map((item, index) => (
            <ArticlesTableRow
              {...item}
              index={index + 1}
              handleEditItem={() => handleEditItem(item.id)}
              handleDeleteItem={() => handleDeleteItem(item.id)}
              key={item.id}
              pagination={pagination}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

ArticlesTable.propTypes = {
  handleEditItem: PropTypes.func.isRequired,
  handleDeleteItem: PropTypes.func.isRequired,
  articles: PropTypes.array.isRequired,
};

export default ArticlesTable;
