import React, { Component } from "react";
import {
  Assignment,
  FilterList,
  /* AssignmentTurnedIn,
  AssignmentLate,
  Code, */
} from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { Link, Redirect } from "react-router-dom";
import JobsCard from "../jobs/JobsCard";
import JobsRequestsTable from "../job-requests/JobRequestsTable";
// import JobsRequestsSearch from "../job-requests/JobRequestsSearch"
import { Paper, Menu, MenuItem } from "@material-ui/core";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
//import CardDropdown from "../jobs/CardDropdown"
//import JobsCardDropdownList from "../jobs/JobsCardDropdownList"
import { connect } from "react-redux";
import {
  handleReceiveJobApplications,
  handleDeleteJobApplication,
  handlePaginateJobApplications,
} from "../../actions/jobApplications";
import { handleReceiveJobs } from "../../actions/jobs";

class JobsRequests extends Component {
  state = {
    openDeleteDialog: false,
    deleteId: null,
    pagination: {
      per_page: 15,
      current_page: 1,
      last_page: 1,
    },
    paginationDisabled: false,
    // the diff between searchVal and search_term is that searchVal is the search input value
    // on the other hand search_term is the actual search submitted to the backend
    searchVal: "",
    search_term: "",
    // filter menu anchor element
    anchorEl: null,
    filterBy: null,
  };

  handleOpenDeleteDialog = (id) => {
    this.setState({ openDeleteDialog: true, deleteId: id });
  };
  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDialog: false, deleteId: null });
  };

  handleConfirmDelete = () => {
    const { deleteJobApplication } = this.props;
    const { deleteId } = this.state;
    deleteJobApplication(deleteId);
  };

  handlePaginationChange = (e, current_page) => {
    const { last_page } = this.state.pagination;
    const { filterBy, search_term } = this.state;
    const { getJobApplicationsPagination } = this.props;
    if (last_page >= current_page) {
      this.setState({ paginationDisabled: true });

      // paginate based on filter or search if any of them were applied
      let key = null,
        value = null;
      if (filterBy) {
        key = "service_name";
        value = filterBy;
      } else if (search_term) {
        key = "key";
        value = search_term;
      }

      getJobApplicationsPagination(current_page, key, value).then(() => {
        this.setState((prevState) => ({
          pagination: { ...prevState.pagination, current_page },
          paginationDisabled: false,
        }));
      });
    }
  };

  componentDidMount() {
    const { getJobApplications, jobs, getJobs } = this.props;
    getJobApplications().then((res) => {
      if (res) {
        const {
          meta: { per_page, current_page, last_page, total },
        } = res;
        this.setState({
          pagination: {
            per_page,
            current_page,
            last_page,
            total,
          },
        });
      }
    });
    if (jobs.length === 0) {
      // to show job names in the filter menu
      // this is not a good practice since jobs endpoint has too many info we do not need and has pagination
      // so in the future there should be an endpoint that would return all and only job names
      getJobs();
    }
  }

  handleInputChange = (evt, callback = null) => {
    this.setState(
      {
        [evt.target.name]: evt.target.value,
      },
      callback
    );
  };

  handleSearchChange = (evt) => {
    function callback() {
      // reset price-requests data when searchVal is cleared
      if (this.state.searchVal === "") {
        this.setState({ search_term: "" });
        this.componentDidMount();
      }
    }
    this.handleInputChange(evt, callback);
  };

  handleSearchSubmit = (evt) => {
    evt.preventDefault();
    const { getJobApplications } = this.props;
    const { searchVal } = this.state;

    // don't send request if there is no searchVal
    if (!searchVal.trim()) {
      return;
    }
    const search_term = searchVal;
    getJobApplications("key", search_term).then((res) => {
      if (res) {
        const {
          meta: { per_page, current_page, last_page, total },
        } = res;
        this.setState({
          search_term,
          pagination: {
            per_page,
            current_page,
            last_page,
            total,
          },
        });
      }
    });
    // reset any filter if there any were applied to not confuse the user
    if (this.state.filterBy) {
      this.setState({ filterBy: "" });
    }
  };

  handleMenuClose = (evt) => {
    this.setState({ anchorEl: null });
  };

  handleMenuOpen = (evt) => {
    this.setState({ anchorEl: evt.currentTarget });
  };

  handleFiltering = (evt) => {
    const { getJobApplications } = this.props;
    const jobName = evt.target.textContent;
    if (jobName === this.state.filterBy) {
      // reset price-requests data when clicking on an active filter
      this.setState({ filterBy: null });
      this.componentDidMount();
    } else {
      this.setState({ filterBy: jobName });
      getJobApplications("job_name", jobName).then((res) => {
        if (res) {
          const {
            meta: { per_page, current_page, last_page, total },
          } = res;
          this.setState({
            pagination: {
              per_page,
              current_page,
              last_page,
              total,
            },
          });
        }
      });
      // reset any search behaviour
      if (this.state.searchVal) {
        this.setState({ searchVal: "", search_term: "" });
      }
    }
    this.handleMenuClose();
  };

  render() {
    const {
      jobApplications,
      user: { permissions, is_super_admin },
      jobs,
    } = this.props;
    const {
      openDeleteDialog,
      pagination: { current_page, last_page, total },
      paginationDisabled,
      searchVal,
      anchorEl,
      filterBy,
    } = this.state;
    // const paginatedJobApplications =
    //   jobApplications.length >= current_page * per_page
    //     ? jobApplications.slice(
    //       (current_page - 1) * per_page,
    //       current_page * per_page - 1
    //     )
    //     : jobApplications.slice(-per_page)

    return (
      <div className="pb-5 container overflow-hidden">
        {!is_super_admin && !permissions.includes(22) && !permissions.includes(22) ? <Redirect to="/" /> : ""}
        <div className="row px-3 pt-3 main-header">
          <div className="col-12">
            <h2 className="page-title">الوظائف</h2>
          </div>
        </div>
        <div className="row p-3">
          <h3 className="mb-3 page-subtitle">نظرة عامة</h3>
        </div>
        <div className="row p-3">
          <JobsCard title="عدد الوظائف المطروحة" count={total || 0}>
            <Assignment />
          </JobsCard>
          {/* <JobsCard title="عدد الطلبات التي تم الرد عليها" count={150}>
            <AssignmentTurnedIn />
          </JobsCard>
          <JobsCard title="عدد الطلبات التي لم يتم الرد عليها" count={150}>
            <AssignmentLate />
          </JobsCard>
          <CardDropdown title="عدد تقديم الوظائف مطور" count={150}>
            <Code />
            <JobsCardDropdownList />
          </CardDropdown> */}
        </div>
        <div className="row p-3">
          <div className="col-12">
            <p className="d-inline-block pl-2 ml-2 font-weight-bold">
              {is_super_admin || permissions.includes(22) ? (
                <Link to="/job-applications" className="icon-link text-decoration-none text-black">
                  طلبات التوظيف
                </Link>
              ) : (
                <Redirect to="/jobs" />
              )}
            </p>
            <p className="d-inline-block px-2 mx-2 ">
              {is_super_admin || permissions.includes(9) ? (
                <Link to="/jobs" className="icon-link text-decoration-none text-black">
                  الوظائف
                </Link>
              ) : (
                ""
              )}
            </p>
          </div>
        </div>
        <div className="px-3">
          <Paper className="search-paper" square>
            <div className="jobs-requests__search">
              <form className="col-12 col-md-6" onSubmit={this.handleSearchSubmit}>
                <input
                  type="search"
                  placeholder="ابحث عن الاسم او البريد الالكتروني او الهاتف"
                  className="form-control"
                  value={searchVal}
                  name="searchVal"
                  onChange={this.handleSearchChange}
                />
              </form>
              <div className="col-12 col-md-6">
                <button
                  className="btn btn-primary jobs-requests__filter"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={this.handleMenuOpen}
                >
                  <FilterList /> <span className="mr-2">ترشيح</span>
                </button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={this.handleMenuClose}
                >
                  {jobs.map((val) => (
                    <MenuItem key={val.id} onClick={this.handleFiltering} selected={filterBy === val.title.ar}>
                      {val.title.ar}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </div>
          </Paper>
        </div>
        <div className="row px-3 pb-3">
          <div className="col-12">
            <JobsRequestsTable handleDeleteItem={this.handleOpenDeleteDialog} jobApplications={jobApplications} />
          </div>
        </div>
        {jobApplications && (
          <div className="my-3 d-flex justify-content-center align-items-center flex-grow-1">
            <Pagination
              variant="outlined"
              shape="rounded"
              count={last_page}
              page={current_page}
              boundaryCount={2}
              onChange={this.handlePaginationChange}
              disabled={paginationDisabled}
            />
          </div>
        )}
        <ConfirmDeleteModal
          open={openDeleteDialog}
          handleClose={this.handleCloseDeleteDialog}
          text="هل تريد حذف طلب التوظيف؟"
          handleDelete={this.handleConfirmDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ jobApplications, user, jobs }) => ({
  jobApplications,
  user,
  jobs,
});

const mapDispatchToProps = (dispatch) => ({
  getJobApplications: (key, value) => dispatch(handleReceiveJobApplications(key, value)),
  deleteJobApplication: (id) => dispatch(handleDeleteJobApplication(id)),
  getJobApplicationsPagination: (page, key, value) => dispatch(handlePaginateJobApplications(page, key, value)),
  getJobs: () => dispatch(handleReceiveJobs()),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobsRequests);
