import React, { Component } from "react";
import Layout from "../base/Layout";
// import ConfirmDeleteModal from "../modals/ConfirmDeleteModal"
import AddAdminModal from "../modals/AddAdmin";
import EditAdminModal from "../modals/EditAdminModal";
import AdminsList from "../admins/adminsList";
import { Pagination } from "@material-ui/lab";
import { connect } from "react-redux";
import {
  handleReceiveAdmins,
  // handleDeleteAdmin,
  handlePaginateAdmin,
} from "../../actions/admins";
import { handleReceiveRoles } from "../../actions/roles";
import QrCodesList from "../admins/QrCodesList";
import { handleDeleteQrCode, handleReceiveQrCodes } from "../../actions/qrCodes";
import QrCodeModal from "../modals/QrCodeModal";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";

class QrCodes extends Component {
  state = {
    openStoreDialog: false,
    openDeleteDialog: false,
    deleteId: null,
    editId: null,
    pagination: {
      per_page: 15,
      current_page: 1,
      last_page: 1,
    },
    paginationDisabled: false,
  };

  handleOpenStoreDialog = () => {
    this.setState({ openStoreDialog: true });
  };

  handleCloseStoreDialog = () => {
    this.setState({ openStoreDialog: false, editId: null });
  };
  handleOpenDeleteDialog = (id) => {
    this.setState({ openDeleteDialog: true, deleteId: id });
  };
  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDialog: false, deleteId: null });
  };

  handleConfirmDelete = () => {
    const { deleteQrCode } = this.props;
    const { deleteId } = this.state;
    deleteQrCode(deleteId);
  };

  handlePaginationChange = (e, current_page) => {
    const { last_page } = this.state.pagination;
    const { getQrCodes } = this.props;
    if (last_page >= current_page) {
      this.setState({ paginationDisabled: true });
      getQrCodes(current_page).then(() => {
        this.setState((prevState) => ({
          pagination: { ...prevState.pagination, current_page },
          paginationDisabled: false,
        }));
      });
    }
  };

  componentDidMount() {
    const { getQrCodes } = this.props;
    getQrCodes().then((res) => {
      if (res) {
        this.setState({
          pagination: { ...this.state.pagination, last_page: res.meta.last_page },
        });
      }
    });
  }

  render() {
    const {
      openStoreDialog,
      openDeleteDialog,
      editId,
      pagination: { current_page, last_page, per_page },
      paginationDisabled,
    } = this.state;
    const {
      qrCodes,
      user: { permissions, is_super_admin },
    } = this.props;
    return (
      <Layout
        title="QR Codes"
        addBtnText="إضافة QR Code"
        showAddBtn={is_super_admin || permissions.includes(2)}
        handleBtnClicked={this.handleOpenStoreDialog}
      >
        <QrCodesList
          handleDeleteItem={this.handleOpenDeleteDialog}
          handleEditItem={(id) => {
            this.setState({ editId: id });
            this.handleOpenStoreDialog();
          }}
          qrCodes={qrCodes}
        />
        {qrCodes && (
          <div className="my-3 d-flex justify-content-center align-items-center flex-grow-1">
            <Pagination
              variant="outlined"
              shape="rounded"
              count={last_page}
              page={current_page}
              boundaryCount={2}
              onChange={this.handlePaginationChange}
              disabled={paginationDisabled}
            />
          </div>
        )}

        {is_super_admin && (
          <>
            <QrCodeModal
              open={openStoreDialog}
              handleClose={this.handleCloseStoreDialog}
              qrCode={qrCodes.find((item) => item.id === editId)}
            />
            <ConfirmDeleteModal
              open={openDeleteDialog}
              handleClose={this.handleCloseDeleteDialog}
              text="هل أنت متأكد"
              handleDelete={this.handleConfirmDelete}
            />
          </>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = ({ qrCodes, user }) => ({ qrCodes, user });

const mapDispatchToProps = (dispatch) => ({
  getQrCodes: (page) => dispatch(handleReceiveQrCodes(page)),
  deleteQrCode: (id) => dispatch(handleDeleteQrCode(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QrCodes);
