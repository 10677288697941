import React from "react";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
import { DeleteOutline, EditOutlined, VisibilityOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import EllipsisText from "react-ellipsis-text";
import { connect } from "react-redux";
const LastNewsTableRow = ({
  handleEditItem,
  handleDeleteItem,
  title,
  content,
  id,
  index,
  pagination,
  user: { permissions, is_super_admin },
}) => (
  <TableRow>
    <TableCell align="center">{index + (pagination.current_page - 1) * pagination.per_page}</TableCell>
    <TableCell align="center">{title.ar}</TableCell>
    <TableCell align="center">
      <EllipsisText text={content.ar} length={55} />
    </TableCell>
    <TableCell align="center" style={{whiteSpace: "nowrap"}}>
      <Link to={`/news/${id}`} className="icon-link">
        <IconButton aria-label="show">
          <VisibilityOutlined />
        </IconButton>
      </Link>
      {is_super_admin || permissions.includes(34) ? (
        <IconButton aria-label="edit" onClick={handleEditItem}>
          <EditOutlined />
        </IconButton>
      ) : (
        ""
      )}
      {is_super_admin || permissions.includes(16) ? (
        <IconButton aria-label="delete" onClick={handleDeleteItem}>
          <DeleteOutline color="error" />
        </IconButton>
      ) : (
        ""
      )}
    </TableCell>
  </TableRow>
);

LastNewsTableRow.propTypes = {
  handleEditItem: PropTypes.func.isRequired,
  handleDeleteItem: PropTypes.func.isRequired,
  title: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(LastNewsTableRow);
