import React from "react";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
import { DeleteOutline, EditOutlined } from "@material-ui/icons";
import PropTypes from "prop-types";
import EllipsisText from "react-ellipsis-text";
import { connect } from "react-redux";
const LastWorksTableRow = ({
  handleEditItem,
  handleDeleteItem,
  index,
  name,
  desc,
  pagination,
  user: { permissions, is_super_admin },
}) => (
  <TableRow>
    <TableCell align="center">{index + (pagination.current_page - 1) * pagination.per_page}</TableCell>
    <TableCell align="center">{name.ar}</TableCell>
    <TableCell align="center">
      <EllipsisText text={desc.ar} length={55} />
    </TableCell>
    <TableCell align="center">
      {is_super_admin || permissions.includes(45) ? (
        <IconButton aria-label="edit" onClick={handleEditItem}>
          <EditOutlined />
        </IconButton>
      ) : (
        ""
      )}
      {is_super_admin || permissions.includes(46) ? (
        <IconButton aria-label="delete" onClick={handleDeleteItem}>
          <DeleteOutline color="error" />
        </IconButton>
      ) : (
        ""
      )}
    </TableCell>
  </TableRow>
);

LastWorksTableRow.propTypes = {
  handleEditItem: PropTypes.func.isRequired,
  handleDeleteItem: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.object.isRequired,
  desc: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(LastWorksTableRow);
