import { AUTHENTICATE_USER, LOGOUT_USER, UPDATE_PERMISSIONS } from "../actions/user";
import Cookies from "js-cookie";
export default function user(
  state = {
    user_id: Cookies.get("user_id") ? JSON.parse(Cookies.get("user_id")) : null,
    user_name: Cookies.get("user_name") ? Cookies.get("user_name") : null,
    is_super_admin: Cookies.get("is_super_admin") ? JSON.parse(Cookies.get("is_super_admin")) : false,
    isAuthenticated: false,
    permissions: Cookies.get("permissions") ? JSON.parse(Cookies.get("permissions")) : [],
  },
  action
) {
  switch (action.type) {
    case AUTHENTICATE_USER:
      return {
        ...state,
        user_id: action.payload.id,
        user_name: action.payload.name,
        isAuthenticated: true,
        permissions: action.payload.permissions.map((permission) => permission.id),
        is_super_admin: action.payload.is_super_admin,
      };
    case LOGOUT_USER:
      return {
        ...state,
        isAuthenticated: false,
        permissions: [],
        user_id: null,
        user_name: null,
        is_super_admin: false,
      };
    case UPDATE_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload.map((permission) => permission.id),
      };
    default:
      return state;
  }
}
