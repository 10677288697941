import React from "react";
import { ListItem, ListItemIcon, ListItemText, IconButton } from "@material-ui/core";
import { Menu, DeleteOutline, EditOutlined } from "@material-ui/icons/";
import PropTypes from "prop-types";
import EllipsisText from "react-ellipsis-text";
import { connect } from "react-redux";
const SliderListItem = ({ handleDeleteItem, handleEditItem, title, desc, user: { permissions, is_super_admin } }) => (
  <ListItem
    classes={{
      root: "text-right d-flex flex-column justify-content-start align-items-start flex-md-row align-items-md-center justify-content-md-between",
    }}
  >
    <ListItemIcon>
      <Menu />
    </ListItemIcon>
    <ListItemText primary={title.ar} secondary={<EllipsisText text={desc.ar} length={55} />} />
    <div className="pl-2 pr-4 d-flex align-items-center">
      {is_super_admin || permissions.includes(61) ? (
        <IconButton aria-label="edit" onClick={handleEditItem}>
          <EditOutlined />
        </IconButton>
      ) : (
        ""
      )}
      {is_super_admin || permissions.includes(62) ? (
        <IconButton aria-label="delete" onClick={handleDeleteItem}>
          <DeleteOutline color="error" />
        </IconButton>
      ) : (
        ""
      )}
    </div>
  </ListItem>
);

SliderListItem.propTypes = {
  handleDeleteItem: PropTypes.func.isRequired,
  handleEditItem: PropTypes.func.isRequired,
  title: PropTypes.object.isRequired,
  desc: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(SliderListItem);
