import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CloseButton from "./CloseButton";
import { handleCreateQrCode, handleEditQrCode } from "../../actions/qrCodes";

class QrCodeModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    createQrCode: PropTypes.func.isRequired,
    editQrCode: PropTypes.func.isRequired,
    qrCode: PropTypes.object,
  };

  state = {
    name: "",
    content: "",
    size: "",
  };

  componentDidUpdate(oldProps) {
    if (oldProps.qrCode !== this.props.qrCode) {
      if (!this.props.qrCode) {
        this.setState({ name: "", content: "", size: "" });
      } else {
        const { name, content, size } = this.props.qrCode;
        this.setState({ name, content, size });
      }
    }
  }

  handleSubmitDisabled = () => {
    const { name, content, size, submitDisabled } = this.state;
    if (name && content && size) {
      this.setState({ submitDisabled: false });
    } else if (submitDisabled === false) {
      this.setState({ submitDisabled: true });
    }
  };
  handleInputChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    this.setState({ [name]: value }, this.handleSubmitDisabled);
  };

  handleSubmit = () => {
    const { createQrCode, editQrCode, handleClose, qrCode = null } = this.props;
    const { name, content, size } = this.state;
    if (qrCode) editQrCode(qrCode.id, { name, content, size });
    else createQrCode({ name, content, size });
    handleClose();
  };

  render() {
    const { open, handleClose } = this.props;
    const { name, content, size, submitDisabled } = this.state;
    const { qrCode = null } = this.props;

    return (
      <Dialog open={open} onClose={handleClose} classes={{ paper: "dialog" }} aria-labelledby="responsive-dialog-title">
        <CloseButton handleClose={handleClose} />
        <DialogTitle id="responsive-dialog-title" className="text-center">
          {qrCode ? "تعديل QR Code" : "إضافة QR Code"}
        </DialogTitle>
        <DialogContent>
          <div className="form-group">
            <TextField
              type="text"
              className="form-control"
              required
              placeholder="الاسم "
              value={name}
              name="name"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              type="content"
              className="form-control"
              required
              placeholder="الرابط"
              value={content}
              name="content"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              type="size"
              className="form-control"
              required
              placeholder="الحجم"
              value={size}
              name="size"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center align-items-center">
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
            className="dialog__btn"
            disabled={submitDisabled}
          >
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createQrCode: (data) => dispatch(handleCreateQrCode(data)),
  editQrCode: (id, data) => dispatch(handleEditQrCode(id, data)),
});

export default connect(null, mapDispatchToProps)(QrCodeModal);
