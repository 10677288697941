import { RECEIVE_JOBS, CREATE_JOB, EDIT_JOB, DELETE_JOB, GET_JOB_DETAILS, PAGINATE_JOBS } from "../actions/jobs";
import sortBy from "sort-by";

export default function jobs(state = [], action) {
  switch (action.type) {
    case RECEIVE_JOBS:
      return action.jobs;
    case PAGINATE_JOBS: {
      return action.jobs;
    }
    case GET_JOB_DETAILS:
      return [...state, action.job];
    case CREATE_JOB:
      return [action.job, ...state];
    case EDIT_JOB:
      return state.map((job) => (job.id === action.id ? Object.assign(job, action.data) : job));
    case DELETE_JOB:
      return state.filter((job) => job.id !== action.id);
    default:
      return state;
  }
}
