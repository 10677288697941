import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CloseButton from "./CloseButton";
import { handleShowRole, handleEditRole } from "../../actions/roles";

class EditRoleModal extends Component {
  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    editId: PropTypes.number,
    availablePermissions: PropTypes.array.isRequired,
  };

  state = {
    name: "",
    permissions: [],
    submitDisabled: true,
  };

  componentDidMount() {
    const { targetRole, showRole, editId } = this.props;
    if (!targetRole.permissions) {
      showRole(editId);
    }
    this.setState({
      name: targetRole.name,
      permissions: targetRole.permissions
        ? targetRole.permissions.length > 0
          ? targetRole.permissions.map((permission) => permission.id)
          : ["DEFAULT"]
        : ["DEFAULT"],
    });
  }

  componentDidUpdate(oldProps) {
    if (oldProps.targetRole !== this.props.targetRole) {
      const { targetRole } = this.props;
      this.setState({
        name: targetRole.name,
        permissions: targetRole.permissions
          ? targetRole.permissions.length > 0
            ? targetRole.permissions.map((permission) => permission.id)
            : ["DEFAULT"]
          : ["DEFAULT"],
      });
    }
  }

  handleSubmitDisabled = () => {
    const { name, permissions, submitDisabled } = this.state;
    if (name && permissions[0] !== "DEFAULT") {
      this.setState({ submitDisabled: false });
    } else if (submitDisabled === false) {
      this.setState({ submitDisabled: true });
    }
  };

  handleInputChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    this.setState({ [name]: value }, this.handleSubmitDisabled);
  };

  handleSelectChange = (e) => {
    var { name, value } = e.target;

    // remove default value which works as placeholder
    const defaultIndex = value.indexOf("DEFAULT");
    if (defaultIndex !== -1) {
      value.splice(defaultIndex, 1);
    }
    if (value.length === 0) {
      value = ["DEFAULT"];
    }
    this.setState({ [name]: value }, this.handleSubmitDisabled);
  };
  handleSubmit = () => {
    const { updateRole, handleClose, editId } = this.props;
    const { name, permissions } = this.state;
    const data = {
      name: name,
      permissions: permissions[0] !== "DEFAULT" ? permissions : [],
    };
    updateRole(editId, data);
    handleClose();
  };

  render() {
    const { handleClose, availablePermissions } = this.props;
    const { name, permissions, submitDisabled } = this.state;

    return (
      <Dialog
        open={true}
        onClose={handleClose}
        classes={{
          paper: "dialog",
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <CloseButton handleClose={handleClose} />
        <DialogTitle id="responsive-dialog-title" className="text-center">
          تعديل دور
        </DialogTitle>
        <DialogContent>
          <div className="form-group">
            <TextField
              type="text"
              className="form-control"
              required
              placeholder="الاسم "
              value={name}
              name="name"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <Select
              className="form-control"
              required
              multiple
              placeholder="الأذونات"
              // get list of role ids out of roles
              value={permissions}
              name="permissions"
              onChange={this.handleSelectChange}
            >
              <MenuItem disabled value="DEFAULT">
                الأذونات
              </MenuItem>
              {availablePermissions.map((permission) => {
                return (
                  <MenuItem key={permission.id} value={permission.id}>
                    {permission.name_ar}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center align-items-center">
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
            className="dialog__btn"
            disabled={submitDisabled}
          >
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ roles, permissions }, { editId }) => {
  const targetRole = roles.find((item) => item.id === editId);
  return { targetRole, availablePermissions: permissions };
};

const mapDispatchToProps = (dispatch) => ({
  updateRole: (id, data) => dispatch(handleEditRole(id, data)),
  showRole: (id) => dispatch(handleShowRole(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditRoleModal);
