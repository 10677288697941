import React, { Component } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArticlesImageUpload from "../articles/ArticlesImageUpload";
import Editor from "../articles/Editor";
import CloseButton from "./CloseButton";
import { handleEditArticle } from "../../actions/articles";
import { uploadMedia } from "../../utils/api";

class EditArticle extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  state = {
    title_ar: "",
    details_ar: "",
    title_en: "",
    details_en: "",
    submitDisabled: true,
    files: [],
    imagePath: "",
    imageLoading: true,
  };

  handleSubmitDisabled = () => {
    const { title_ar, title_en, imagePath, details_ar, details_en } = this.state;
    if (!title_ar || !title_en || !imagePath || !Array.isArray(details_ar) || !Array.isArray(details_en)) {
      this.setState({ submitDisabled: true });
    } else this.setState({ submitDisabled: false });
  };
  handleTitleArChange = (e) => {
    this.setState({ title_ar: e.target.value }, this.handleSubmitDisabled);
  };
  handleDetailsArChange = (value) => {
    this.setState({ details_ar: value }, this.handleSubmitDisabled);
  };
  handleTitleEnChange = (e) => {
    this.setState({ title_en: e.target.value }, this.handleSubmitDisabled);
  };
  handleDetailsEnChange = (value) => {
    this.setState({ details_en: value }, this.handleSubmitDisabled);
  };
  handleSetFiles = (files) => {
    this.setState(
      { files },
      () => {
        const { files } = this.state;
        const form = new FormData();
        form.append("file", files[0]);
        form.append("mime_type", "image");

        uploadMedia(form)
          .then((res) => res.json())
          .then((res) => {
            this.setState({ imagePath: res.path, imageLoading: false });
          });
      },
      this.handleSubmitDisabled
    );
  };

  componentDidUpdate(oldProps) {
    if (oldProps.targetArticle !== this.props.targetArticle) {
      const { targetArticle } = this.props;
      const title_ar = targetArticle ? targetArticle.title.ar : "";
      const title_en = targetArticle ? targetArticle.title.en : "";
      let content_ar = "", content_en = "";
      try {
        content_ar = JSON.parse(targetArticle.content.ar);
        content_en = JSON.parse(targetArticle.content.en);
      } catch (e) {}
      const image = targetArticle ? targetArticle.image : "";
      this.setState(
        {
          title_ar: title_ar,
          details_ar: content_ar,
          title_en: title_en,
          details_en: content_en,
          imagePath: image,
        },
        this.handleSubmitDisabled
      );
    }
  }

  componentDidMount() {
    const { targetArticle } = this.props;
    if (targetArticle)
      this.setState(
        {
          title_ar: targetArticle.title.ar,
          details_ar: targetArticle.content.ar,
          title_en: targetArticle.title.en,
          details_en: targetArticle.content.en,
          imagePath: targetArticle.image,
        },
        this.handleSubmitDisabled
      );
  }

  handleSubmit = () => {
    const { updateArticle, handleClose, editId } = this.props;
    const { title_ar, details_ar, title_en, details_en, imagePath } = this.state;
    const data = {
      title: {
        ar: title_ar,
        en: title_en,
      },
      content: {
        ar: JSON.stringify(details_ar),
        en: JSON.stringify(details_en),
      },
      image: imagePath,
    };
    updateArticle(editId, data);
    handleClose();
  };

  render() {
    const { open, handleClose } = this.props;
    const { title_ar, details_ar, title_en, details_en, submitDisabled, files, imagePath, imageLoading } = this.state;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: "dialog",
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <CloseButton handleClose={handleClose} />
        <DialogTitle id="responsive-dialog-title" className="text-center">
          تعديل المقال
        </DialogTitle>
        <DialogContent>
          <div className="form-group">
            <ArticlesImageUpload files={files} setFiles={this.handleSetFiles} loading={imageLoading} image={imagePath} />
          </div>
          <div className="form-group">
            <TextField
              type="text"
              placeholder="عنوان الخبر بالعربية"
              className="form-control"
              required
              value={title_ar}
              onChange={this.handleTitleArChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              type="text"
              placeholder="عنوان الخبر بالانجليزية"
              className="form-control"
              required
              value={title_en}
              onChange={this.handleTitleEnChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <div className="pt-3 px-4 border rounded">
              <Editor placeholder="المحتوى بالعربية" onChange={this.handleDetailsArChange} value={details_ar} />
            </div>
          </div>
          <div className="form-group">
            <div className="pt-3 px-4 border rounded">
              <Editor placeholder="المحتوى بالانجليزية" onChange={this.handleDetailsEnChange} value={details_en} />
            </div>
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center align-items-center">
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
            className="dialog__btn"
            disabled={submitDisabled}
          >
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ articles }, { editId }) => {
  const targetArticle = articles.find((item) => item.id === editId);
  return { targetArticle };
};

const mapDispatchToProps = (dispatch) => ({
  updateArticle: (id, data) => dispatch(handleEditArticle(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditArticle);
