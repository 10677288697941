import {
  RECEIVE_PRICE_REQUESTS,
  DELETE_PRICE_REQUEST,
  GET_PRICE_REQUEST_DETAILS,
  PAGINATE_PRICE_REQUESTS,
} from "../actions/priceRequests";
import sortBy from "sort-by";

export default function priceRequests(state = [], action) {
  switch (action.type) {
    case RECEIVE_PRICE_REQUESTS:
      return action.priceRequests;
    case PAGINATE_PRICE_REQUESTS: {
      return action.priceRequests;
    }
    case DELETE_PRICE_REQUEST:
      return state.filter((priceRequest) => priceRequest.id !== action.id);
    case GET_PRICE_REQUEST_DETAILS:
      return [...state, action.priceRequest];
    default:
      return state;
  }
}
