import React from "react";
import { Paper, List } from "@material-ui/core";
import TestimonialsListItem from "./TestimonialsListItem";
import PropTypes from "prop-types";

const TestimonialsList = ({ handleDeleteItem, handleEditItem, testimonials }) =>
  testimonials.length ? (
    <Paper square>
      <List className="slider__list">
        {testimonials.map((testimonial) => (
          <TestimonialsListItem
            {...testimonial}
            handleDeleteItem={() => handleDeleteItem(testimonial.id)}
            handleEditItem={() => handleEditItem(testimonial.id)}
            key={testimonial.id}
          />
        ))}
      </List>
    </Paper>
  ) : (
    <Paper square>
      <List className="slider__list text-center p-5">لا يوجد شهادات</List>
    </Paper>
  );

TestimonialsList.propTypes = {
  handleDeleteItem: PropTypes.func.isRequired,
  handleEditItem: PropTypes.func.isRequired,
  testimonials: PropTypes.array.isRequired,
};

export default TestimonialsList;
