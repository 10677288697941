import { showLoading, hideLoading } from "react-redux-loading-bar";
import { getQrCodes, storeQrCode, updateQrCode, destroyQrCode } from "../utils/api";
import { showMessageAlert } from "./messageAlert";

export const RECEIVE_QR_CODES = "RECEIVE_QR_CODES";
export const CREATE_QR_CODE = "CREATE_QR_CODE";
export const EDIT_QR_CODE = "EDIT_QR_CODE";
export const DELETE_QR_CODE = "DELETE_QR_CODE";
const receiveQrCodes = (qrCodes) => ({
  type: RECEIVE_QR_CODES,
  qrCodes,
});

const createQrCode = (qrCode) => ({
  type: CREATE_QR_CODE,
  qrCode,
});

const editQrCode = (id, data) => ({
  type: EDIT_QR_CODE,
  id,
  data,
});

const deleteQrCode = (id) => ({
  type: DELETE_QR_CODE,
  id,
});

export const handleReceiveQrCodes =
  (page = 1) =>
  (dispatch) => {
    dispatch(showLoading());
    return getQrCodes(page)
      .then((res) => res.json())
      .then((res) => {
        dispatch(receiveQrCodes(res.data));
        dispatch(hideLoading());
        return res;
      })
      .catch((e) => {
        dispatch(showMessageAlert(e.message, "error"));
        dispatch(hideLoading());
      });
  };
export const handleCreateQrCode = (data) => (dispatch) => {
  dispatch(showLoading());
  storeQrCode(data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(createQrCode(res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم الامر بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleEditQrCode = (id, data, same_user) => (dispatch) => {
  dispatch(showLoading());
  updateQrCode(id, data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(editQrCode(id, res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم الامر بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleDeleteQrCode = (id) => (dispatch) => {
  dispatch(showLoading());
  destroyQrCode(id)
    .then((res) => res.json())
    .then((res) => {
      dispatch(deleteQrCode(id));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم الامر بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};
