import React from "react";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
import { VisibilityOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/ar";

const PriceRequestsTableRow = ({ index, name, email, created_at, id, service_name, pagination }) => (
  <TableRow>
    <TableCell align="center">{index + (pagination.current_page - 1) * pagination.per_page}</TableCell>
    <TableCell align="center">{name}</TableCell>
    <TableCell align="center">{email}</TableCell>
    <TableCell align="center">{service_name || "استشارة"}</TableCell>
    <TableCell align="center">{moment(created_at).local("ar").format("L")}</TableCell>
    <TableCell align="center">
      <Link to={`/price-requests/${id}`} className="icon-link">
        <IconButton aria-label="show">
          <VisibilityOutlined />
        </IconButton>
      </Link>
    </TableCell>
  </TableRow>
);

PriceRequestsTableRow.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

export default PriceRequestsTableRow;
