import React from "react";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
import { DeleteOutline, VisibilityOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
const JobsTableRow = ({
  handleDeleteItem,
  title,
  desc,
  location,
  index,
  id,
  pagination,
  user: { permissions, is_super_admin },
}) => (
  <TableRow>
    <TableCell align="center">{index + (pagination.current_page - 1) * pagination.per_page}</TableCell>
    <TableCell align="center">{title.ar}</TableCell>
    <TableCell align="center">{desc.ar}</TableCell>
    <TableCell align="center">{location.ar}</TableCell>
    <TableCell align="center">
      <Link to={`/jobs/${id}`} className="icon-link">
        <IconButton aria-label="show">
          <VisibilityOutlined />
        </IconButton>
      </Link>
      {is_super_admin || permissions.includes(12) ? (
        <IconButton aria-label="delete" onClick={handleDeleteItem}>
          <DeleteOutline color="error" />
        </IconButton>
      ) : (
        ""
      )}
    </TableCell>
  </TableRow>
);

JobsTableRow.propTypes = {
  handleDeleteItem: PropTypes.func.isRequired,
  title: PropTypes.object.isRequired,
  desc: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(JobsTableRow);
