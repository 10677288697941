import React, { Component } from "react";
import { Button } from "@material-ui/core";
import ContactsModal from "../modals/ContactsModal";
import StackedRow from "../base/StackedRow";
import { connect } from "react-redux";
import { handleReceiveContacts, handleDeleteContact } from "../../actions/contacts";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
import EditContactModal from "../modals/EditContactModal";

class ContactDetails extends Component {
  state = {
    openAddDialog: false,
    openDeleteDialog: false,
    deleteId: null,
    openEditDialog: false,
    editId: null,
  };
  handleOpenAddDialog = () => {
    this.setState({ openAddDialog: true });
  };
  handleCloseAddDialog = () => {
    this.setState({ openAddDialog: false });
  };
  handleOpenDeleteDialog = (id) => {
    this.setState({ openDeleteDialog: true, deleteId: id });
  };
  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDialog: false, deleteId: null });
  };
  handleOpenEditDialog = (id) => {
    this.setState({ openEditDialog: true, editId: id });
  };
  handleCloseEditDialog = () => {
    this.setState({ openEditDialog: false, editId: null });
  };

  handleConfirmDelete = () => {
    const { deleteContact } = this.props;
    const { deleteId } = this.state;
    deleteContact(deleteId);
  };

  componentDidMount() {
    const { getContacts } = this.props;
    getContacts();
  }

  render() {
    const { openAddDialog, openEditDialog, openDeleteDialog, editId } = this.state;
    const {
      contacts,
      user: { permissions, is_super_admin },
    } = this.props;

    return (
      <div className="pb-5 container overflow-hidden">
        <div className="row px-3 pt-3 main-header">
          <div className="col-12">
            <h2 className="page-title">تعديل الصفحة الرئيسية</h2>
          </div>
        </div>
        <div className="row p-3 mb-3">
          <div className="col d-flex align-items-center">
            <h5 className="mb-0 ml-md-3 page-subtitle-lead">معلومات التواصل</h5>
            {is_super_admin || permissions.includes(56) ? (
              <Button color="primary" variant="text" onClick={this.handleOpenAddDialog}>
                إضافة
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
        {contacts.length === 0 ? (
          <div className="row px-4">لا توجد معلومات</div>
        ) : (
          contacts.map(({ key, value, id }) => (
            <StackedRow
              question={key}
              answer={value.en}
              key={id}
              handleEdit={() => this.handleOpenEditDialog(id)}
              handleDelete={() => this.handleOpenDeleteDialog(id)}
            />
          ))
        )}
        <ContactsModal open={openAddDialog} handleClose={this.handleCloseAddDialog} />
        <EditContactModal open={openEditDialog} handleClose={this.handleCloseEditDialog} editId={editId} />
        <ConfirmDeleteModal
          open={openDeleteDialog}
          handleClose={this.handleCloseDeleteDialog}
          text="هل تريد الحذف؟"
          handleDelete={this.handleConfirmDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ contacts, user }) => ({ contacts, user });

const mapDispatchToProps = (dispatch) => ({
  getContacts: () => dispatch(handleReceiveContacts()),
  deleteContact: (id) => dispatch(handleDeleteContact(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetails);
