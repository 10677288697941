import React from "react";
import PropTypes from "prop-types";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const SideBarMenuItem = ({ Icon, text, href }) => (
  <NavLink to={href} className="text-decoration-none" activeClassName="selected" exact={href === "/"}>
    <ListItem
      button
      classes={{
        root: "side-bar__item",
      }}
    >
      <ListItemIcon
        classes={{
          root: "side-bar__icon",
        }}
      >
        {Icon ? <Icon /> : ""}
      </ListItemIcon>
      <ListItemText primary={text} className="side-bar__item-text" />
    </ListItem>
  </NavLink>
);

SideBarMenuItem.propTypes = {
  Icon: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default SideBarMenuItem;
