import {
  RECEIVE_JOB_APPLICATION_NOTES,
  EDIT_JOB_APPLICATION_NOTE,
  DELETE_JOB_APPLICATION_NOTE,
  CREATE_JOB_APPLICATION_NOTE,
} from "../actions/jobApplicationNotes";

export default function jobApplicationNotes(state = {}, action) {
  switch (action.type) {
    case RECEIVE_JOB_APPLICATION_NOTES:
      return {
        ...state,
        [action.jobId]: action.notes,
      };
    case CREATE_JOB_APPLICATION_NOTE:
      return {
        ...state,
        [action.jobId]: state[action.jobId].concat(action.note),
      };
    case EDIT_JOB_APPLICATION_NOTE:
      return {
        ...state,
        [action.jobId]: state[action.jobId].map((note) =>
          note.id === action.noteId ? Object.assign(note, action.note) : note
        ),
      };
    case DELETE_JOB_APPLICATION_NOTE:
      return {
        ...state,
        [action.jobId]: state[action.jobId].filter((note) => note.id !== action.noteId),
      };
    default:
      return state;
  }
}
