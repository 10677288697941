import { showLoading, hideLoading } from "react-redux-loading-bar";
import { getNews, storeNews, updateNews, destroyNews, getNewsDetails, getNewsPage } from "../utils/api";
import { showMessageAlert } from "./messageAlert";

export const RECEIVE_NEWS = "RECEIVE_NEWS";
export const CREATE_NEWS = "CREATE_NEWS";
export const EDIT_NEWS = "EDIT_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";
export const GET_NEWS_DETAILS = "GET_NEWS_DETAILS";
export const PAGINATE_NEWS = "PAGINATE_NEWS";

const receiveNews = (news) => ({
  type: RECEIVE_NEWS,
  news,
});

const createNews = (news) => ({
  type: CREATE_NEWS,
  news,
});

const editNews = (id, data) => ({
  type: EDIT_NEWS,
  id,
  data,
});

const deleteNews = (id) => ({
  type: DELETE_NEWS,
  id,
});

const receiveNewsDetails = (news) => ({
  type: GET_NEWS_DETAILS,
  news,
});

const paginateNews = (news) => ({
  type: PAGINATE_NEWS,
  news,
});

export const handleReceiveNews = () => (dispatch) => {
  dispatch(showLoading());
  return getNews()
    .then((res) => res.json())
    .then((res) => {
      dispatch(receiveNews(res.data));
      dispatch(hideLoading());
      return res;
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handlePaginateNews = (page) => (dispatch) => {
  dispatch(showLoading());
  return getNewsPage(page)
    .then((res) => res.json())
    .then((res) => {
      dispatch(paginateNews(res.data));
      dispatch(hideLoading());
      return res;
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleReceiveNewsDetails = (id) => (dispatch) => {
  dispatch(showLoading());
  getNewsDetails(id)
    .then((res) => res.json())
    .then((res) => {
      dispatch(receiveNewsDetails(res.data));
      dispatch(hideLoading());
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
    });
};

export const handleCreateNews = (data) => (dispatch) => {
  dispatch(showLoading());
  storeNews(data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(createNews(res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم إضافة الخبر بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleEditNews = (id, data) => (dispatch) => {
  dispatch(showLoading());
  updateNews(id, data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(editNews(id, res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم تعديل الخبر بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleDeleteNews = (id) => (dispatch) => {
  dispatch(showLoading());
  destroyNews(id)
    .then((res) => res.json())
    .then((res) => {
      dispatch(deleteNews(id));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم حذف الخبر بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};
