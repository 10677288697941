import { RECEIVE_SLIDERS, CREATE_SLIDER, EDIT_SLIDER, DELETE_SLIDER } from "../actions/slider";

export default function slider(state = [], action) {
  switch (action.type) {
    case RECEIVE_SLIDERS:
      return action.sliders;
    case CREATE_SLIDER:
      return [action.slider, ...state];
    case EDIT_SLIDER:
      return state.map((slider) => (slider.id === action.id ? Object.assign(slider, action.data) : slider));
    case DELETE_SLIDER:
      return state.filter((slider) => slider.id !== action.id);
    default:
      return state;
  }
}
