import { showLoading, hideLoading } from "react-redux-loading-bar";
import { getContacts, storeContact, updateContact, destroyContact } from "../utils/api";
import { showMessageAlert } from "./messageAlert";

export const RECEIVE_CONTACTS = "RECEIVE_CONTACTS";
export const CREATE_CONTACT = "CREATE_CONTACT";
export const EDIT_CONTACT = "EDIT_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";

const receiveCotacts = (contacts) => ({
  type: RECEIVE_CONTACTS,
  contacts,
});

const createContact = (contact) => ({
  type: CREATE_CONTACT,
  contact,
});

const editContact = (id, data) => ({
  type: EDIT_CONTACT,
  id,
  data,
});

const deleteContact = (id) => ({
  type: DELETE_CONTACT,
  id,
});

export const handleReceiveContacts = () => (dispatch) => {
  dispatch(showLoading());
  getContacts()
    .then((res) => res.json())
    .then((res) => {
      dispatch(receiveCotacts(res.data));
      dispatch(hideLoading());
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleCreateContact = (data) => (dispatch) => {
  dispatch(showLoading());
  storeContact(data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(createContact(res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم الإضافة بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleEditContact = (id, data) => (dispatch) => {
  dispatch(showLoading());
  updateContact(id, data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(editContact(id, res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم التعديل بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleDeleteContact = (id) => (dispatch) => {
  dispatch(showLoading());
  destroyContact(id)
    .then((res) => res.json())
    .then((res) => {
      dispatch(deleteContact(id));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم الحذف بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};
