import React from "react";
import sidebarMenuList from "../../utils/sidebarMenuList";
import SideBarMenuItem from "./SideBarMenuItem";
import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import PropTypes from "prop-types";
import { ExitToApp } from "@material-ui/icons";
import { connect } from "react-redux";
import { handleUserLogout } from "../../actions/user";
import { useHistory } from "react-router-dom";

const SideBarMenu = ({ open, logout, user: { permissions, is_super_admin, user_name } }) => {
  const history = useHistory();
  const handleLogout = () => {
    logout().then((isLoggedOut) => {
      if (isLoggedOut) history.push("/");
    });
  };

  return (
    <List disablePadding={true}>
      {open ? (
        <div className="text-center h6 mt-n4">
          <strong>{user_name}</strong>
        </div>
      ) : (
        ""
      )}

      {sidebarMenuList.map((item) =>
        item.permission == undefined || is_super_admin || item.permission.some((e) => permissions.includes(e)) ? (
          <SideBarMenuItem {...item} key={item.Icon.displayName} />
        ) : (
          ""
        )
      )}
      <ListItem
        onClick={handleLogout}
        button
        classes={{
          root: "side-bar__item",
        }}
      >
        <ListItemIcon
          classes={{
            root: "side-bar__icon",
          }}
        >
          <ExitToApp />
        </ListItemIcon>
        <ListItemText primary="تسجيل الخروج" className="side-bar__item-text" />
      </ListItem>
    </List>
  );
};

SideBarMenu.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(handleUserLogout()),
});
const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps, mapDispatchToProps)(SideBarMenu);
