import React, { Component } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CloseButton from "./CloseButton";
import { handleEditContact } from "../../actions/contacts";

class EditContactModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  state = {
    key: "",
    value_ar: "",
    value_en: "",
    submitDisabled: true,
  };

  handleSubmitDisabled = () => {
    const { key, value_en, submitDisabled } = this.state;
    if (!key || (!value_en && !submitDisabled)) this.setState({ submitDisabled: true });
    if (key && value_en && submitDisabled) this.setState({ submitDisabled: false });
  };
  handleKeyChange = (e) => {
    this.setState({ key: e.target.value }, this.handleSubmitDisabled);
  };
  handleValueArChange = (e) => {
    this.setState({ value_ar: e.target.value }, this.handleSubmitDisabled);
  };
  handleValueEnChange = (e) => {
    this.setState({ value_en: e.target.value }, this.handleSubmitDisabled);
  };

  handleSubmit = () => {
    const { key, value_en } = this.state;
    const { updateContact, handleClose, editId } = this.props;
    const data = {
      key,
      value: {
        // ar: value_ar,
        en: value_en,
      },
    };
    updateContact(editId, data);
    handleClose();
  };

  componentDidUpdate(oldProps) {
    if (oldProps.targetContact !== this.props.targetContact) {
      const { targetContact } = this.props;
      const contactkey = targetContact ? targetContact.key : "";
      const contactValue_ar = targetContact ? targetContact.value.ar : "";
      const contactValue_en = targetContact ? targetContact.value.en : "";
      this.setState(
        {
          key: contactkey,
          value_ar: contactValue_ar,
          value_en: contactValue_en,
        },
        this.handleSubmitDisabled
      );
    }
  }

  render() {
    const { open, handleClose } = this.props;
    const { key, value_en, submitDisabled } = this.state;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: "dialog",
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <CloseButton handleClose={handleClose} />
        <DialogTitle id="responsive-dialog-title" className="text-center">
          تعديل معلومات التواصل
        </DialogTitle>
        <DialogContent>
          <div className="form-group">
            <TextField
              type="text"
              placeholder="الاسم"
              className="form-control"
              required
              value={key}
              onChange={this.handleKeyChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              type="text"
              placeholder="القيمة "
              className="form-control"
              required
              value={value_en}
              onChange={this.handleValueEnChange}
              variant="outlined"
              size="small"
            />
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center align-items-center">
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
            className="dialog__btn"
            disabled={submitDisabled}
          >
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ contacts }, { editId }) => {
  const targetContact = contacts.find((item) => item.id === editId);
  return { targetContact };
};

const mapDispatchToProps = (dispatch) => ({
  updateContact: (id, data) => dispatch(handleEditContact(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditContactModal);
