import React, { Component } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import CloseButton from "./CloseButton";

class EditNoteModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleEditNote: PropTypes.func.isRequired,
  };

  state = {
    description: "",
    submitDisabled: true,
  };

  handleSubmitDisabled = () => {
    const { description, submitDisabled } = this.state;
    if (!description && !submitDisabled) this.setState({ submitDisabled: true });
    if (description && submitDisabled) this.setState({ submitDisabled: false });
  };
  handleDescriptionChange = (e) => {
    this.setState({ description: e.target.value }, this.handleSubmitDisabled);
  };

  handleSubmit = () => {
    const { description } = this.state;
    const { handleEditNote, handleClose } = this.props;
    handleEditNote(description);
    handleClose();
  };

  componentDidUpdate(oldProps) {
    const { note } = this.props;
    if (oldProps.note !== note && note) {
      const { content } = note;
      this.setState({ description: content }, this.handleSubmitDisabled);
    }
  }

  render() {
    const { open, handleClose } = this.props;
    const { description, submitDisabled } = this.state;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: "dialog",
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <CloseButton handleClose={handleClose} />
        <DialogTitle id="responsive-dialog-title" className="text-center">
          تعديل الملاحظة
        </DialogTitle>
        <DialogContent>
          <div className="form-group">
            <TextField
              rows={4}
              placeholder="النص"
              multiline
              required
              value={description}
              onChange={this.handleDescriptionChange}
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center align-items-center">
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
            className="dialog__btn"
            disabled={submitDisabled}
          >
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditNoteModal;
