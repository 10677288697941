import { combineReducers } from "redux";
import { loadingBarReducer } from "react-redux-loading-bar";
import user from "./user";
import slider from "./slider";
import news from "./news";
import projects from "./projects";
import testimonials from "./testimonials";
import jobs from "./jobs";
import jobApplications from "./jobApplications";
import countries from "./countries";
import messageAlert from "./messageAlert";
import jobApplicationNotes from "./jobApplicationNotes";
import priceRequests from "./priceRequests";
import priceRequestNotes from "./priceRequestNotes";
import partners from "./partners";
import contacts from "./contacts";
import statistics from "./statistics";
import permissions from "./permissions";
import roles from "./roles";
import admins from "./admins";
import qrCodes from "./qrCodes";
import articles from "./articles";
//import messages from "./messages"
//import services from "./services"

export default combineReducers({
  loadingBar: loadingBarReducer,
  user,
  slider,
  news,
  projects,
  testimonials,
  jobs,
  jobApplications,
  countries,
  messageAlert,
  jobApplicationNotes,
  priceRequests,
  priceRequestNotes,
  partners,
  contacts,
  statistics,
  permissions,
  roles,
  admins,
  qrCodes,
  articles
  //messages,
  //services,
});
