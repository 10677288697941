import React, { Component } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CloseButton from "./CloseButton";
import { handleEditProject } from "../../actions/projects";

class EditProjectModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  state = {
    title_ar: "",
    details_ar: "",
    title_en: "",
    details_en: "",
    website: "",
    submitDisabled: true,
  };

  handleSubmitDisabled = () => {
    const { title_ar, details_ar, title_en, details_en, website, submitDisabled } = this.state;
    if (!title_ar || !title_en || !website || (!details_ar && !details_en && !submitDisabled))
      this.setState({ submitDisabled: true });
    if (title_ar && details_ar && title_en && details_en && website && submitDisabled)
      this.setState({ submitDisabled: false });
  };
  handleTitleArChange = (e) => {
    this.setState({ title_ar: e.target.value }, this.handleSubmitDisabled);
  };
  handleDetailsArChange = (e) => {
    this.setState({ details_ar: e.target.value }, this.handleSubmitDisabled);
  };
  handleTitleEnChange = (e) => {
    this.setState({ title_en: e.target.value }, this.handleSubmitDisabled);
  };
  handleDetailsEnChange = (e) => {
    this.setState({ details_en: e.target.value }, this.handleSubmitDisabled);
  };

  handleWebsiteChange = (e) => {
    this.setState({ website: e.target.value }, this.handleSubmitDisabled);
  };

  componentDidUpdate(oldProps) {
    if (oldProps.targetProject !== this.props.targetProject) {
      const { targetProject } = this.props;
      const projectTitle_ar = targetProject ? targetProject.name.ar : "";
      const projectDetails_ar = targetProject ? targetProject.desc.ar : "";
      const projectTitle_en = targetProject ? targetProject.name.en : "";
      const projectDetails_en = targetProject ? targetProject.desc.en : "";
      const projectWebsite = targetProject ? targetProject.website : "";
      this.setState(
        {
          title_ar: projectTitle_ar,
          details_ar: projectDetails_ar,
          title_en: projectTitle_en,
          details_en: projectDetails_en,
          website: projectWebsite,
        },
        this.handleSubmitDisabled
      );
    }
  }

  handleSubmit = () => {
    const { updateProject, handleClose, editId, targetProject } = this.props;
    const { title_ar, details_ar, title_en, details_en, website } = this.state;
    const { slug, image, sliders } = targetProject;
    const data = {
      name: {
        ar: title_ar,
        en: title_en,
      },
      desc: {
        ar: details_ar,
        en: details_en,
      },
      slug,
      image,
      sliders,
      website,
    };
    updateProject(editId, data);
    handleClose();
  };

  render() {
    const { open, handleClose } = this.props;
    const { title_ar, details_ar, title_en, details_en, website, submitDisabled } = this.state;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: "dialog",
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <CloseButton handleClose={handleClose} />
        <DialogTitle id="responsive-dialog-title" className="text-center">
          تعديل المشروع
        </DialogTitle>
        <DialogContent>
          <div className="form-group">
            <TextField
              type="text"
              placeholder="اسم المشروع بالعربية"
              className="form-control"
              required
              value={title_ar}
              onChange={this.handleTitleArChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              type="text"
              placeholder="اسم المشروع بالانجليزية"
              className="form-control"
              required
              value={title_en}
              onChange={this.handleTitleEnChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              rows={3}
              placeholder="تفاصيل المشروع بالعربية"
              multiline
              required
              value={details_ar}
              onChange={this.handleDetailsArChange}
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>
          <div className="form-group">
            <TextField
              rows={3}
              placeholder="تفاصيل المشروع بالانجليزية"
              multiline
              required
              value={details_en}
              onChange={this.handleDetailsArChange}
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>
          <div className="form-group">
            <TextField
              type="url"
              placeholder="الموقع الإلكتروني"
              className="form-control"
              required
              value={website}
              onChange={this.handleWebsiteChange}
              variant="outlined"
              size="small"
            />
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center align-items-center">
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
            className="dialog__btn"
            disabled={submitDisabled}
          >
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ projects }, { editId }) => {
  const targetProject = projects.find((item) => item.id === editId);
  return { targetProject };
};

const mapDispatchToProps = (dispatch) => ({
  updateProject: (id, data) => dispatch(handleEditProject(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectModal);
