import { showLoading, hideLoading } from "react-redux-loading-bar";
import { getRoles, getRolesPage, showRole, storeRole, updateRole, destroyRole } from "../utils/api";
import { showMessageAlert } from "./messageAlert";

export const RECEIVE_ROLES = "RECEIVE_ROLES";
export const PAGINATE_ROLES = "PAGINATE_ROLES";
export const SHOW_ROLE = "SHOW_ROLE";
export const CREATE_ROLE = "CREATE_ROLE";
export const EDIT_ROLE = "EDIT_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";

const receiveRoles = (roles) => ({
  type: RECEIVE_ROLES,
  roles,
});

const createRole = (role) => ({
  type: CREATE_ROLE,
  role,
});

const editRole = (id, data) => ({
  type: EDIT_ROLE,
  id,
  data,
});

const deleteRole = (id) => ({
  type: DELETE_ROLE,
  id,
});
const paginateRoles = (roles) => ({
  type: PAGINATE_ROLES,
  roles,
});

const getRole = (role) => ({
  type: SHOW_ROLE,
  role,
});

export const handleReceiveRoles = () => (dispatch) => {
  dispatch(showLoading());
  return getRoles()
    .then((res) => res.json())
    .then((res) => {
      dispatch(receiveRoles(res.data));
      dispatch(hideLoading());
      return res;
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handlePaginateRoles = (page) => (dispatch) => {
  dispatch(showLoading());
  return getRolesPage(page)
    .then((res) => res.json())
    .then((res) => {
      dispatch(paginateRoles(res.data));
      dispatch(hideLoading());
      return res;
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleShowRole = (roleId) => (dispatch) => {
  dispatch(showLoading());
  return showRole(roleId)
    .then((res) => res.json())
    .then((res) => {
      dispatch(getRole(res.data));
      dispatch(hideLoading());
      return res;
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleCreateRole = (data) => (dispatch) => {
  dispatch(showLoading());
  storeRole(data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(createRole(res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم إضافة الدور بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleEditRole = (id, data) => (dispatch) => {
  dispatch(showLoading());
  updateRole(id, data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(editRole(id, res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم تعديل الدور بنجاح", "success"));
      dispatch(showMessageAlert("يجب إعادة تسجيل الدخول لتفعيل الدور اذا كان من ضمن أدوارك", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleDeleteRole = (id) => (dispatch) => {
  dispatch(showLoading());
  destroyRole(id)
    .then((res) => res.json())
    .then((res) => {
      dispatch(deleteRole(id));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم حذف الدور بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};
