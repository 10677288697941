import { RECEIVE_ADMINS, CREATE_ADMIN, EDIT_ADMIN, DELETE_ADMIN, PAGINATE_ADMIN } from "../actions/admins";

export default function admins(state = [], action) {
  switch (action.type) {
    case RECEIVE_ADMINS:
      return action.admins;
    case PAGINATE_ADMIN:
      return action.admins;
    case CREATE_ADMIN:
      return [action.admin, ...state];
    case EDIT_ADMIN:
      return state.map((admin) => (admin.id === action.id ? Object.assign(admin, action.data) : admin));
    case DELETE_ADMIN:
      return state.filter((admin) => admin.id !== action.id);
    default:
      return state;
  }
}
