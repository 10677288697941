import React from "react";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
import { DeleteOutline, EditOutlined, Http } from "@material-ui/icons";
import PropTypes from "prop-types";

import { connect } from "react-redux";
const ProjectsTableRow = ({
  handleEditItem,
  handleDeleteItem,
  index,
  name,
  website,
  user: { permissions, is_super_admin },
}) => (
  <TableRow>
    <TableCell align="center">{index}</TableCell>
    <TableCell align="center">{name.ar}</TableCell>
    <TableCell align="center">
      <a href={website || ""} className="icon-link" target="_blank" rel="noopener noreferrer">
        <IconButton aria-label="show">
          <Http fontSize="large" color="primary" />
        </IconButton>
      </a>
    </TableCell>
    <TableCell align="center">
      {is_super_admin || permissions.includes(45) ? (
        <IconButton aria-label="edit" onClick={handleEditItem}>
          <EditOutlined />
        </IconButton>
      ) : (
        ""
      )}
      {is_super_admin || permissions.includes(46) ? (
        <IconButton aria-label="delete" onClick={handleDeleteItem}>
          <DeleteOutline color="error" />
        </IconButton>
      ) : (
        ""
      )}
    </TableCell>
  </TableRow>
);

ProjectsTableRow.propTypes = {
  handleEditItem: PropTypes.func.isRequired,
  handleDeleteItem: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(ProjectsTableRow);
