import {
  RECEIVE_JOB_APPLICATIONS,
  DELETE_JOB_APPLICATION,
  GET_JOB_APPLICATION_DETAILS,
  PAGINATE_JOB_APPLICATIONS,
} from "../actions/jobApplications";
import sortBy from "sort-by";

export default function jobApplications(state = [], action) {
  switch (action.type) {
    case RECEIVE_JOB_APPLICATIONS:
      return action.jobApplications;
    case PAGINATE_JOB_APPLICATIONS:
      return action.jobApplications;
    case DELETE_JOB_APPLICATION:
      return state.filter((jobApplication) => jobApplication.id !== action.id);
    case GET_JOB_APPLICATION_DETAILS:
      return [...state, action.jobApplication];
    default:
      return state;
  }
}
