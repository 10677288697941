import React, { Component } from "react";
import { BusinessCenterOutlined, AddCircleOutline } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import JobsTable from "../jobs/JobsTable";
import AddJobModal from "../modals/AddJobModal";
import { Link, Redirect } from "react-router-dom";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
import { connect } from "react-redux";
import { handleReceiveJobs, handleDeleteJob, handlePaginateJobs } from "../../actions/jobs";
import JobsCard from "../jobs/JobsCard";

class JobsView extends Component {
  state = {
    openAddDialog: false,
    openDeleteDialog: false,
    deleteId: null,
    pagination: {
      per_page: 15,
      current_page: 1,
      last_page: 1,
    },
    paginationDisabled: false,
  };
  handleOpenAddDialog = () => {
    this.setState({ openAddDialog: true });
  };
  handleCloseAddDialog = () => {
    this.setState({ openAddDialog: false });
  };
  handleOpenDeleteDialog = (id) => {
    this.setState({ openDeleteDialog: true, deleteId: id });
  };
  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDialog: false, deleteId: null });
  };

  handleConfirmDelete = () => {
    const { deleteJob } = this.props;
    const { deleteId } = this.state;
    deleteJob(deleteId);
  };

  handlePaginationChange = (e, current_page) => {
    const { last_page } = this.state.pagination;
    const { getJobsPagination } = this.props;
    if (last_page >= current_page) {
      this.setState({ paginationDisabled: true });
      getJobsPagination(current_page).then(() => {
        this.setState((prevState) => ({
          pagination: { ...prevState.pagination, current_page },
          paginationDisabled: false,
        }));
      });
    }
  };

  componentDidMount() {
    const { getJobs } = this.props;
    getJobs().then((res) => {
      if (res) {
        const {
          meta: { per_page, current_page, last_page, total },
        } = res;
        this.setState({
          pagination: {
            per_page,
            current_page,
            last_page,
            total,
          },
        });
      }
    });
  }

  render() {
    const {
      openAddDialog,
      openDeleteDialog,
      pagination: { current_page, last_page, per_page },
      paginationDisabled,
    } = this.state;
    const {
      jobs,
      user: { permissions, is_super_admin },
    } = this.props;
    const paginatedJobs =
      jobs.length >= current_page * per_page
        ? jobs.slice((current_page - 1) * per_page, current_page * per_page - 1)
        : jobs.slice(-per_page);

    return (
      <div className="pb-5 container overflow-hidden">
        {!is_super_admin && !permissions.includes(22) && !permissions.includes(22) ? <Redirect to="/" /> : ""}
        <div className="row px-3 pt-3 main-header">
          <div className="col-12">
            <h2 className="page-title">الوظائف</h2>
          </div>
        </div>
        <div className="row p-3">
          <div className="mb-3">
            <h3 className="mb-3 page-subtitle">نظرة عامة</h3>
          </div>
        </div>
        <div className="row p-3">
          <JobsCard title="عدد الوظائف المطروحة" count={jobs.length}>
            <BusinessCenterOutlined />
          </JobsCard>
        </div>
        <div className="row p-3">
          <div className="col-12">
            <p className="d-inline-block pl-2 ml-2">
              {is_super_admin || permissions.includes(22) ? (
                <Link to="/job-applications" className="icon-link text-decoration-none text-black">
                  طلبات التوظيف
                </Link>
              ) : (
                ""
              )}
            </p>
            <p className="d-inline-block px-2 mx-2 font-weight-bold">
              {is_super_admin || permissions.includes(9) ? (
                <Link to="/jobs" className="icon-link text-decoration-none text-black">
                  الوظائف
                </Link>
              ) : (
                <Redirect to="/job-applications" />
              )}
            </p>
            {is_super_admin || permissions.includes(10) ? (
              <button className="add-btn btn px-2 mx-2" onClick={this.handleOpenAddDialog}>
                <AddCircleOutline /> إضافة وظيفة جديدة
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row p-3">
          <div className="col-12">
            <JobsTable handleDeleteItem={this.handleOpenDeleteDialog} jobs={jobs} pagination={this.state.pagination} />
          </div>
        </div>
        {jobs && (
          <div className="my-3 d-flex justify-content-center align-items-center flex-grow-1">
            <Pagination
              variant="outlined"
              shape="rounded"
              count={last_page}
              page={current_page}
              boundaryCount={2}
              onChange={this.handlePaginationChange}
              disabled={paginationDisabled}
            />
          </div>
        )}
        <AddJobModal open={openAddDialog} handleClose={this.handleCloseAddDialog} />
        <ConfirmDeleteModal
          open={openDeleteDialog}
          handleClose={this.handleCloseDeleteDialog}
          text="هل تريد حذف الوظيفة؟"
          handleDelete={this.handleConfirmDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ jobs, user }) => ({ jobs, user });

const mapDispatchToProps = (dispatch) => ({
  getJobs: () => dispatch(handleReceiveJobs()),
  deleteJob: (id) => dispatch(handleDeleteJob(id)),
  getJobsPagination: (page) => dispatch(handlePaginateJobs(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobsView);
