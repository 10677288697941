import React, { Component } from "react";
import { validateEmail } from "../../utils/helperFunctions/validation-functions";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CloseButton from "./CloseButton";
import { handleEditAdmin } from "../../actions/admins";

class EditAdminModal extends Component {
  constructor(props) {
    super(props);
    this.statuses = [
      { id: 1, name: "نشط" },
      { id: 2, name: "قيد الانتظار" },
      { id: 3, name: "مرفوض" },
      { id: 4, name: "محظور" },
    ];
  }
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    editId: PropTypes.number,
    availableRoles: PropTypes.array.isRequired,
  };

  state = {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    status: "",
    roles: [],
    submitDisabled: true,

    roles_changed: false,
  };

  componentDidUpdate(oldProps) {
    if (oldProps.targetAdmin !== this.props.targetAdmin) {
      const { targetAdmin } = this.props;
      const adminName = targetAdmin ? targetAdmin.name : "";
      const adminEmail = targetAdmin ? targetAdmin.email : "";
      const adminStatus = targetAdmin ? targetAdmin.status : "";
      const adminPassword = targetAdmin ? targetAdmin.password_confirmation : "";
      const adminPassword_confirmation = targetAdmin ? targetAdmin.password_confirmation : "";
      const adminRoles = targetAdmin
        ? targetAdmin.roles.length > 0
          ? targetAdmin.roles.map((role) => role.id)
          : ["DEFAULT"]
        : ["DEFAULT"];

      this.setState({
        name: adminName,
        email: adminEmail,
        status: adminStatus,
        password: adminPassword,
        password_confirmation: adminPassword_confirmation,
        roles: adminRoles,
        submitDisabled: false,
      });
    }
  }

  handleSubmitDisabled = () => {
    const { name, email, password, password_confirmation, status, submitDisabled } = this.state;
    if (
      name &&
      email &&
      password_confirmation === password &&
      status &&
      // submitDisabled &&
      validateEmail(email)
    ) {
      this.setState({ submitDisabled: false });
    } else if (submitDisabled === false) {
      this.setState({ submitDisabled: true });
    }
  };
  handleInputChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    this.setState({ [name]: value }, this.handleSubmitDisabled);
  };
  handleSelectChange = (e) => {
    var { name, value } = e.target;
    this.setState({ roles_changed: true });
    // remove default value which works as placeholder
    const defaultIndex = value.indexOf("DEFAULT");
    if (defaultIndex !== -1) {
      value.splice(defaultIndex, 1);
    }
    if (value.length == 0) {
      value = ["DEFAULT"];
    }
    this.setState({ [name]: value }, this.handleSubmitDisabled);
  };
  handleSubmit = () => {
    const { updateAdmin, handleClose, editId, user_id } = this.props;
    const { name, email, roles, password, password_confirmation, status, roles_changed } = this.state;
    const data = {
      name: name,
      email: email,
      password: password,
      status: status,
      password_confirmation: password_confirmation,
      roles: roles[0] !== "DEFAULT" ? roles : [],
    };
    const same_user = editId == user_id && roles_changed;

    updateAdmin(editId, data, same_user);
    handleClose();
  };

  render() {
    const { open, handleClose, availableRoles } = this.props;
    const { name, email, password, password_confirmation, status, submitDisabled, roles } = this.state;
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: "dialog",
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <CloseButton handleClose={handleClose} />
        <DialogTitle id="responsive-dialog-title" className="text-center">
          تعديل مدير
        </DialogTitle>
        <DialogContent>
          <div className="form-group">
            <TextField
              type="text"
              className="form-control"
              required
              placeholder="الاسم "
              value={name}
              name="name"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              type="email"
              className="form-control"
              required
              placeholder="البريد الإلكتروني "
              value={email}
              name="email"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <Select
              className="form-control"
              placeholder="حالة"
              value={status}
              name="status"
              onChange={this.handleInputChange}
            >
              {this.statuses.map((status, index) => (
                <MenuItem key={index} value={status.id}>
                  {status.name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="form-group">
            <Select
              className="form-control"
              required
              multiple
              placeholder="الدور"
              // get list of role ids out of roles
              value={roles}
              name="roles"
              onChange={this.handleSelectChange}
            >
              <MenuItem disabled value="DEFAULT">
                دور المدير
              </MenuItem>
              {availableRoles.map((role) => {
                return (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className="form-group">
            <TextField
              type="password"
              className="form-control"
              required
              placeholder="كلمة المرور "
              value={password}
              name="password"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              type="password"
              className="form-control"
              required
              placeholder=" تأكيد كلمة المرور "
              value={password_confirmation}
              name="password_confirmation"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center align-items-center">
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
            className="dialog__btn"
            disabled={submitDisabled}
          >
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ admins, roles, user }, { editId }) => {
  const targetAdmin = admins.find((item) => item.id === editId);
  return { targetAdmin, user_id: user.user_id, availableRoles: roles };
};

const mapDispatchToProps = (dispatch) => ({
  updateAdmin: (id, data, same_user) => dispatch(handleEditAdmin(id, data, same_user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAdminModal);
