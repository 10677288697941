import React, { Component } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CloseButton from "./CloseButton";
import NewsImageUpload from "../last-news/NewsImageUpload";
import { handleEditTestimonial } from "../../actions/testimonials";
import { uploadMedia } from "../../utils/api";

class EditTestimonialModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    editId: PropTypes.number,
  };

  state = {
    testimonial_ar: "",
    name_ar: "",
    testimonial_en: "",
    name_en: "",
    submitDisabled: true,
    files: [],
    imagePath: "",
    imageLoading: true,
  };

  componentDidUpdate(oldProps) {
    if (oldProps.targetTestimonial !== this.props.targetTestimonial) {
      const { targetTestimonial } = this.props;

      const testimonialName_ar = targetTestimonial ? targetTestimonial.name.ar : "";
      const testimonialContent_ar = targetTestimonial ? targetTestimonial.content.ar : "";
      const testimonialName_en = targetTestimonial ? targetTestimonial.name.en : "";
      const testimonialContent_en = targetTestimonial ? targetTestimonial.content.en : "";

      const testimonialImage = targetTestimonial ? targetTestimonial.image : "";
      this.setState(
        {
          name_ar: testimonialName_ar,
          testimonial_ar: testimonialContent_ar,
          name_en: testimonialName_en,
          testimonial_en: testimonialContent_en,
          imagePath: testimonialImage,
        },
        this.handleSubmitDisabled
      );
    }
  }

  handleSubmitDisabled = () => {
    const { testimonial_ar, name_ar, testimonial_en, name_en, imagePath, submitDisabled } = this.state;
    if (!testimonial_ar || !name_ar || !testimonial_en || !name_en || (!imagePath && !submitDisabled))
      this.setState({ submitDisabled: true });
    if (testimonial_ar && testimonial_en && imagePath && name_ar && name_en && submitDisabled)
      this.setState({ submitDisabled: false });
  };
  handleTestimoinalArChange = (e) => {
    this.setState({ testimonial_ar: e.target.value }, this.handleSubmitDisabled);
  };
  handleNameArChange = (e) => {
    this.setState({ name_ar: e.target.value });
  };
  handleTestimoinalEnChange = (e) => {
    this.setState({ testimonial_en: e.target.value }, this.handleSubmitDisabled);
  };
  handleNameEnChange = (e) => {
    this.setState({ name_en: e.target.value });
  };

  handleSetFiles = (files) => {
    this.setState(
      { files },
      () => {
        const { files } = this.state;
        const form = new FormData();
        form.append("file", files[0]);
        form.append("mime_type", "image");

        uploadMedia(form)
          .then((res) => res.json())
          .then((res) => {
            this.setState({ imagePath: res.path, imageLoading: false });
          });
      },
      this.handleSubmitDisabled
    );
  };
  handleSubmit = () => {
    const { updateTestimonial, handleClose, editId } = this.props;
    const { name_ar, testimonial_ar, name_en, testimonial_en, imagePath } = this.state;
    const data = {
      name: {
        ar: name_ar,
        en: name_en,
      },
      content: {
        ar: testimonial_ar,
        en: testimonial_en,
      },
      image: imagePath,
    };
    updateTestimonial(editId, data);
    handleClose();
  };

  render() {
    const { open, handleClose } = this.props;
    const { testimonial_ar, name_ar, testimonial_en, name_en, files, submitDisabled, imagePath, imageLoading } =
      this.state;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: "dialog",
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <CloseButton handleClose={handleClose} />
        <DialogTitle id="responsive-dialog-title" className="text-center">
          تعديل الشهادة
        </DialogTitle>
        <DialogContent>
          <div className="form-group">
            <NewsImageUpload files={files} setFiles={this.handleSetFiles} loading={imageLoading} image={imagePath} />
          </div>
          <div className="form-group">
            <TextField
              type="text"
              className="form-control"
              required
              placeholder="الاسم بالعربية"
              value={name_ar}
              onChange={this.handleNameArChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              type="text"
              className="form-control"
              required
              placeholder="الاسم بالانجليزية"
              value={name_en}
              onChange={this.handleNameEnChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              rows={5}
              placeholder="تفاصيل الشهادة بالعربية"
              multiline
              required
              value={testimonial_ar}
              onChange={this.handleTestimoinalArChange}
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>
          <div className="form-group">
            <TextField
              rows={5}
              placeholder="تفاصيل الشهادة بالانجليزية"
              multiline
              required
              value={testimonial_en}
              onChange={this.handleTestimoinalEnChange}
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center align-items-center">
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
            className="dialog__btn"
            disabled={submitDisabled}
          >
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ testimonials }, { editId }) => {
  const targetTestimonial = testimonials.find((item) => item.id === editId);
  return { targetTestimonial };
};

const mapDispatchToProps = (dispatch) => ({
  updateTestimonial: (id, data) => dispatch(handleEditTestimonial(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTestimonialModal);
