import { IconButton, TableCell, TableRow } from "@material-ui/core";
import { DeleteOutline, EditOutlined } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";
import EllipsisText from "react-ellipsis-text";
import { connect } from "react-redux";
import { serializeSlateValue } from "../../utils/helpers";
const ArticlesTableRow = ({
  handleEditItem,
  handleDeleteItem,
  title,
  content,
  id,
  index,
  pagination,
  user: { permissions, is_super_admin },
}) =>
  (
    <TableRow>
      <TableCell align="center">{index + (pagination.current_page - 1) * pagination.per_page}</TableCell>
      <TableCell align="center">{title.ar}</TableCell>
      <TableCell align="center">
        <EllipsisText text={serializeSlateValue(content.ar)} length={55}/>
      </TableCell>
      <TableCell align="center" style={{whiteSpace: "nowrap"}}>
        {(is_super_admin || permissions.includes(123)) && (
          <IconButton aria-label="edit" onClick={handleEditItem}>
            <EditOutlined/>
          </IconButton>
        )}
        {(is_super_admin || permissions.includes(124)) && (
          <IconButton aria-label="delete" onClick={handleDeleteItem}>
            <DeleteOutline color="error"/>
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );

ArticlesTableRow.propTypes = {
  handleEditItem: PropTypes.func.isRequired,
  handleDeleteItem: PropTypes.func.isRequired,
  title: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(ArticlesTableRow);
