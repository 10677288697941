export const SHOW_MESSAGE_ALERT = "SHOW_MESSAGE_ALERT";
export const CLOSE_MESSAGE_ALERT = "CLOSE_MESSAGE_ALERT";

export const showMessageAlert = (message, level) => ({
  type: SHOW_MESSAGE_ALERT,
  message,
  level,
});

export const closeMessageAlert = () => ({
  type: CLOSE_MESSAGE_ALERT,
});
