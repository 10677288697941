import {
  RECEIVE_TESTIMONIALS,
  CREATE_TESTIMONIAL,
  EDIT_TESTIMONIAL,
  DELETE_TESTIMONIAL,
} from "../actions/testimonials";

export default function testimonials(state = [], action) {
  switch (action.type) {
    case RECEIVE_TESTIMONIALS:
      return action.testimonials;
    case CREATE_TESTIMONIAL:
      return [action.testimonial, ...state];
    case EDIT_TESTIMONIAL:
      return state.map((testimonial) =>
        testimonial.id === action.id ? Object.assign(testimonial, action.data) : testimonial
      );
    case DELETE_TESTIMONIAL:
      return state.filter((testimonial) => testimonial.id !== action.id);
    default:
      return state;
  }
}
