import React, { Component } from "react";
import { Button, TableContainer, Table, TableBody } from "@material-ui/core";
import { NoteOutlined } from "@material-ui/icons";
import RequestNoteCard from "../price-requsets/RequestNoteCard";
import AddNoteModal from "../modals/AddNoteModal";
import PriceDetailsTable from "../price-requsets/PriceDetailsTable";
import RequestModelAnswer from "../price-requsets/RequestModelAnswer";
import EditNoteModal from "../modals/EditNoteModal";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
import { connect } from "react-redux";
import {
  handleReceivePriceRequestNotes,
  handleCreatePriceRequestNote,
  handleDeletePriceRequestNote,
  handleEditPriceRequestNote,
} from "../../actions/priceRequestNotes";
import { handleReceivePriceRequestDetails } from "../../actions/priceRequests";

class PriceRequestDetails extends Component {
  state = {
    openAddDialog: false,
    openDeleteDialog: false,
    deleteId: null,
    openEditDialog: false,
    editId: null,
  };
  handleOpenAddDialog = () => {
    this.setState({ openAddDialog: true });
  };
  handleCloseAddDialog = () => {
    this.setState({ openAddDialog: false });
  };
  handleOpenDeleteDialog = (id) => {
    this.setState({ openDeleteDialog: true, deleteId: id });
  };
  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDialog: false, deleteId: null });
  };
  handleOpenEditDialog = (id) => {
    this.setState({ openEditDialog: true, editId: id });
  };
  handleCloseEditDialog = () => {
    this.setState({ openEditDialog: false, editId: null });
  };

  handleSubmitAddNote = (content) => {
    const { createPriceRequestNote, priceRequestId } = this.props;
    const data = { pricing_request_id: priceRequestId, content };
    createPriceRequestNote(data);
  };

  handleSubmitEditNote = (content) => {
    const { updatePriceRequestNote, priceRequestId } = this.props;
    const { editId } = this.state;
    const data = { content };
    updatePriceRequestNote(priceRequestId, editId, data);
  };

  handleConfirmDeleteNote = () => {
    const { deletePriceRequestNote, priceRequestId } = this.props;
    const { deleteId } = this.state;
    deletePriceRequestNote(priceRequestId, deleteId);
  };

  componentDidMount() {
    const {
      targetPriceRequest,
      priceRequestId,
      notes,
      getPriceRequestDetails,
      getPriceRequestNotes,
      user: { permissions, is_super_admin },
    } = this.props;
    if (!targetPriceRequest) getPriceRequestDetails(priceRequestId);
    if (is_super_admin || permissions.includes(30)) {
      if (!notes) getPriceRequestNotes(priceRequestId);
    }
  }

  render() {
    const { openAddDialog, openDeleteDialog, openEditDialog, editId } = this.state;
    const {
      meta,
      notes,
      country,
      person,
      service_name,
      user: { permissions, is_super_admin },
    } = this.props;
    const note = editId ? notes.find((item) => item.id === editId) : undefined;

    return (
      <div className="pb-5 container overflow-hidden">
        <div className="row px-3 pt-3 main-header">
          <div className="col-12">
            <h2 className="page-title">طلبات الاسعار</h2>
          </div>
        </div>
        <div className="row p-3">
          <div className="col d-flex align-items-center">
            <h5 className="mb-0 ml-md-3 text-theme-primary page-subtitle-lead">معلومات التواصل</h5>
          </div>
        </div>
        <div className="row">
          <div className="d-flex px-3">
            <PriceDetailsTable person={person} country={country} service_name={service_name} />
          </div>
        </div>
        <div className="row px-3 mt-3">
          <div className="col-12 mb-3">
            <h5 className="m-0 text-theme-primary">إجابات النموذج</h5>
          </div>
          {meta && (
            <TableContainer className="table-responsive">
              <Table className="table table-borderless">
                <TableBody>
                  {Object.entries(meta).map(([key, value], index) => (
                    <div className="d-flex px-3">
                      <RequestModelAnswer question={key} answer={value} key={index} />
                    </div>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <div className="row p-3 mt-3">
          <div className="col-12 mb-2">
            <h6 className="text-theme-primary">ملاحظات</h6>
          </div>
          <div className="col-12">
            {is_super_admin || permissions.includes(31) ? (
              <Button
                color="primary"
                variant="contained"
                startIcon={<NoteOutlined className="ml-2" />}
                onClick={this.handleOpenAddDialog}
              >
                إضافة ملاحظة
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
        {notes && (
          <div className="row mb-3">
            <div className="col-12 col-md-10">
              {notes.map((note) => (
                <RequestNoteCard
                  handleEditNote={() => this.handleOpenEditDialog(note.id)}
                  handleDeleteNote={() => this.handleOpenDeleteDialog(note.id)}
                  key={note.id}
                  {...note}
                />
              ))}
            </div>
          </div>
        )}
        <AddNoteModal
          open={openAddDialog}
          handleClose={this.handleCloseAddDialog}
          handleAddNote={this.handleSubmitAddNote}
        />
        <EditNoteModal
          open={openEditDialog}
          handleClose={this.handleCloseEditDialog}
          handleEditNote={this.handleSubmitEditNote}
          note={note}
        />
        <ConfirmDeleteModal
          open={openDeleteDialog}
          handleClose={this.handleCloseDeleteDialog}
          text="هل تريد حذف الملاحظة؟"
          handleDelete={this.handleConfirmDeleteNote}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ priceRequests, priceRequestNotes, user }, props) => {
  const priceRequestId = parseInt(props.match.params.id);
  const targetPriceRequest = priceRequests.find((item) => item.id === priceRequestId);
  const notes = priceRequestNotes[priceRequestId];
  if (targetPriceRequest) {
    const { id, meta, country, service_name, ...person } = targetPriceRequest;
    return {
      targetPriceRequest,
      meta,
      country,
      service_name,
      person,
      priceRequestId,
      notes,
      user,
    };
  }
  return { targetPriceRequest, priceRequestId, notes, user };
};

const mapDispatchToProps = (dispatch) => ({
  getPriceRequestDetails: (id) => dispatch(handleReceivePriceRequestDetails(id)),
  getPriceRequestNotes: (priceRequestId) => dispatch(handleReceivePriceRequestNotes(priceRequestId)),
  createPriceRequestNote: (data) => dispatch(handleCreatePriceRequestNote(data)),
  deletePriceRequestNote: (priceRequestId, noteId) => dispatch(handleDeletePriceRequestNote(priceRequestId, noteId)),
  updatePriceRequestNote: (priceRequestId, noteId, note) =>
    dispatch(handleEditPriceRequestNote(priceRequestId, noteId, note)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PriceRequestDetails);
