import { Pagination } from "@material-ui/lab";
import React, { Component } from "react";
import { connect } from "react-redux";
import { handleDeleteArticle, handleReceiveArticles } from "../../actions/articles";
import Layout from "../base/Layout";
import ArticlesTable from "../articles/ArticlesTable";
import AddArticle from "../modals/AddArticle";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
import EditArticle from "../modals/EditArticle";

class Articles extends Component {
  state = {
    openAddDialog: false,
    openDeleteDialog: false,
    deleteId: null,
    openEditDialog: false,
    editId: null,
    pagination: {
      per_page: 15,
      current_page: 1,
      last_page: 1,
    },
    paginationDisabled: false,
  };
  handleOpenAddDialog = () => {
    this.setState({ openAddDialog: true });
  };
  handleCloseAddDialog = () => {
    this.setState({ openAddDialog: false });
  };
  handleOpenDeleteDialog = (id) => {
    this.setState({ openDeleteDialog: true, deleteId: id });
  };
  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDialog: false, deleteId: null });
  };
  handleOpenEditDialog = (id) => {
    this.setState({ openEditDialog: true, editId: id });
  };
  handleCloseEditDialog = () => {
    this.setState({ openEditDialog: false, editId: null });
  };

  handleConfirmDelete = () => {
    const { deleteArticle } = this.props;
    const { deleteId } = this.state;
    deleteArticle(deleteId);
  };

  handlePaginationChange = (e, current_page) => {
    const { last_page } = this.state.pagination;
    const { getArticles } = this.props;
    if (last_page >= current_page) {
      this.setState({ paginationDisabled: true });
      getArticles(current_page).then(() => {
        this.setState((prevState) => ({
          pagination: { ...prevState.pagination, current_page },
          paginationDisabled: false,
        }));
      });
    }
  };

  componentDidMount() {
    const { getArticles } = this.props;
    getArticles().then((res) => {
      if (res) {
        const {
          meta: { per_page, current_page, last_page },
        } = res;
        this.setState({
          pagination: {
            per_page,
            current_page,
            last_page,
          },
        });
      }
    });
  }

  render() {
    const {
      openAddDialog,
      openDeleteDialog,
      openEditDialog,
      editId,
      pagination: { current_page, last_page },
      paginationDisabled,
    } = this.state;
    const {
      articles,
      user: { permissions, is_super_admin },
    } = this.props;

    return (
      <Layout
        title="آخر المقالات"
        addBtnText="إضافة مقال جديد"
        showAddBtn={is_super_admin || permissions.includes(122)}
        handleBtnClicked={this.handleOpenAddDialog}
      >
        <ArticlesTable
          handleEditItem={this.handleOpenEditDialog}
          handleDeleteItem={this.handleOpenDeleteDialog}
          articles={articles}
          pagination={this.state.pagination}
        />
        {articles && (
          <div className="my-3 d-flex justify-content-center align-items-center flex-grow-1">
            <Pagination
              variant="outlined"
              shape="rounded"
              count={last_page}
              page={current_page}
              boundaryCount={2}
              onChange={this.handlePaginationChange}
              disabled={paginationDisabled}
            />
          </div>
        )}
        {(is_super_admin || permissions.includes(122)) && (
          <AddArticle open={openAddDialog} handleClose={this.handleCloseAddDialog} />
        )}
        {(is_super_admin || permissions.includes(123)) && (
          <EditArticle open={openEditDialog} handleClose={this.handleCloseEditDialog} editId={editId} />
        )}
        <ConfirmDeleteModal
          open={openDeleteDialog}
          handleClose={this.handleCloseDeleteDialog}
          text="هل تريد حذف المقال؟"
          handleDelete={this.handleConfirmDelete}
        />
      </Layout>
    );
  }
}

const mapStateToProps = ({ articles, user }) => ({ articles, user });

const mapDispatchToProps = (dispatch) => ({
  getArticles: (page) => dispatch(handleReceiveArticles(page)),
  deleteArticle: (id) => dispatch(handleDeleteArticle(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
