import React from "react";
import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from "@material-ui/core";
import RolesListItem from "./rolesListItem";
import PropTypes from "prop-types";

const RolesList = ({ handleEditItem, handleDeleteItem, roles }) =>
  roles.length === 0 ? (
    <Paper className="p-4 text-center">لا يوجد ادوار</Paper>
  ) : (
    <TableContainer component={Paper} className="table-responsive">
      <Table className="table table-fixed">
        <TableHead>
          <TableRow className="admins__theader">
            <TableCell align="center">#</TableCell>
            <TableCell align="center">الاسم</TableCell>
            <TableCell align="center">افعال</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {roles.map((item, index) => (
            <RolesListItem
              {...item}
              index={index + 1}
              handleEditItem={() => handleEditItem(item.id)}
              handleDeleteItem={() => handleDeleteItem(item.id)}
              key={item.id}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
RolesList.propTypes = {
  // handleDeleteItem: PropTypes.func.isRequired,
  handleEditItem: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
};

export default RolesList;
