import React, { Component } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CloseButton from "./CloseButton";
import { handleCreateJob } from "../../actions/jobs";

class AddJobModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  state = {
    jobTitle_ar: "",
    jobDetails_ar: "",
    jobLocation_ar: "",
    jobTitle_en: "",
    jobDetails_en: "",
    jobLocation_en: "",
    submitDisabled: true,
  };

  handleSubmitDisabled = () => {
    const { jobTitle_ar, jobDetails_ar, jobLocation_ar, jobTitle_en, jobDetails_en, jobLocation_en, submitDisabled } =
      this.state;
    if (!jobTitle_ar || !jobDetails_ar || !jobTitle_en || !jobDetails_en || !jobLocation_en || !jobLocation_ar) {
      this.setState({ submitDisabled: true });
    } else if (submitDisabled) {
      this.setState({ submitDisabled: false });
    }
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, this.handleSubmitDisabled);
  };

  handleSubmit = () => {
    const { createJob, handleClose } = this.props;
    const { jobTitle_ar, jobDetails_ar, jobLocation_ar, jobTitle_en, jobDetails_en, jobLocation_en } = this.state;
    const data = {
      title: {
        ar: jobTitle_ar,
        en: jobTitle_en,
      },
      desc: {
        ar: jobDetails_ar,
        en: jobDetails_en,
      },
      location: {
        ar: jobLocation_ar,
        en: jobLocation_en,
      },
    };
    createJob(data);
    handleClose();
    this.setState({
      jobTitle_ar: "",
      jobTitle_en: "",
      jobDetails_ar: "",
      jobDetails_en: "",
      jobLocation_ar: "",
      jobLocation_en: "",
    });
  };

  render() {
    const { open, handleClose } = this.props;
    const { jobTitle_ar, jobDetails_ar, jobLocation_ar, jobTitle_en, jobDetails_en, jobLocation_en, submitDisabled } =
      this.state;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: "dialog",
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <CloseButton handleClose={handleClose} />
        <DialogTitle id="responsive-dialog-title" className="text-center">
          إضافة وظيفة جديدة
        </DialogTitle>
        <DialogContent>
          <div className="form-group">
            <TextField
              type="text"
              placeholder="عنوان الوظيفة بالعربية"
              className="form-control"
              required
              value={jobTitle_ar}
              name="jobTitle_ar"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              type="text"
              placeholder="عنوان الوظيفة بالانجليزية"
              className="form-control"
              required
              value={jobTitle_en}
              name="jobTitle_en"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              rows={3}
              placeholder="تفاصيل الوظيفة بالعربية"
              required
              value={jobDetails_ar}
              name="jobDetails_ar"
              onChange={this.handleInputChange}
              multiline
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>
          <div className="form-group">
            <TextField
              rows={3}
              placeholder="تفاصيل الوظيفة بالانجليزية"
              required
              value={jobDetails_en}
              name="jobDetails_en"
              onChange={this.handleInputChange}
              multiline
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>
          <div className="form-group">
            <TextField
              type="text"
              placeholder="مكان الوظيفة بالعربية"
              className="form-control"
              required
              value={jobLocation_ar}
              name="jobLocation_ar"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              type="text"
              placeholder="مكان الوظيفة بالانجليزية"
              className="form-control"
              required
              value={jobLocation_en}
              name="jobLocation_en"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center align-items-center">
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
            className="dialog__btn"
            disabled={submitDisabled}
          >
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ countries }) => ({ countries });

const mapDispatchToProps = (dispatch) => ({
  createJob: (data) => dispatch(handleCreateJob(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddJobModal);
