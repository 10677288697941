import React, { Component } from "react";
import { ArrowBack, ArrowForward, Assignment, Language, People, Create, BusinessCenter } from "@material-ui/icons";
import HomeSliderItem from "./HomeSliderItem";
import AliceCarousel from "react-alice-carousel";
import { connect } from "react-redux";
import { handleReceiveStatistics } from "../../actions/statistics";

const responsive = {
  0: {
    items: 1,
  },
  576: {
    items: 2,
  },
  992: {
    items: 3,
  },
  1200: {
    items: 4,
  },
};

class HomeSlider extends Component {
  goToNextSlide = () => {
    this.slider.slideNext();
  };
  goToPreviousSlide = () => {
    this.slider.slidePrev();
  };

  componentDidMount() {
    const { getStatistics } = this.props;
    getStatistics();
  }

  render() {
    const { statistics } = this.props;

    return (
      <div className="row px-3">
        <div className="px-3">
          <ArrowForward className="home-slider-btn" onClick={this.goToPreviousSlide} />
          <ArrowBack className="home-slider-btn" onClick={this.goToNextSlide} />
        </div>
        <AliceCarousel
          key={Object.keys(statistics).length}
          responsive={responsive}
          buttonsDisabled
          dotsDisabled
          ref={(el) => (this.slider = el)}
        >
          <HomeSliderItem title="عدد طلبات الاسعار" count={statistics.price_requests}>
            <Assignment />
          </HomeSliderItem>
          <HomeSliderItem title="عدد الدول" count={statistics.countries}>
            <Language />
          </HomeSliderItem>
          <HomeSliderItem title="عدد الشركاء" count={statistics.partners}>
            <People />
          </HomeSliderItem>
          <HomeSliderItem title="عدد المشاريع" count={statistics.projects}>
            <Create />
          </HomeSliderItem>
          <HomeSliderItem title="عدد الوظائف" count={statistics.jobs}>
            <BusinessCenter />
          </HomeSliderItem>
        </AliceCarousel>
      </div>
    );
  }
}

const mapStateToProps = ({ statistics }) => ({ statistics });

const mapDispatchToProps = (dispatch) => ({
  getStatistics: () => dispatch(handleReceiveStatistics()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeSlider);
