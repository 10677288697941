import {
  Timeline,
  Dialpad,
  Create,
  PanTool,
  Contacts,
  Assignment,
  BusinessCenterOutlined,
  Announcement,
  Group,
  SupervisorAccount,
  CropFree,
  NoteAdd
} from "@material-ui/icons";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
const menu = [
  { Icon: Timeline, text: "إحصائيات", href: "/home" },
  { Icon: SupervisorAccount, text: "أدوار المشرفين", href: "/roles", permission: [47] },
  { Icon: AssignmentIndIcon, text: "مشرفي الموقع", href: "/admins", permission: [1] },
  { Icon: Dialpad, text: "السلايدر", href: "/slider", permission: [59] },
  { Icon: Announcement, text: "اخر الاخبار", href: "/news", permission: [13] },
  { Icon: Create, text: "الاعمال", href: "/projects", permission: [43] },
  { Icon: PanTool, text: "شهادة العملاء", href: "/testimonials", permission: [35] },
  { Icon: Contacts, text: "معلومات التواصل", href: "/contacts", permission: [55] },
  { Icon: Assignment, text: "طلبات الاسعار", href: "/price-requests", permission: [24] },
  { Icon: BusinessCenterOutlined, text: "الوظائف", href: "/jobs", permission: [9, 22] },
  { Icon: Group, text: "شركاؤنا", href: "/partners", permission: [39] },
  { Icon: NoteAdd, text: "المقالات", href: "/articles", permission: [121] },
  { Icon: CropFree, text: "QR Codes", href: "/qr-codes", permission: [39] },
];

export default menu;
