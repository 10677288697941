import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { EditSharp } from "@material-ui/icons";
import { Avatar, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";

function ImageUpload({ image, files, setFiles, loading }) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <div className="image-upload__rounded">
      <div className="image-upload">
        <div
          {...getRootProps({
            className: "image-upload__root",
          })}
        >
          <input {...getInputProps()} />
          {loading ? <CircularProgress size={20} /> : <EditSharp color="primary" />}
        </div>
      </div>
      <div className="d-flex w-100 h-100">
        <Avatar
          className="w-100 h-100 image-upload__img"
          variant="rounded"
          src={files[0] ? files[0].preview : image || "/images/project-placeholder.jpg"}
          alt="profile picture"
        ></Avatar>
      </div>
    </div>
  );
}

ImageUpload.propTypes = {
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ImageUpload;
