import React from "react";
import { Snackbar, Grow } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import { closeMessageAlert } from "../../actions/messageAlert";
import PropTypes from "prop-types";

const MessageAlert = ({ open, message, level, handleClose }) => (
  <Snackbar
    open={open}
    autoHideDuration={5000}
    onClose={handleClose}
    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    key="bottom, center"
    TransitionComponent={Grow}
    dir="ltr"
  >
    <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={level}>
      {message}
    </MuiAlert>
  </Snackbar>
);

const mapStateToProps = ({ messageAlert: { open, message, level } }) => ({
  open,
  message,
  level,
});

const mapDispatchToProps = (dispatch) => ({
  handleClose: (e, reason) => {
    if (reason === "clickaway") return;
    dispatch(closeMessageAlert());
  },
});

MessageAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageAlert);
