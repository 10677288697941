import { showLoading, hideLoading } from "react-redux-loading-bar";
import { getPriceRequests, destroyPriceRequest, getPriceRequestDetails, getPriceRequestsPage } from "../utils/api";
import { showMessageAlert } from "./messageAlert";

export const RECEIVE_PRICE_REQUESTS = "RECEIVE_PRICE_REQUESTS";
export const DELETE_PRICE_REQUEST = "DELETE_PRICE_REQUEST";
export const GET_PRICE_REQUEST_DETAILS = "GET_PRICE_REQUEST_DETAILS";
export const PAGINATE_PRICE_REQUESTS = "PAGINATE_PRICE_REQUESTS";

const receivePriceRequests = (priceRequests) => ({
  type: RECEIVE_PRICE_REQUESTS,
  priceRequests,
});

const deletePriceRequest = (id) => ({
  type: DELETE_PRICE_REQUEST,
  id,
});

const receivePriceRequestDetails = (priceRequest) => ({
  type: GET_PRICE_REQUEST_DETAILS,
  priceRequest,
});

const paginatePriceRequests = (priceRequests) => ({
  type: PAGINATE_PRICE_REQUESTS,
  priceRequests,
});

export const handleReceivePriceRequests =
  (key = null, value = null) =>
  (dispatch) => {
    dispatch(showLoading());
    return getPriceRequests(key, value)
      .then((res) => res.json())
      .then((res) => {
        dispatch(receivePriceRequests(res.data));
        dispatch(hideLoading());
        return res;
      })
      .catch((e) => {
        dispatch(showMessageAlert(e.message, "error"));
        dispatch(hideLoading());
      });
  };

export const handlePaginatePriceRequests =
  (page, key = null, value = null) =>
  (dispatch) => {
    dispatch(showLoading());
    return getPriceRequestsPage(page, key, value)
      .then((res) => res.json())
      .then((res) => {
        dispatch(paginatePriceRequests(res.data));
        dispatch(hideLoading());
        return res;
      })
      .catch((e) => {
        dispatch(showMessageAlert(e.message, "error"));
        dispatch(hideLoading());
      });
  };

export const handleDeletePriceRequest = (id) => (dispatch) => {
  dispatch(showLoading());
  destroyPriceRequest(id)
    .then((res) => res.json())
    .then((res) => {
      dispatch(deletePriceRequest(id));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم حذف الطلب بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleReceivePriceRequestDetails = (id) => (dispatch) => {
  dispatch(showLoading());
  getPriceRequestDetails(id)
    .then((res) => res.json())
    .then((res) => {
      dispatch(receivePriceRequestDetails(res.data));
      dispatch(hideLoading());
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};
