import React, { Component } from "react";
import WorkDetailsForm from "../last-works/WorkDetailsForm";
import ImageUpload from "../last-works/ImageUpload";
import ProjectPictures from "../last-works/ProjectPictures";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { handleEditProject } from "../../actions/projects";
import { uploadMedia } from "../../utils/api";
import { showMessageAlert } from "../../actions/messageAlert";
import { handleReceiveProject } from "../../actions/projects";
import { destroyProjectSlider } from "../../utils/api";

class EditWork extends Component {
  state = {
    logo: [],
    name_ar: "",
    description_ar: "",
    name_en: "",
    description_en: "",
    url: "",
    submitDisabled: true,
    pictures: [[], []],
    imageLoaders: [false, false, false],
    imagePaths: {
      logo: "",
      first: "",
      second: "",
    },
  };

  componentDidMount() {
    const populateState = () => {
      const { targetProject } = this.props;
      this.setState({
        name_ar: targetProject.name.ar,
        name_en: targetProject.name.en,
        description_ar: targetProject.desc.ar,
        description_en: targetProject.desc.en,
        url: targetProject.website,
        imagePaths: {
          logo: targetProject.image,
          first: targetProject.sliders[0] ? targetProject.sliders[0].image : null,
          second: targetProject.sliders[1] ? targetProject.sliders[1].image : null,
        },
      });
    };
    const { targetProject, projectId, getProject } = this.props;
    if (!targetProject) {
      getProject(projectId).then(populateState);
    } else {
      populateState();
    }
  }

  handleInputChange = (e, name) => {
    this.setState({ [name]: e.target.value }, this.handleSubmitDisabled);
  };
  handleSubmitDisabled = () => {
    const { targetProject } = this.props;
    const { name_ar, description_ar, name_en, description_en, url, imagePaths, submitDisabled } = this.state;
    if (
      !name_ar ||
      !description_ar ||
      !name_en ||
      !description_en ||
      !url ||
      // don't submit if there was no data changed
      (targetProject.name.ar === name_ar.trim() &&
        targetProject.name.en === name_en.trim() &&
        targetProject.desc.ar === description_ar.trim() &&
        targetProject.desc.en === description_en.trim() &&
        targetProject.website === url.trim() &&
        targetProject.image === imagePaths.logo &&
        targetProject.sliders[0].image === imagePaths.first &&
        targetProject.sliders[1] &&
        targetProject.sliders[1].image === imagePaths.second)
      // actually the code above has problem that if there was only one slider
      // the submit will be always available
    ) {
      this.setState({ submitDisabled: true });
    } else if (submitDisabled) {
      this.setState({ submitDisabled: false });
    }
  };
  handleChangeLogo = (logo) => {
    this.setState(
      (prevState) => {
        let imageLoaders = prevState.imageLoaders;
        imageLoaders[2] = true;
        return {
          logo,
          imageLoaders,
        };
      },
      () => {
        const { showUploadError } = this.props;
        const { logo } = this.state;
        const logoForm = new FormData();
        logoForm.append("file", logo[0]);
        logoForm.append("mime_type", "image");

        uploadMedia(logoForm)
          .then((res) => res.json())
          .then((res) => {
            this.setState((prevState) => {
              let imageLoaders = prevState.imageLoaders;
              imageLoaders[2] = false;
              return {
                imageLoaders,
                imagePaths: { ...prevState.imagePaths, logo: res.path },
              };
            }, this.handleSubmitDisabled);
          })
          .catch(() => {
            showUploadError("حدث خطأ");
          });
      }
    );
  };
  handleChangeFirstPicture = (picture) => {
    this.setState(
      (prevState) => {
        let pics = prevState.pictures;
        pics[0] = picture;

        let imageLoaders = prevState.imageLoaders;
        imageLoaders[0] = true;

        return { pictures: pics, imageLoaders };
      },
      () => {
        const { pictures } = this.state;
        const { showUploadError } = this.props;
        const firstSliderForm = new FormData();
        firstSliderForm.append("file", pictures[0][0]);
        firstSliderForm.append("mime_type", "image");

        uploadMedia(firstSliderForm)
          .then((res) => res.json())
          .then((res) => {
            this.setState((prevState) => {
              let imageLoaders = prevState.imageLoaders;
              imageLoaders[0] = false;
              return {
                imageLoaders,
                imagePaths: { ...prevState.imagePaths, first: res.path },
              };
            }, this.handleSubmitDisabled);
          })
          .catch(() => {
            showUploadError("حدث خطأ");
          });
      }
    );
  };

  handleChangeSecondPicture = (picture) => {
    this.setState(
      (prevState) => {
        let pics = prevState.pictures;
        pics[1] = picture;

        let imageLoaders = prevState.imageLoaders;
        imageLoaders[1] = true;

        return { pictures: pics, imageLoaders };
      },
      () => {
        const { pictures } = this.state;
        const { showUploadError } = this.props;
        const secondSliderForm = new FormData();
        secondSliderForm.append("file", pictures[1][0]);
        secondSliderForm.append("mime_type", "image");

        uploadMedia(secondSliderForm)
          .then((res) => res.json())
          .then((res) => {
            this.setState((prevState) => {
              let imageLoaders = prevState.imageLoaders;
              imageLoaders[1] = false;
              return {
                imageLoaders,
                imagePaths: { ...prevState.imagePaths, second: res.path },
              };
            }, this.handleSubmitDisabled);
          })
          .catch(() => {
            showUploadError("حدث خطأ");
          });
      }
    );
  };
  validURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };
  handleSubmit = () => {
    const { updateProject, projectId, targetProject, history, showUploadError } = this.props;
    const { name_ar, description_ar, name_en, description_en, url, imagePaths } = this.state;
    const { slug } = targetProject;
    var data = {
      name: {
        ar: name_ar,
        en: name_en,
      },
      desc: {
        ar: description_ar,
        en: description_en,
      },
      slug,
      image: imagePaths.logo,
      website: url,
    };

    // destroy sliders if they were replaced and append the new sliders to data
    if (targetProject.sliders[0].image !== imagePaths.first) {
      destroyProjectSlider(targetProject.sliders[0].id);
      data.sliders = [];
      data.sliders.push(imagePaths.first);
    }
    if (targetProject.sliders[1] && targetProject.sliders[1].image !== imagePaths.second) {
      destroyProjectSlider(targetProject.sliders[1].id);
      if (!data.sliders) {
        data.sliders = [];
      }
      data.sliders.push(imagePaths.second);
      // if there was no second slider but the user have upload a new one
    } else if (!targetProject.sliders[1] && imagePaths.second) {
      if (!data.sliders) {
        data.sliders = [];
      }
      data.sliders.push(imagePaths.second);
    }
    var submit = true;
    if (!this.validURL(url)) {
      submit = false;
      showUploadError("يجب إدخال رابط صحيح");
    }
    if (submit) {
      updateProject(projectId, data)
        .then((res) => {
          history.push("/projects");
        })
        .catch((e) => {
          console.log("error", e);
        });
    }
  };

  render() {
    const {
      name_ar,
      description_ar,
      name_en,
      description_en,
      url,
      submitDisabled,
      logo,
      pictures,
      imagePaths,
      imageLoaders,
    } = this.state;

    return (
      <div className="pb-5 container overflow-hidden">
        <div className="row px-3 pt-3 main-header">
          <div className="col-12">
            <h2 className="page-title">تعديل الصفحة الرئيسية</h2>
          </div>
        </div>
        <div className="row p-3">
          <div className="col-12">
            <h3 className="page-subtitle">تعديل مشروع</h3>
          </div>
        </div>
        <div className="row p-3 justify-content-center align-items-center">
          <div className="col-12 mb-4 text-center">
            <h5 className="text-theme-primary mb-0 page-subtitle-lead">شعار المشروع</h5>
          </div>
          <div className="col-12 col-md-6 image-upload-container">
            <ImageUpload
              image={imagePaths.logo}
              files={logo}
              setFiles={this.handleChangeLogo}
              loading={imageLoaders[2]}
            />
          </div>
        </div>
        <hr />
        <ProjectPictures
          imagePaths={Object.values(imagePaths).slice(1)}
          files={pictures}
          handleChangeFirstPicture={this.handleChangeFirstPicture}
          handleChangeSecondPicture={this.handleChangeSecondPicture}
          imageLoaders={imageLoaders}
        />
        <hr />
        <WorkDetailsForm
          name_ar={name_ar}
          description_ar={description_ar}
          name_en={name_en}
          description_en={description_en}
          url={url}
          handleInputChange={this.handleInputChange}
        />
        <div className="row p-3">
          <div className="col-12 text-center">
            <Button
              color="primary"
              variant="contained"
              size="large"
              className="mw-50"
              onClick={this.handleSubmit}
              disabled={submitDisabled}
            >
              حفظ
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ projects }, props) => {
  const projectId = parseInt(props.match.params.id);
  const targetProject = projects.find((item) => item.id === projectId);
  return { targetProject, projectId };
};

const mapDispatchToProps = (dispatch) => ({
  updateProject: (id, data) => dispatch(handleEditProject(id, data)),
  getProject: (projectId) => dispatch(handleReceiveProject(projectId)),
  showUploadError: (message) => dispatch(showMessageAlert(message, "error")),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditWork);
