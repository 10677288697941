import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";

const WorkDetailsForm = ({ name_ar, description_ar, name_en, description_en, url, handleInputChange }) => (
  <div className="row p-3">
    <div className="col-12 mb-3">
      <h5 className="text-theme-primary page-subtitle-lead">تفاصيل المشروع</h5>
    </div>
    <div className="col-12 col-md-6">
      <div className="form-group">
        <TextField
          type="text"
          placeholder="اسم المشروع بالعربية"
          className="form-control"
          required
          value={name_ar}
          onChange={(e) => handleInputChange(e, "name_ar")}
          variant="outlined"
          size="small"
        />
      </div>
    </div>
    <div className="col-12 col-md-6">
      <div className="form-group">
        <TextField
          type="text"
          placeholder="اسم المشروع بالانجليزية"
          className="form-control"
          required
          value={name_en}
          onChange={(e) => handleInputChange(e, "name_en")}
          variant="outlined"
          size="small"
        />
      </div>
    </div>
    <div className="col-12">
      <div className="form-group">
        <textarea
          rows={6}
          className="form-control"
          placeholder="تفاصيل المشروع بالعربية"
          required
          value={description_ar}
          onChange={(e) => handleInputChange(e, "description_ar")}
        ></textarea>
      </div>
    </div>
    <div className="col-12">
      <div className="form-group">
        <textarea
          rows={6}
          className="form-control"
          placeholder="تفاصيل المشروع بالانجليزية"
          required
          value={description_en}
          onChange={(e) => handleInputChange(e, "description_en")}
        ></textarea>
      </div>
    </div>
    <div className="col-12 col-md-6">
      <div className="form-group">
        <TextField
          type="url"
          placeholder="رايط المشروع"
          className="form-control"
          required
          value={url}
          onChange={(e) => handleInputChange(e, "url")}
          variant="outlined"
          size="small"
        />
      </div>
    </div>
  </div>
);

WorkDetailsForm.propTypes = {
  name_ar: PropTypes.string.isRequired,
  description_ar: PropTypes.string.isRequired,
  name_en: PropTypes.string.isRequired,
  description_en: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
};

export default WorkDetailsForm;
