import { showLoading, hideLoading } from "react-redux-loading-bar";
import { getProjects, showProject, storeProject, updateProject, destroyProject, getProjectsPage } from "../utils/api";
import { showMessageAlert } from "./messageAlert";

export const RECEIVE_PROJECTS = "RECEIVE_PROJECTS";
export const RECEIVE_PROJECT = "RECEIVE_PROJECT";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const EDIT_PROJECT = "EDIT_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const PAGINATE_PROJECTS = "PAGINATE_PROJECTS";

const receiveProjects = (projects) => ({
  type: RECEIVE_PROJECTS,
  projects,
});

const receiveProject = (project) => ({
  type: RECEIVE_PROJECT,
  project,
});

const createProject = (project) => ({
  type: CREATE_PROJECT,
  project,
});

const editProject = (id, data) => ({
  type: EDIT_PROJECT,
  id,
  data,
});

const deleteProject = (id) => ({
  type: DELETE_PROJECT,
  id,
});

const paginateProjects = (projects) => ({
  type: PAGINATE_PROJECTS,
  projects,
});

export const handleReceiveProjects = () => (dispatch) => {
  dispatch(showLoading());
  return getProjects()
    .then((res) => res.json())
    .then((res) => {
      dispatch(receiveProjects(res.data));
      dispatch(hideLoading());
      return res;
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleReceiveProject = (id) => (dispatch) => {
  dispatch(showLoading);
  return showProject(id)
    .then((res) => res.json())
    .then((res) => {
      dispatch(receiveProject(res.data));
      dispatch(hideLoading());
      return res;
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handlePaginateProjects = (page) => (dispatch) => {
  dispatch(showLoading());
  return getProjectsPage(page)
    .then((res) => res.json())
    .then((res) => {
      dispatch(paginateProjects(res.data));
      dispatch(hideLoading());
      return res;
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleCreateProject = (data) => (dispatch) => {
  dispatch(showLoading());
  return storeProject(data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(createProject(res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم إضافة المشروع بنجاح", "success"));
      return res;
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
      e.res.json().then((data) => {
        if (data.errors) {
          if (data.errors["slug.ar"] || data.errors["slug.en"])
            dispatch(showMessageAlert("تم استخدام اسم المشروع من قبل.", "error"));
        }
      });
      return Promise.reject(e);
    });
};

export const handleEditProject = (id, data) => (dispatch) => {
  dispatch(showLoading());
  return updateProject(id, data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(editProject(id, res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم تعديل المشروع بنجاح", "success"));
      return res;
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
      if (e.res) {
        e.res.json().then((data) => {
          if (data.errors) {
            if (data.errors["slug.ar"] || data.errors["slug.en"])
              dispatch(showMessageAlert("تم استخدام اسم المشروع من قبل.", "error"));
          }
        });
      }
      return Promise.reject(e);
    });
};

export const handleDeleteProject = (id) => (dispatch) => {
  dispatch(showLoading());
  destroyProject(id)
    .then((res) => res.json())
    .then((res) => {
      dispatch(deleteProject(id));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم حذف المشروع بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};
