import React, { Component } from "react";
import { Paper, Button, TextField, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { handleUserAuthentication } from "../../actions/user";
import { isAuthenticated } from "../../utils/helpers";
import { Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";

class Login extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <p className="text-danger pt-2 m-0">{message}</p>,
    });

    this.state = {
      email: "",
      password: "",
      submitDisabled: true,
      btnLoading: false,
    };
  }

  handleButtonDisabled = () => {
    const { email, password } = this.state;
    if (!email || !password) {
      this.setState({ submitDisabled: true });
    } else {
      this.setState({ submitDisabled: false });
    }
  };
  handleChangeEmail = (e) => {
    const email = e.target.value;
    this.setState({ email }, this.handleButtonDisabled);
  };
  handleChangePassword = (e) => {
    const password = e.target.value;
    this.setState({ password }, this.handleButtonDisabled);
  };
  handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const { email, password } = this.state;
      const { handleUserLogin, history } = this.props;
      this.setState({ btnLoading: true });
      handleUserLogin(email, password).then((isAuthenticated) => {
        this.setState({ btnLoading: false });
        if (isAuthenticated) history.push("/home");
      });
    } else this.validator.showMessages();
  };

  render() {
    if (isAuthenticated()) return <Redirect to="/home" />;

    const { email, password, submitDisabled, btnLoading } = this.state;

    return (
      <div className="login-container">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-8 col-lg-6 mt-5">
              <Paper className="px-4 py-5">
                <div className="text-center mb-3">
                  <img src="/images/logo.png" alt="wide tech logo" />
                </div>
                <form onSubmit={this.handleFormSubmit}>
                  <div className="form-group">
                    <TextField
                      type="email"
                      placeholder="البريد الالكتروني"
                      className="form-control"
                      required
                      value={email}
                      onChange={this.handleChangeEmail}
                      variant="outlined"
                      size="small"
                    />
                    {this.validator.message("email", email, "required|email")}
                  </div>
                  <div className="form-group">
                    <TextField
                      type="password"
                      placeholder="كلمة المرور"
                      className="form-control"
                      required
                      value={password}
                      onChange={this.handleChangePassword}
                      variant="outlined"
                      size="small"
                    />
                    {this.validator.message("password", password, "required")}
                  </div>
                  <div className="text-center">
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      className="w-100"
                      type="submit"
                      disabled={submitDisabled}
                    >
                      تسجيل الدخول {btnLoading && <CircularProgress className="text-white mr-2" size={20} />}
                    </Button>
                  </div>
                </form>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  handleUserLogin: (email, password) => dispatch(handleUserAuthentication(email, password)),
});

export default connect(null, mapDispatchToProps)(Login);
