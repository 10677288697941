import React, { PureComponent } from "react";
import { Paper, IconButton } from "@material-ui/core";
import { DeleteOutline, EditOutlined } from "@material-ui/icons";
import EditNewsModal from "../modals/EditNewsModal";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
import { connect } from "react-redux";
import { handleReceiveNewsDetails, handleDeleteNews } from "../../actions/news";

class NewsDetails extends PureComponent {
  state = {
    openDeleteDialog: false,
    openEditDialog: false,
  };

  handleOpenDeleteDialog = () => {
    this.setState({ openDeleteDialog: true });
  };
  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDialog: false });
  };
  handleOpenEditDialog = () => {
    this.setState({ openEditDialog: true });
  };
  handleCloseEditDialog = () => {
    this.setState({ openEditDialog: false, editId: null });
  };
  handleConfirmDelete = () => {
    const { deleteNews, newsId, history } = this.props;
    deleteNews(newsId);
    history.push("/news");
  };

  componentDidMount() {
    const { targetNews, newsId, getNewsDetails } = this.props;
    if (!targetNews) getNewsDetails(newsId);
  }

  render() {
    const { openDeleteDialog, openEditDialog } = this.state;
    const {
      image,
      title,
      content,
      newsId,
      user: { permissions, is_super_admin },
    } = this.props;

    return (
      <div className="pb-5 container overflow-hidden">
        <div className="row px-3 pt-3 main-header">
          <div className="col-12">
            <h2 className="page-title">تعديل الصفحة الرئيسية</h2>
          </div>
        </div>
        <div className="row p-3">
          <div className="col-12">
            <h3 className="page-subtitle">تفاصيل الخبر</h3>
          </div>
        </div>
        <div className="row p-3">
          <Paper className="px-0">
            <img
              src={image || "/images/img-placeholder.jpg"}
              alt="news details placeholder"
              className="img-fluid news__image"
            />
          </Paper>
        </div>
        <div className="row p-3">
          <Paper className="col-12 p-3">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="m-0 card__title">{title || "عنوان الخبر"}</h4>
              <div className="d-flex align-items-center">
                {is_super_admin || permissions.includes(34) ? (
                  <IconButton aria-label="edit" onClick={this.handleOpenEditDialog}>
                    <EditOutlined />
                  </IconButton>
                ) : (
                  ""
                )}
                {is_super_admin || permissions.includes(16) ? (
                  <IconButton aria-label="delete" onClick={this.handleOpenDeleteDialog}>
                    <DeleteOutline color="error" />
                  </IconButton>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="mt-3">
              <p className="card__subtitle">
                {content ||
                  `لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل
                ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ...
                بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت`}
              </p>
            </div>
          </Paper>
        </div>
        <EditNewsModal open={openEditDialog} handleClose={this.handleCloseEditDialog} editId={newsId} />
        <ConfirmDeleteModal
          open={openDeleteDialog}
          handleClose={this.handleCloseDeleteDialog}
          text="هل تريد حذف الخبر؟"
          handleDelete={this.handleConfirmDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ news, user }, props) => {
  const newsId = parseInt(props.match.params.id);
  const targetNews = news.find((item) => item.id === newsId);
  const image = targetNews ? targetNews.image : "";
  const title = targetNews ? targetNews.title.ar : "";
  const content = targetNews ? targetNews.content.ar : "";
  return { targetNews, image, title, content, newsId, user };
};

const mapDispatchToProps = (dispatch) => ({
  getNewsDetails: (id) => dispatch(handleReceiveNewsDetails(id)),
  deleteNews: (id) => dispatch(handleDeleteNews(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetails);
