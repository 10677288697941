import React from "react";
import { TableRow, TableCell, IconButton, Link } from "@material-ui/core";
import { DeleteOutline, EditOutlined } from "@material-ui/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const QrCodesListItem = ({
  handleDeleteItem,
  handleEditItem,
  qrCode: { id, name, content, path },
  user: { permissions, is_super_admin },
}) => (
  <TableRow>
    <TableCell align="center">{id}</TableCell>
    <TableCell align="center">{name}</TableCell>
    <TableCell align="center">{content}</TableCell>
    <TableCell align="center">
      <Link href={path} target={"_blank"} rel={"noopener"}>
        عرض
      </Link>
    </TableCell>
    <TableCell align="center">
      {is_super_admin && (
        <IconButton aria-label="edit" onClick={handleEditItem}>
          <EditOutlined />
        </IconButton>
      )}
      <IconButton aria-label="delete" onClick={handleDeleteItem}>
        <DeleteOutline color="error" />
      </IconButton>
    </TableCell>
  </TableRow>
);

QrCodesListItem.propTypes = {
  handleDeleteItem: PropTypes.func.isRequired,
  handleEditItem: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  qrCode: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(QrCodesListItem);
