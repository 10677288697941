import { createMuiTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#4a90e4",
    },
    secondary: {
      main: "#3c448e",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fbfbfb",
    },
  },
  typography: {
    fontFamily: "'Tajawal', sans-serif",
  },
  direction: "rtl",
});

export default theme;
