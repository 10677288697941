import { httpMethods } from "./enums";
import Cookies from "js-cookie";
import fetchIntercept from "fetch-intercept";
import history from "./history";

const API_URL = process.env.REACT_APP_API_URL;
const headers = {
  Accept: "application/json",
  "Accept-Language": "ar",
  "Content-Type": "application/json",
};

fetchIntercept.register({
  response: function (res) {
    if (res.ok === true) return res;
    else {
      const { status, statusText } = res;
      let message = res.error || statusText;
      if (status === 422) message = "ادخل بيانات صحيحة";
      else if (status === 404) message = "Invalid id was given";
      else if (status === 401) {
        // may be token has expired!
        message = "يجب عليك تسجيل الدخول";
        Cookies.remove("SID");
        // login page
        history.push("/");
      } else if (status === 403) {
        message = " غير مصرح بعرض هذا المحتوى لهذا المستخدم";
      }
      return Promise.reject({ status, message, res });
    }
  },

  responseError: function (error) {
    return Promise.reject({ message: "حدث خطأ اثناء الإتصال" });
  },
});

export const loginAdmin = (email, password) =>
  fetch(`${API_URL}/admin-login`, {
    method: httpMethods.POST,
    headers,
    body: JSON.stringify({ email, password }),
  });

export const logoutAdmin = () =>
  fetch(`${API_URL}/admin/logout`, {
    method: httpMethods.POST,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getSliders = () =>
  fetch(`${API_URL}/admin/sliders`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const storeSlider = (slider) =>
  fetch(`${API_URL}/admin/sliders`, {
    method: httpMethods.POST,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(slider),
  });

export const updateSlider = (id, data) =>
  fetch(`${API_URL}/admin/sliders/${id}`, {
    method: httpMethods.PUT,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(data),
  });

export const destroySlider = (id) =>
  fetch(`${API_URL}/admin/sliders/${id}`, {
    method: httpMethods.DELETE,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getTestimonials = () =>
  fetch(`${API_URL}/admin/opinions`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const storeTestimonial = (testimonial) =>
  fetch(`${API_URL}/admin/opinions`, {
    method: httpMethods.POST,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(testimonial),
  });

export const updateTestimonial = (id, data) =>
  fetch(`${API_URL}/admin/opinions/${id}`, {
    method: httpMethods.PUT,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(data),
  });

export const destroyTestimonial = (id) =>
  fetch(`${API_URL}/admin/opinions/${id}`, {
    method: httpMethods.DELETE,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getNews = () =>
  fetch(`${API_URL}/admin/news`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getNewsDetails = (id) =>
  fetch(`${API_URL}/admin/news/${id}`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const storeNews = (news) =>
  fetch(`${API_URL}/admin/news`, {
    method: httpMethods.POST,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(news),
  });

export const updateNews = (id, data) =>
  fetch(`${API_URL}/admin/news/${id}`, {
    method: httpMethods.PUT,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(data),
  });

export const destroyNews = (id) =>
  fetch(`${API_URL}/admin/news/${id}`, {
    method: httpMethods.DELETE,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getArticles = (page = 1) =>
  fetch(`${API_URL}/admin/articles?page=${page}`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const storeArticle = (article) =>
  fetch(`${API_URL}/admin/articles`, {
    method: httpMethods.POST,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(article),
  });

export const updateArticle = (id, data) =>
  fetch(`${API_URL}/admin/articles/${id}`, {
    method: httpMethods.PUT,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(data),
  });

export const destroyArticle = (id) =>
  fetch(`${API_URL}/admin/articles/${id}`, {
    method: httpMethods.DELETE,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getJobs = () =>
  fetch(`${API_URL}/admin/jobs`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getJobDetails = (id) =>
  fetch(`${API_URL}/admin/jobs/${id}`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const storeJob = (job) =>
  fetch(`${API_URL}/admin/jobs`, {
    method: httpMethods.POST,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(job),
  });

export const updateJob = (id, job) =>
  fetch(`${API_URL}/admin/jobs/${id}`, {
    method: httpMethods.PUT,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(job),
  });

export const destroyJob = (id) =>
  fetch(`${API_URL}/admin/jobs/${id}`, {
    method: httpMethods.DELETE,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getProjects = () =>
  fetch(`${API_URL}/admin/projects`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const showProject = (id) => {
  return fetch(`${API_URL}/admin/projects/${id}`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });
};

export const storeProject = (project) =>
  fetch(`${API_URL}/admin/projects`, {
    method: httpMethods.POST,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(project),
  });

export const updateProject = (id, data) =>
  fetch(`${API_URL}/admin/projects/${id}`, {
    method: httpMethods.PUT,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(data),
  });

export const destroyProject = (id) =>
  fetch(`${API_URL}/admin/projects/${id}`, {
    method: httpMethods.DELETE,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const destroyProjectSlider = (id) => {
  return fetch(`${API_URL}/admin/project-sliders/${id}`, {
    method: httpMethods.DELETE,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });
};

export const getJobApplications = (key = null, value = null) => {
  const endpoint = key && value ? `/admin/job-applications?${key}=${value}` : "/admin/job-applications";
  return fetch(API_URL + endpoint, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });
};

export const getJobApplicationDetails = (id) =>
  fetch(`${API_URL}/admin/job-applications/${id}`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const destroyJobApplication = (id) =>
  fetch(`${API_URL}/admin/job-applications/${id}`, {
    method: httpMethods.DELETE,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const uploadMedia = (formData) =>
  fetch(`${API_URL}/admin/upload`, {
    method: httpMethods.POST,
    headers: {
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: formData,
  });

export const getCountries = () =>
  fetch(`${API_URL}/admin/countries`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const storeCountry = (country) =>
  fetch(`${API_URL}/admin/countries`, {
    method: httpMethods.POST,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(country),
  });

export const updateCountry = (id, country) =>
  fetch(`${API_URL}/admin/countries/${id}`, {
    method: httpMethods.PUT,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(country),
  });

export const destroyCountry = (id) =>
  fetch(`${API_URL}/admin/countries/${id}`, {
    method: httpMethods.DELETE,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getJobApplicationNotes = (jobId) =>
  fetch(`${API_URL}/admin/job-application-notes?job_application_id=${jobId}`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const storeJobApplicationNote = (data) =>
  fetch(`${API_URL}/admin/job-application-notes`, {
    method: httpMethods.POST,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(data),
  });

export const updateJobApplicationNote = (noteId, note) =>
  fetch(`${API_URL}/admin/job-application-notes/${noteId}`, {
    method: httpMethods.PUT,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(note),
  });

export const destroyJobApplicationNote = (noteId) =>
  fetch(`${API_URL}/admin/job-application-notes/${noteId}`, {
    method: httpMethods.DELETE,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getPriceRequestNotes = (priceRequestId) =>
  fetch(`${API_URL}/admin/pricing-request-notes?pricing_request_id=${priceRequestId}`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const storePriceRequestNote = (data) =>
  fetch(`${API_URL}/admin/pricing-request-notes`, {
    method: httpMethods.POST,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(data),
  });

export const updatePriceRequestNote = (noteId, note) =>
  fetch(`${API_URL}/admin/pricing-request-notes/${noteId}`, {
    method: httpMethods.PUT,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(note),
  });

export const destroyPriceRequestNote = (noteId) =>
  fetch(`${API_URL}/admin/pricing-request-notes/${noteId}`, {
    method: httpMethods.DELETE,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getPriceRequests = (key = null, value = null) => {
  const endpoint = key && value ? `/admin/pricing-requests?${key}=${value}` : "/admin/pricing-requests";
  return fetch(API_URL + endpoint, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });
};

export const getPriceRequestDetails = (id) =>
  fetch(`${API_URL}/admin/pricing-requests/${id}`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const destroyPriceRequest = (id) =>
  fetch(`${API_URL}/admin/pricing-requests/${id}`, {
    method: httpMethods.DELETE,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getPartners = () =>
  fetch(`${API_URL}/admin/partners`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const storePartner = (partner) =>
  fetch(`${API_URL}/admin/partners`, {
    method: httpMethods.POST,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(partner),
  });

export const updatePartner = (id, data) =>
  fetch(`${API_URL}/admin/partners/${id}`, {
    method: httpMethods.PUT,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(data),
  });

export const destroyPartner = (id) =>
  fetch(`${API_URL}/admin/partners/${id}`, {
    method: httpMethods.DELETE,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getContacts = () =>
  fetch(`${API_URL}/admin/settings`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const storeContact = (contact) =>
  fetch(`${API_URL}/admin/settings`, {
    method: httpMethods.POST,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(contact),
  });

export const updateContact = (id, data) =>
  fetch(`${API_URL}/admin/settings/${id}`, {
    method: httpMethods.PUT,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(data),
  });

export const destroyContact = (id) =>
  fetch(`${API_URL}/admin/settings/${id}`, {
    method: httpMethods.DELETE,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getStatistics = () =>
  fetch(`${API_URL}/admin/statistics`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getNewsPage = (page) =>
  fetch(`${API_URL}/admin/news?page=${page}`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getProjectsPage = (page) =>
  fetch(`${API_URL}/admin/projects?page=${page}`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getJobsPage = (page) =>
  fetch(`${API_URL}/admin/jobs?page=${page}`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getJobApplicationsPage = (page, key = null, value = null) => {
  const endpoint =
    key && value ? `/admin/job-applications?${key}=${value}&page=${page}` : `/admin/job-applications?page=${page}`;
  return fetch(API_URL + endpoint, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });
};

export const getPartnersPage = (page) =>
  fetch(`${API_URL}/admin/partners?page=${page}`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getPriceRequestsPage = (page, key = null, value = null) => {
  const endpoint =
    key && value ? `/admin/pricing-requests?${key}=${value}&page=${page}` : `/admin/pricing-requests?page=${page}`;
  return fetch(`${API_URL + endpoint}`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });
};

export const getPermissions = () =>
  fetch(`${API_URL}/admin/permissions`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getRoles = () =>
  fetch(`${API_URL}/admin/roles`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getRolesPage = (page) =>
  fetch(`${API_URL}/admin/roles?page=${page}`, {
    method: httpMethods.GET,
    headers: {
      ...headers,

      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const showRole = (roleId) =>
  fetch(`${API_URL}/admin/roles/${roleId}`, {
    method: httpMethods.GET,
    headers: {
      ...headers,

      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const storeRole = (role) =>
  fetch(`${API_URL}/admin/roles`, {
    method: httpMethods.POST,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(role),
  });

export const updateRole = (id, data) =>
  fetch(`${API_URL}/admin/roles/${id}`, {
    method: httpMethods.PUT,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(data),
  });

export const destroyRole = (id) =>
  fetch(`${API_URL}/admin/roles/${id}`, {
    method: httpMethods.DELETE,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getAdmins = () =>
  fetch(`${API_URL}/admin/admins`, {
    method: httpMethods.GET,
    headers: {
      ...headers,

      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getAdminsPage = (page) =>
  fetch(`${API_URL}/admin/admins?page=${page}`, {
    method: httpMethods.GET,
    headers: {
      ...headers,

      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });
export const storeAdmin = (admin) =>
  fetch(`${API_URL}/admin/admins`, {
    method: httpMethods.POST,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(admin),
  });

export const updateAdmin = (id, data) =>
  fetch(`${API_URL}/admin/admins/${id}`, {
    method: httpMethods.PUT,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(data),
  });

export const destroyAdmin = (id) =>
  fetch(`${API_URL}/admin/admins/${id}`, {
    method: httpMethods.DELETE,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getMessages = () =>
  fetch(`${API_URL}/admin/contact-messages`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getMessageDetails = (id) =>
  fetch(`${API_URL}/admin/contact-messages/${id}`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const destroyMessage = (id) =>
  fetch(`${API_URL}/admin/contact-messages/${id}`, {
    method: httpMethods.DELETE,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const getServices = () =>
  fetch(`${API_URL}/admin/services`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const storeService = (service) =>
  fetch(`${API_URL}/admin/services`, {
    method: httpMethods.POST,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(service),
  });

export const updateService = (id, data) =>
  fetch(`${API_URL}/admin/services/${id}`, {
    method: httpMethods.PUT,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(data),
  });

export const destroyService = (id) =>
  fetch(`${API_URL}/admin/services/${id}`, {
    method: httpMethods.DELETE,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

// --------------- qr code -----------

export const getQrCodes = (page) =>
  fetch(`${API_URL}/admin/qr-codes?page=${page}`, {
    method: httpMethods.GET,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });

export const storeQrCode = (service) =>
  fetch(`${API_URL}/admin/qr-codes`, {
    method: httpMethods.POST,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(service),
  });

export const updateQrCode = (id, data) =>
  fetch(`${API_URL}/admin/qr-codes/${id}`, {
    method: httpMethods.PUT,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
    body: JSON.stringify(data),
  });

export const destroyQrCode = (id) =>
  fetch(`${API_URL}/admin/qr-codes/${id}`, {
    method: httpMethods.DELETE,
    headers: {
      ...headers,
      Authorization: `Bearer ${Cookies.get("SID")}`,
    },
  });
