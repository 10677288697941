import { showLoading, hideLoading } from "react-redux-loading-bar";
import { getAdmins, getAdminsPage, storeAdmin, updateAdmin, destroyAdmin } from "../utils/api";
import { showMessageAlert } from "./messageAlert";

export const RECEIVE_ADMINS = "RECEIVE_ADMINS";
export const CREATE_ADMIN = "CREATE_ADMIN";
export const EDIT_ADMIN = "EDIT_ADMIN";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const PAGINATE_ADMIN = "PAGINATE_ADMIN";
const receiveAdmins = (admins) => ({
  type: RECEIVE_ADMINS,
  admins,
});

const createAdmin = (admin) => ({
  type: CREATE_ADMIN,
  admin,
});

const editAdmin = (id, data) => ({
  type: EDIT_ADMIN,
  id,
  data,
});

const deleteAdmin = (id) => ({
  type: DELETE_ADMIN,
  id,
});
const paginateAdmin = (admins) => ({
  type: PAGINATE_ADMIN,
  admins,
});
export const handleReceiveAdmins = () => (dispatch) => {
  dispatch(showLoading());
  return getAdmins()
    .then((res) => res.json())
    .then((res) => {
      dispatch(receiveAdmins(res.data));
      dispatch(hideLoading());
      return res;
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};
export const handlePaginateAdmin = (page) => (dispatch) => {
  dispatch(showLoading());
  return getAdminsPage(page)
    .then((res) => res.json())
    .then((res) => {
      dispatch(paginateAdmin(res.data));
      dispatch(hideLoading());
      return res;
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleCreateAdmin = (data) => (dispatch) => {
  dispatch(showLoading());
  storeAdmin(data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(createAdmin(res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم إضافة الادمن بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleEditAdmin = (id, data, same_user) => (dispatch) => {
  dispatch(showLoading());
  updateAdmin(id, data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(editAdmin(id, res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم تعديل الادمن بنجاح", "success"));
      console.log(same_user);
      if (same_user) {
        dispatch(showMessageAlert("يجب إعادة تسجيل الدخول لتفعيل الوظائف الجديدة", "success"));
      }
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleDeleteAdmin = (id) => (dispatch) => {
  dispatch(showLoading());
  destroyAdmin(id)
    .then((res) => res.json())
    .then((res) => {
      dispatch(deleteAdmin(id));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم حذف الادمن بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};
