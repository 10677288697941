import { showLoading, hideLoading } from "react-redux-loading-bar";
import { getPartners, storePartner, updatePartner, destroyPartner, getPartnersPage } from "../utils/api";
import { showMessageAlert } from "./messageAlert";

export const RECEIVE_PARTNERS = "RECEIVE_PARTNERS";
export const CREATE_PARTNER = "CREATE_PARTNER";
export const EDIT_PARTNER = "EDIT_PARTNER";
export const DELETE_PARTNER = "DELETE_PARTNER";
export const PAGINATE_PARTNERS = "PAGINATE_PARTNERS";

const receivePartners = (partners) => ({
  type: RECEIVE_PARTNERS,
  partners,
});

const createPartner = (partner) => ({
  type: CREATE_PARTNER,
  partner,
});

const editPartner = (id, data) => ({
  type: EDIT_PARTNER,
  id,
  data,
});

const deletePartner = (id) => ({
  type: DELETE_PARTNER,
  id,
});

const paginatePartners = (partners) => ({
  type: PAGINATE_PARTNERS,
  partners,
});

export const handleReceivePartners = () => (dispatch) => {
  dispatch(showLoading());
  return getPartners()
    .then((res) => res.json())
    .then((res) => {
      dispatch(receivePartners(res.data));
      dispatch(hideLoading());
      return res;
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handlePaginatePartners = (page) => (dispatch) => {
  dispatch(showLoading());
  return getPartnersPage(page)
    .then((res) => res.json())
    .then((res) => {
      dispatch(paginatePartners(res.data));
      dispatch(hideLoading());
      return res;
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleCreatePartner = (data) => (dispatch) => {
  dispatch(showLoading());
  storePartner(data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(createPartner(res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم إضافة الشريك بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleEditPartner = (id, data) => (dispatch) => {
  dispatch(showLoading());
  updatePartner(id, data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(editPartner(id, res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم تعديل الشريك بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleDeletePartner = (id) => (dispatch) => {
  dispatch(showLoading());
  destroyPartner(id)
    .then((res) => res.json())
    .then((res) => {
      dispatch(deletePartner(id));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم حذف الشريك بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};
