import React, { Component } from "react";
import Layout from "../base/Layout";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
import AddTestimonialModal from "../modals/AddTestimonialModal";
import EditTestimonialModal from "../modals/EditTestimonialModal";
import TestimonialsList from "../testimonials/TestimonialsList";
import { connect } from "react-redux";
import { handleReceiveTestimonials, handleDeleteTestimonial } from "../../actions/testimonials";

class ClientsTestimonial extends Component {
  state = {
    openAddDialog: false,
    openDeleteDialog: false,
    deleteId: null,
    openEditDialog: false,
    editId: null,
  };
  handleOpenAddDialog = () => {
    this.setState({ openAddDialog: true });
  };
  handleCloseAddDialog = () => {
    this.setState({ openAddDialog: false });
  };
  handleOpenDeleteDialog = (id) => {
    this.setState({ openDeleteDialog: true, deleteId: id });
  };
  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDialog: false, deleteId: null });
  };
  handleOpenEditDialog = (id) => {
    this.setState({ openEditDialog: true, editId: id });
  };
  handleCloseEditDialog = () => {
    this.setState({ openEditDialog: false, editId: null });
  };

  handleConfirmDelete = () => {
    const { deleteTestimonial } = this.props;
    const { deleteId } = this.state;
    deleteTestimonial(deleteId);
  };

  componentDidMount() {
    const { getTestimonials } = this.props;
    getTestimonials();
  }

  render() {
    const { openAddDialog, openDeleteDialog, openEditDialog, editId } = this.state;
    const {
      testimonials,
      user: { permissions, is_super_admin },
    } = this.props;

    return (
      <Layout
        title="شهادة العملاء"
        addBtnText="إضافة شهادة جديد"
        showAddBtn={is_super_admin || permissions.includes(36)}
        handleBtnClicked={this.handleOpenAddDialog}
      >
        <TestimonialsList
          handleDeleteItem={this.handleOpenDeleteDialog}
          handleEditItem={this.handleOpenEditDialog}
          testimonials={testimonials}
        />
        {is_super_admin || permissions.includes(36) ? (
          <AddTestimonialModal open={openAddDialog} handleClose={this.handleCloseAddDialog} />
        ) : (
          ""
        )}

        <EditTestimonialModal open={openEditDialog} handleClose={this.handleCloseEditDialog} editId={editId} />
        <ConfirmDeleteModal
          open={openDeleteDialog}
          handleClose={this.handleCloseDeleteDialog}
          text="هل تريد حذف الشهادة؟"
          handleDelete={this.handleConfirmDelete}
        />
      </Layout>
    );
  }
}

const mapStateToProps = ({ testimonials, user }) => ({ testimonials, user });

const mapDispatchToProps = (dispatch) => ({
  getTestimonials: () => dispatch(handleReceiveTestimonials()),
  deleteTestimonial: (id) => dispatch(handleDeleteTestimonial(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientsTestimonial);
