import React, { Component } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArticlesImageUpload from "../articles/ArticlesImageUpload";
import CloseButton from "./CloseButton";
import { handleCreateArticle } from "../../actions/articles";
import { uploadMedia } from "../../utils/api";
import "moment/locale/en-au";
import Editor from "../articles/Editor";

const initialState = {
  title_ar: "",
  details_ar: "",
  title_en: "",
  details_en: "",
  files: [],
  imagePath: "",
  imageLoading: true,
  errors: {},
  isInvalid: true,
};

class AddArticle extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  state = initialState;

  // I miss typescript :(
  // type key = "title_ar" | "title_en" | "imagePath" | undefined
  // undefined means validate all fields, it's used in handleSubmit
  validateForm = (key) => {
    const { details_ar, details_en } = this.state;
    const errors = this.state.errors;
    const emptyEditorJSON = JSON.stringify([{ type: "paragraph", children: [{ text: "" }] }]);

    const checkField = (fieldName, errorMessage) => {
      if (!this.state[fieldName]) {
        errors[fieldName] = errorMessage;
      } else {
        delete errors[fieldName];
      }
    };

    const checkDetailsField = (fieldName, detailsValue, errorMessage) => {
      console.log(detailsValue);
      if (!Array.isArray(detailsValue) || JSON.stringify(detailsValue) === emptyEditorJSON) {
        errors[fieldName] = errorMessage;
      } else {
        delete errors[fieldName];
      }
    };

    if (!key || key === "title_ar") {
      checkField("title_ar", "العنوان بالعربية مطلوب");
    }

    if (!key || key === "title_en") {
      checkField("title_en", "العنوان بالإنجليزية مطلوب");
    }

    if (!key || key === "imagePath") {
      checkField("imagePath", "الصورة مطلوبة");
    }

    if (!key) {
      checkDetailsField("details_ar", details_ar, "المحتوى بالعربية مطلوب");
      checkDetailsField("details_en", details_en, "المحتوى بالإنجليزية مطلوب");
    }

    const isInvalid = Object.keys(errors).length > 0;
    this.setState({ errors, isInvalid });

    return isInvalid;
  };

  handleTitleArChange = (e) => {
    this.setState({ title_ar: e.target.value }, () => this.validateForm("title_ar"));
  };

  handleTitleEnChange = (e) => {
    this.setState({ title_en: e.target.value }, () => this.validateForm("title_en"));
  };

  // no need to validate details in onChange, it's an editor
  handleDetailsArChange = (value) => {
    this.setState({ details_ar: value }, () => this.validateForm("details_ar"));
  };
  handleDetailsEnChange = (value) => {
    this.setState({ details_en: value });
  };

  handleSetFiles = (files) => {
    this.setState({ files }, () => {
      const { files } = this.state;
      const form = new FormData();
      form.append("file", files[0]);
      form.append("mime_type", "image");

      uploadMedia(form)
        .then((res) => res.json())
        .then((res) => {
          this.setState({ imagePath: res.path, imageLoading: false });
          this.validateForm("imagePath");
        })
        .catch((err) => {
          if (err.status === 422) {
            this.setState({ imageLoading: false, errors: { imagePath: "الصورة مطلوبة" } });
            this.validateForm("imagePath");
          }
        });
    });
  };

  handleSubmit = () => {
    if (this.validateForm()) return;
    const { createArticle, handleClose } = this.props;
    const { title_ar, details_ar, title_en, details_en, imagePath } = this.state;
    const data = {
      title: {
        ar: title_ar,
        en: title_en,
      },
      content: {
        ar: JSON.stringify(details_ar),
        en: JSON.stringify(details_en),
      },
      image: imagePath,
    };
    createArticle(data);
    this.setState(initialState);
    handleClose();
  };

  render() {
    const { open, handleClose } = this.props;
    const { title_ar, title_en, files, imageLoading, errors } = this.state;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: "dialog",
        }}
        aria-labelledby="responsive-dialog-title"
        maxWidth={false}
      >
        <CloseButton handleClose={handleClose} />
        <DialogTitle id="responsive-dialog-title" className="text-center">
          إضافة مقال جديد
        </DialogTitle>
        <DialogContent>
          <div className="form-group">
            <ArticlesImageUpload files={files} setFiles={this.handleSetFiles} loading={imageLoading} />
            {errors.imagePath && <span className="text-danger">{errors.imagePath}</span>}
          </div>

          <div className="form-group">
            <TextField
              type="text"
              placeholder="عنوان المقال بالعربية"
              className="form-control"
              required
              value={title_ar}
              onChange={this.handleTitleArChange}
              variant="outlined"
              size="small"
              error={errors.title_ar}
            />
            {errors.title_ar && <span className="text-danger">{errors.title_ar}</span>}
          </div>

          <div className="form-group">
            <TextField
              type="text"
              placeholder="عنوان المقال بالانجليزية"
              className="form-control"
              required
              value={title_en}
              onChange={this.handleTitleEnChange}
              variant="outlined"
              size="small"
              error={errors.title_en}
            />
            {errors.title_en && <span className="text-danger">{errors.title_en}</span>}
          </div>

          <div className="form-group">
            <div className="pt-3 px-4 border rounded">
              <Editor placeholder="المحتوى بالعربية" onChange={this.handleDetailsArChange} />
            </div>
            {errors.details_ar && <span className="text-danger">{errors.details_ar}</span>}
          </div>

          <div className="form-group">
            <div className="pt-3 px-4 border rounded">
              <Editor placeholder="المحتوى بالانجليزية" onChange={this.handleDetailsEnChange} />
            </div>
            {errors.details_en && <span className="text-danger">{errors.details_en}</span>}
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center align-items-center">
          <Button onClick={this.handleSubmit} color="primary" variant="contained" className="dialog__btn">
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createArticle: (data) => dispatch(handleCreateArticle(data)),
});

export default connect(null, mapDispatchToProps)(AddArticle);
