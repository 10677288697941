import React, { Component } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CloseButton from "./CloseButton";
import { handleEditSlider } from "../../actions/slider";

class EditSliderModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    editId: PropTypes.number,
  };

  state = {
    title_ar: "",
    subtitle_ar: "",
    title_en: "",
    subtitle_en: "",
    submitDisabled: true,
  };

  handleSubmitDisabled = () => {
    const { title_ar, subtitle_ar, title_en, subtitle_en, submitDisabled } = this.state;
    if (!title_ar || !title_en || (!subtitle_ar && !subtitle_en && !submitDisabled))
      this.setState({ submitDisabled: true });
    if (title_ar && subtitle_ar && title_en && subtitle_en && submitDisabled) this.setState({ submitDisabled: false });
  };
  handleTitleArChange = (e) => {
    this.setState({ title_ar: e.target.value }, this.handleSubmitDisabled);
  };
  handleSubtitleArChange = (e) => {
    this.setState({ subtitle_ar: e.target.value }, this.handleSubmitDisabled);
  };
  handleTitleEnChange = (e) => {
    this.setState({ title_en: e.target.value }, this.handleSubmitDisabled);
  };
  handleSubtitleEnChange = (e) => {
    this.setState({ subtitle_en: e.target.value }, this.handleSubmitDisabled);
  };

  handleSubmit = () => {
    const { updateSlider, editId, handleClose } = this.props;
    const { title_ar, subtitle_ar, title_en, subtitle_en } = this.state;
    const data = {
      title: {
        ar: title_ar,
        en: title_en,
      },
      desc: {
        ar: subtitle_ar,
        en: subtitle_en,
      },
    };
    updateSlider(editId, data);
    handleClose();
  };

  componentDidUpdate(oldProps) {
    if (oldProps.slider !== this.props.slider) {
      const { slider } = this.props;
      const sliderTitle_ar = slider ? slider.title.ar : "";
      const sliderSubtitle_ar = slider ? slider.desc.ar : "";
      const sliderTitle_en = slider ? slider.title.en : "";
      const sliderSubtitle_en = slider ? slider.desc.en : "";
      this.setState(
        {
          title_ar: sliderTitle_ar,
          subtitle_ar: sliderSubtitle_ar,
          title_en: sliderTitle_en,
          subtitle_en: sliderSubtitle_en,
        },
        this.handleSubmitDisabled
      );
    }
  }

  render() {
    const { open, handleClose } = this.props;
    const { title_ar, subtitle_ar, title_en, subtitle_en, submitDisabled } = this.state;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: "dialog",
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <CloseButton handleClose={handleClose} />
        <DialogTitle id="responsive-dialog-title" className="text-center">
          تعديل النص
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <TextField
                  type="text"
                  placeholder="العنوان الرئيسي بالعربية"
                  className="form-control"
                  required
                  value={title_ar}
                  onChange={this.handleTitleArChange}
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <TextField
                  type="text"
                  placeholder="العنوان الرئيسي بالانجليزية"
                  className="form-control"
                  required
                  value={title_en}
                  onChange={this.handleTitleEnChange}
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <TextField
              rows={3}
              placeholder="العنوان الفرعي بالعربية"
              multiline
              required
              value={subtitle_ar}
              onChange={this.handleSubtitleArChange}
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>
          <div className="form-group">
            <TextField
              rows={3}
              placeholder="العنوان الفرعي بالانجليزية"
              multiline
              required
              value={subtitle_en}
              onChange={this.handleSubtitleEnChange}
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center align-items-center">
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
            className="dialog__btn"
            disabled={submitDisabled}
          >
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ slider }, { editId }) => {
  const targetSlider = slider.find((item) => item.id === editId);
  return { slider: targetSlider };
};

const mapDispatchToProps = (dispatch) => ({
  updateSlider: (id, data) => dispatch(handleEditSlider(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditSliderModal);
