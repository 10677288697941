import React, { Component } from "react";
import { Pagination } from "@material-ui/lab";
import { AddCircleOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";
import LastWorksTable from "../last-works/LastWorksTable";
import EditProjectModal from "../modals/EditProjectModal";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
import { connect } from "react-redux";
import { handleReceiveProjects, handleDeleteProject, handlePaginateProjects } from "../../actions/projects";

class LastWorks extends Component {
  state = {
    openDeleteDialog: false,
    deleteId: null,
    editId: null,
    pagination: {
      per_page: 15,
      current_page: 1,
      last_page: 1,
    },
    paginationDisabled: false,
  };
  handleOpenDeleteDialog = (id) => {
    this.setState({ openDeleteDialog: true, deleteId: id });
  };
  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDialog: false, deleteId: null });
  };
  handleEditItem = (id) => {
    this.setState({ editId: id });
    this.props.history.push(`/projects/edit/${id}`);
  };

  handleConfirmDelete = () => {
    const { deleteProject } = this.props;
    const { deleteId } = this.state;
    deleteProject(deleteId);
  };

  handlePaginationChange = (e, current_page) => {
    const { last_page } = this.state.pagination;
    const { getProjectsPagination } = this.props;
    if (last_page >= current_page) {
      this.setState({ paginationDisabled: true });
      getProjectsPagination(current_page).then(() => {
        this.setState((prevState) => ({
          pagination: { ...prevState.pagination, current_page },
          paginationDisabled: false,
        }));
      });
    }
  };

  componentDidMount() {
    const { getProjects } = this.props;
    getProjects().then((res) => {
      if (res) {
        const {
          meta: { per_page, current_page, last_page },
        } = res;
        this.setState({
          pagination: {
            per_page,
            current_page,
            last_page,
          },
        });
      }
    });
  }

  render() {
    const {
      openDeleteDialog,
      openEditDialog,
      editId,
      pagination: { current_page, last_page, per_page },
      paginationDisabled,
    } = this.state;
    const {
      projects,
      user: { permissions, is_super_admin },
    } = this.props;
    const paginatedProjects =
      projects.length >= current_page * per_page
        ? projects.slice((current_page - 1) * per_page, current_page * per_page - 1)
        : projects.slice(-per_page);

    return (
      <div className="pb-5 container overflow-hidden">
        <div className="row px-3 pt-3 main-header">
          <div className="col-12">
            <h2 className="page-title">تعديل الصفحة الرئيسية</h2>
          </div>
        </div>
        <div className="row p-3">
          <div className="col-12 mb-3">
            <h3 className="page-subtitle">آخر الأعمال</h3>
            {is_super_admin || permissions.includes(44) ? (
              <Link to="/projects/add" className="add-btn btn pr-0">
                <AddCircleOutline /> إضافة مشروع جديد
              </Link>
            ) : (
              ""
            )}
          </div>
          <div className="col-12">
            <LastWorksTable
              handleEditItem={this.handleEditItem}
              handleDeleteItem={this.handleOpenDeleteDialog}
              projects={projects}
              pagination={this.state.pagination}
            />
          </div>
          {projects && (
            <div className="my-3 d-flex justify-content-center align-items-center flex-grow-1">
              <Pagination
                count={last_page}
                variant="outlined"
                shape="rounded"
                page={current_page}
                boundaryCount={2}
                onChange={this.handlePaginationChange}
                disabled={paginationDisabled}
              />
            </div>
          )}
        </div>
        <ConfirmDeleteModal
          open={openDeleteDialog}
          handleClose={this.handleCloseDeleteDialog}
          text="هل تريد حذف المشروع؟"
          handleDelete={this.handleConfirmDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ projects, user }) => ({ projects, user });

const mapDispatchToProps = (dispatch) => ({
  getProjects: () => dispatch(handleReceiveProjects()),
  deleteProject: (id) => dispatch(handleDeleteProject(id)),
  getProjectsPagination: (page) => dispatch(handlePaginateProjects(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LastWorks);
