import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import theme from "../utils/theme";
import { Router, BrowserRouter, Switch, Route } from "react-router-dom";
import SideBar from "./side-bar/SideBar";
import SliderView from "./views/SliderView";
import ClientsTestimonial from "./views/ClientsTestimonial";
import LastNews from "./views/LastNews";
import NewsDetails from "./views/NewsDetails";
import ContactDetails from "./views/ContactDetails";
import LastWorks from "./views/LastWorks";
import AddWork from "./views/AddWork";
import EditWork from "./views/EditWork";
import JobsView from "./views/JobsView";
import JobDetails from "./views/JobDetails";
import JobsRequests from "./views/JobsRequests";
import JobRequestDetails from "./views/JobRequestDetails";
import PriceRequests from "./views/PriceRequests";
import PriceRequestDetails from "./views/PriceRequestDetails";
import Admins from "./views/admins";
import Roles from "./views/roles";
//import MessagesView from "./views/MessagesView"
//import MessageDetails from "./views/MessageDetails"
import HomeView from "./views/HomeView";
import Login from "./views/Login";
import NotFound from "./views/NotFound";
import LoadingBar from "react-redux-loading-bar";
import { Provider as StoreProvider } from "react-redux";
import store from "./store";
import MessageAlert from "./base/MessageAlert";
import Partners from "./views/Partners";
import ProtectedContainer from "./base/ProtectedContainer";
import history from "../utils/history";
import QrCodes from "./views/QrCodes";
import Articles from "./views/Articles";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StoreProvider store={store}>
        <Router history={history}>
          <section className="main-section">
            <LoadingBar className="loading-bar" />
            <Switch>
              <Route exact path="/" component={Login} />
              <ProtectedContainer>
                <SideBar />
                <Route path="/home" component={HomeView} />
                <Route path="/roles" component={Roles} />
                <Route path="/admins" component={Admins} />
                <Route path="/qr-codes" component={QrCodes} />
                <Route path="/slider" component={SliderView} />
                <Route path="/testimonials" component={ClientsTestimonial} />
                <Route exact path="/news" component={LastNews} />
                <Route path="/news/:id" component={NewsDetails} />
                <Route path="/contacts" component={ContactDetails} />
                <Route exact path="/projects" component={LastWorks} />
                <Route path="/projects/add" component={AddWork} />
                <Route path="/projects/edit/:id" component={EditWork} />
                <Route exact path="/jobs" component={JobsView} />
                <Route path="/jobs/:id" component={JobDetails} />
                <Route exact path="/job-applications" component={JobsRequests} />
                <Route path="/job-applications/:id" component={JobRequestDetails} />
                <Route exact path="/price-requests" component={PriceRequests} />
                <Route path="/price-requests/:id" component={PriceRequestDetails} />
                {/* <Route exact path="/messages" component={MessagesView} />
                <Route path="/messages/details" component={MessageDetails} /> */}
                <Route path="/partners" component={Partners} />
                <Route path="/articles" component={Articles} />
              </ProtectedContainer>
              <Route component={NotFound} />
            </Switch>
            <MessageAlert />
          </section>
        </Router>
      </StoreProvider>
    </ThemeProvider>
  );
}

export default App;
