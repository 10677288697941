import React from "react";
import { AddCircleOutline } from "@material-ui/icons";

const Layout = ({ children, title, addBtnText, handleBtnClicked, showAddBtn }) => (
  <div className="pb-5 container overflow-hidden">
    <div className="row px-3 pt-3 main-header">
      <div className="col-12">
        <h2 className="page-title">تعديل الصفحة الرئيسية</h2>
      </div>
    </div>
    <div className="row p-3">
      <div className="col-12 mb-3">
        <h3 className="page-subtitle">{title}</h3>
        {showAddBtn ? (
          <button className="add-btn btn pr-0" onClick={handleBtnClicked}>
            <AddCircleOutline /> {addBtnText}
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
    <div className="row p-3">
      <div className="col-12">{children}</div>
    </div>
  </div>
);

export default Layout;
