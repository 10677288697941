import React, { Component } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CloseButton from "./CloseButton";
import { handleEditJob } from "../../actions/jobs";

class EditJobModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    editId: PropTypes.number,
  };

  state = {
    jobTitle_ar: "",
    jobTitle_en: "",
    jobDetails_ar: "",
    jobDetails_en: "",
    jobLocation_ar: "",
    jobLocation_en: "",
    submitDisabled: true,
  };

  handleSubmitDisabled = () => {
    const { targetJob } = this.props;
    const { jobTitle_ar, jobDetails_ar, jobLocation_ar, jobTitle_en, jobDetails_en, jobLocation_en, submitDisabled } =
      this.state;
    if (
      !jobTitle_ar ||
      !jobDetails_ar ||
      !jobTitle_en ||
      !jobDetails_en ||
      !jobLocation_en ||
      !jobLocation_ar ||
      // don't enable submit unless something have been changed
      (targetJob.title.ar === jobTitle_ar.trim() &&
        targetJob.title.en === jobTitle_en.trim() &&
        targetJob.desc.ar === jobDetails_ar.trim() &&
        targetJob.desc.en === jobDetails_en.trim() &&
        targetJob.location.ar === jobLocation_ar.trim() &&
        targetJob.location.en === jobLocation_en.trim())
    ) {
      this.setState({ submitDisabled: true });
    } else if (submitDisabled) {
      this.setState({ submitDisabled: false });
    }
  };

  componentDidUpdate(oldProps) {
    if (oldProps.targetJob !== this.props.targetJob) {
      const { targetJob } = this.props;
      const jobTitle_ar = targetJob ? targetJob.title.ar : "";
      const jobDetails_ar = targetJob ? targetJob.desc.ar : "";
      const jobLocation_ar = targetJob ? targetJob.location.ar : "";
      const jobTitle_en = targetJob ? targetJob.title.en : "";
      const jobDetails_en = targetJob ? targetJob.desc.en : "";
      const jobLocation_en = targetJob ? targetJob.location.en : "";
      this.setState(
        {
          jobTitle_ar,
          jobDetails_ar,
          jobLocation_ar,
          jobTitle_en,
          jobDetails_en,
          jobLocation_en,
        },
        this.handleSubmitDisabled
      );
    }
  }

  componentDidMount() {
    const { targetJob } = this.props;
    if (targetJob)
      this.setState(
        {
          jobTitle_ar: targetJob.title.ar,
          jobDetails_ar: targetJob.desc.ar,
          jobLocation_ar: targetJob.location.ar,
          jobTitle_en: targetJob.title.en,
          jobDetails_en: targetJob.desc.en,
          jobLocation_en: targetJob.location.en,
        },
        this.handleSubmitDisabled
      );
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, this.handleSubmitDisabled);
  };

  handleSubmit = () => {
    const { updateJob, handleClose, editId } = this.props;
    const { jobTitle_ar, jobDetails_ar, jobLocation_ar, jobTitle_en, jobDetails_en, jobLocation_en } = this.state;
    const data = {
      title: {
        ar: jobTitle_ar.trim(),
        en: jobTitle_en.trim(),
      },
      desc: {
        ar: jobDetails_ar.trim(),
        en: jobDetails_en.trim(),
      },
      location: {
        ar: jobLocation_ar.trim(),
        en: jobLocation_en.trim(),
      },
      status: 1,
    };
    updateJob(editId, data);
    handleClose();
    // reset this so handleSubmitDisabled logic would work properly
    this.setState({ submitDisabled: true });
  };

  render() {
    const { open, handleClose } = this.props;
    const { jobTitle_ar, jobDetails_ar, jobLocation_ar, jobTitle_en, jobDetails_en, jobLocation_en, submitDisabled } =
      this.state;
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: "dialog",
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <CloseButton handleClose={handleClose} />
        <DialogTitle id="responsive-dialog-title" className="text-center">
          تعديل الوظيفة
        </DialogTitle>
        <DialogContent>
          <div className="form-group">
            <TextField
              type="text"
              placeholder="عنوان الوظيفة بالعربية"
              className="form-control"
              required
              value={jobTitle_ar}
              name="jobTitle_ar"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              type="text"
              placeholder="عنوان الوظيفة بالانجليزية"
              className="form-control"
              required
              value={jobTitle_en}
              name="jobTitle_en"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              rows={3}
              placeholder="تفاصيل الوظيفة بالعربية"
              required
              value={jobDetails_ar}
              name="jobDetails_ar"
              onChange={this.handleInputChange}
              multiline
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>
          <div className="form-group">
            <TextField
              rows={3}
              placeholder="تفاصيل الوظيفة بالانجليزية"
              required
              value={jobDetails_en}
              name="jobDetails_en"
              onChange={this.handleInputChange}
              multiline
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>
          <div className="form-group">
            <TextField
              type="text"
              placeholder="مكان الوظيفة بالعربية"
              className="form-control"
              required
              value={jobLocation_ar}
              name="jobLocation_ar"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              type="text"
              placeholder="مكان الوظيفة بالانجليزية"
              className="form-control"
              required
              value={jobLocation_en}
              name="jobLocation_en"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center align-items-center">
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
            className="dialog__btn"
            disabled={submitDisabled}
          >
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ jobs, countries }, { editId }) => {
  const targetJob = jobs.find((item) => item.id === editId);
  return { targetJob, countries };
};

const mapDispatchToProps = (dispatch) => ({
  updateJob: (id, data) => dispatch(handleEditJob(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditJobModal);
