import React from "react";
import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from "@material-ui/core";
import CountriesTableRow from "./CountriesTableRow";
import PropTypes from "prop-types";

const CountriesTable = ({ handleEditItem, handleDeleteItem, countries }) =>
  countries.length === 0 ? (
    <Paper className="p-4 text-center">لا توجد دول</Paper>
  ) : (
    <TableContainer component={Paper} className="table-responsive">
      <Table className="table table-fixed slider__list">
        <TableHead>
          <TableRow className="last-news__theader">
            <TableCell align="center">#</TableCell>
            <TableCell align="center">اسم الدولة</TableCell>
            <TableCell align="center">افعال</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {countries.map((country, index) => (
            <CountriesTableRow
              key={country.id}
              handleEditItem={() => handleEditItem(country.id)}
              handleDeleteItem={() => handleDeleteItem(country.id)}
              {...country}
              index={index + 1}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

CountriesTable.propTypes = {
  handleEditItem: PropTypes.func.isRequired,
  handleDeleteItem: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
};

export default CountriesTable;
