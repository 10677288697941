import { RECEIVE_CONTACTS, CREATE_CONTACT, EDIT_CONTACT, DELETE_CONTACT } from "../actions/contacts";

export default function contacts(state = [], action) {
  switch (action.type) {
    case RECEIVE_CONTACTS:
      return action.contacts;
    case CREATE_CONTACT:
      return [action.contact, ...state];
    case EDIT_CONTACT:
      return state.map((contact) => (contact.id === action.id ? Object.assign(contact, action.data) : contact));
    case DELETE_CONTACT:
      return state.filter((contact) => contact.id !== action.id);
    default:
      return state;
  }
}
