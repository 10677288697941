import {
  RECEIVE_PRICE_REQUEST_NOTES,
  EDIT_PRICE_REQUEST_NOTE,
  DELETE_PRICE_REQUEST_NOTE,
  CREATE_PRICE_REQUEST_NOTE,
} from "../actions/priceRequestNotes";

export default function priceRequestNotes(state = {}, action) {
  switch (action.type) {
    case RECEIVE_PRICE_REQUEST_NOTES:
      return {
        ...state,
        [action.priceRequestId]: action.notes,
      };
    case CREATE_PRICE_REQUEST_NOTE:
      return {
        ...state,
        [action.priceRequestId]: state[action.priceRequestId].concat(action.note),
      };
    case EDIT_PRICE_REQUEST_NOTE:
      return {
        ...state,
        [action.priceRequestId]: state[action.priceRequestId].map((note) =>
          note.id === action.noteId ? Object.assign(note, action.note) : note
        ),
      };
    case DELETE_PRICE_REQUEST_NOTE:
      return {
        ...state,
        [action.priceRequestId]: state[action.priceRequestId].filter((note) => note.id !== action.noteId),
      };
    default:
      return state;
  }
}
