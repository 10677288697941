import React from "react";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
import { EditOutlined, DeleteOutline } from "@material-ui/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
const RolesListItem = ({ handleDeleteItem, handleEditItem, id, name, user: { permissions, is_super_admin } }) => (
  <TableRow>
    <TableCell align="center">{id}</TableCell>
    <TableCell align="center">{name}</TableCell>
    <TableCell align="center">
      {is_super_admin || permissions.includes(49) ? (
        <IconButton aria-label="edit" onClick={() => handleEditItem(id)}>
          <EditOutlined />
        </IconButton>
      ) : (
        ""
      )}
      {is_super_admin || permissions.includes(50) ? (
        <IconButton aria-label="delete" onClick={() => handleDeleteItem(id)}>
          <DeleteOutline color="error" />
        </IconButton>
      ) : (
        ""
      )}
    </TableCell>
  </TableRow>
);

RolesListItem.propTypes = {
  handleDeleteItem: PropTypes.func.isRequired,
  handleEditItem: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(RolesListItem);
