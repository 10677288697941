import { showLoading, hideLoading } from "react-redux-loading-bar";
import { getStatistics } from "../utils/api";
import { showMessageAlert } from "./messageAlert";

export const RECEIVE_STATISTICS = "RECEIVE_STATISTICS";

const receiveStatistics = (statistics) => ({
  type: RECEIVE_STATISTICS,
  statistics,
});

export const handleReceiveStatistics = () => (dispatch) => {
  dispatch(showLoading());
  getStatistics()
    .then((res) => res.json())
    .then((res) => {
      dispatch(receiveStatistics(res));
      dispatch(hideLoading());
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};
