import React from "react";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import { EditOutlined, DeleteOutline } from "@material-ui/icons";
import { connect } from "react-redux";
const StackedRow = ({ question, answer, handleEdit, handleDelete, user: { permissions, is_super_admin } }) => (
  <div className="row">
    <div className="col-12 col-md-8 col-lg-6 d-flex flex-wrap justify-content-between align-items-center">
      <div className="d-flex">
        <p className="d-inline-block m-0 p-2">{question}</p>
        <p className="d-inline-block m-0 p-2">{answer}</p>
      </div>
      <div>
        {is_super_admin || permissions.includes(57) ? (
          <IconButton aria-label="edit" onClick={handleEdit}>
            <EditOutlined />
          </IconButton>
        ) : (
          ""
        )}
        {is_super_admin || permissions.includes(58) ? (
          <IconButton aria-label="delete" onClick={handleDelete}>
            <DeleteOutline color="error" />
          </IconButton>
        ) : (
          ""
        )}
      </div>
    </div>
  </div>
);

StackedRow.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(StackedRow);
