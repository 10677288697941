import React from "react";
import { Paper, IconButton } from "@material-ui/core";
import { EditOutlined, DeleteOutline } from "@material-ui/icons";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
const RequestNoteCard = ({
  handleEditNote,
  handleDeleteNote,
  content,
  created_at,
  user: { permissions, is_super_admin },
}) => (
  <Paper className="p-4">
    <div className="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center">
      <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
        <p className="m-0 mt-2 mt-sm-0">{moment(created_at).format("MMMM DD") || "14 يناير"}</p>
      </div>
      <div className="d-flex justify-content-start">
        {is_super_admin || permissions.includes(28) ? (
          <IconButton aria-label="edit" onClick={handleEditNote}>
            <EditOutlined />
          </IconButton>
        ) : (
          ""
        )}
        {is_super_admin || permissions.includes(29) ? (
          <IconButton aria-label="delete" onClick={handleDeleteNote}>
            <DeleteOutline color="error" />
          </IconButton>
        ) : (
          ""
        )}
      </div>
    </div>
    <div className="mt-3">
      <p>
        {content ||
          `لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور
        طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... بروشور او فلاير
        على سبيل المثال ... او نماذج مواقع انترنت`}
      </p>
    </div>
  </Paper>
);

RequestNoteCard.propTypes = {
  handleEditNote: PropTypes.func.isRequired,
  handleDeleteNote: PropTypes.func.isRequired,
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(RequestNoteCard);
