import React from "react";
import { isAuthenticated } from "../../utils/helpers";
import { Redirect } from "react-router-dom";

function ProtectedContainer({ children }) {
  if (!isAuthenticated()) return <Redirect to="/" />;

  return <div className="d-flex flex-grow-1 mw-100">{children}</div>;
}

export default ProtectedContainer;
