import React from "react";
import { Paper, List } from "@material-ui/core";
import PropTypes from "prop-types";
import PartnersListItem from "./PartnersListItem";

const PartnersList = ({ handleDeleteItem, handleEditItem, partners }) =>
  partners.length ? (
    <Paper square>
      <List className="slider__list">
        {partners.map((partner) => (
          <PartnersListItem
            {...partner}
            handleDeleteItem={() => handleDeleteItem(partner.id)}
            handleEditItem={() => handleEditItem(partner.id)}
            key={partner.id}
          />
        ))}
      </List>
    </Paper>
  ) : (
    <Paper square>
      <List className="slider__list text-center p-5">لا يوجد شركاء</List>
    </Paper>
  );

PartnersList.propTypes = {
  handleDeleteItem: PropTypes.func.isRequired,
  handleEditItem: PropTypes.func.isRequired,
  partners: PropTypes.array.isRequired,
};

export default PartnersList;
