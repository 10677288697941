import { showLoading, hideLoading } from "react-redux-loading-bar";
import { getPermissions } from "../utils/api";
import { showMessageAlert } from "./messageAlert";

export const RECEIVE_PERMISSIONS = "RECEIVE_PERMISSIONS";

const receivePermissions = (permissions) => ({
  type: RECEIVE_PERMISSIONS,
  permissions,
});

export const handleReceivePermissions = () => (dispatch) => {
  dispatch(showLoading());
  getPermissions()
    .then((res) => res.json())
    .then((res) => {
      dispatch(receivePermissions(res.data));
      dispatch(hideLoading());
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};
