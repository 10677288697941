import React from "react";
import { TableContainer, Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import PropTypes from "prop-types";

const PriceDetailsTable = ({ person, country, service_name }) => (
  <TableContainer className="table-responsive">
    <Table className="table table-borderless">
      <TableBody>
        <TableRow>
          <TableCell align="right">الاسم:</TableCell>
          <TableCell align="right">{person ? person.name : "محمد"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">البريد الالكتروني:</TableCell>
          <TableCell align="right">{person ? person.email : "Mohamed@gmail.com"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">رقم الجوال:</TableCell>
          <TableCell align="right">{person ? person.phone : "0123456789"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">نوع الطلب:</TableCell>
          <TableCell align="right">{service_name || "استشارة"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">الدولة:</TableCell>
          <TableCell align="right">{country ? country.name.ar : "المملكة العربية السعودية"}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

PriceDetailsTable.propTypes = {
  person: PropTypes.object,
  country: PropTypes.object,
  meta: PropTypes.object,
};

export default PriceDetailsTable;
