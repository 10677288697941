import React from "react";
import { Paper, List } from "@material-ui/core";
import SliderListItem from "./SliderListItem";
import PropTypes from "prop-types";

const SliderList = ({ handleDeleteItem, handleEditItem, sliders }) =>
  sliders.length ? (
    <Paper square>
      <List className="slider__list">
        {sliders.map((slider) => (
          <SliderListItem
            {...slider}
            handleDeleteItem={() => handleDeleteItem(slider.id)}
            handleEditItem={() => handleEditItem(slider.id)}
            key={slider.id}
          />
        ))}
      </List>
    </Paper>
  ) : (
    <Paper square>
      <List className="slider__list text-center p-5">لا يوجد سلايدرز</List>
    </Paper>
  );

SliderList.propTypes = {
  handleDeleteItem: PropTypes.func.isRequired,
  handleEditItem: PropTypes.func.isRequired,
  sliders: PropTypes.array.isRequired,
};

export default SliderList;
