import React, { Component } from "react";
import Layout from "../base/Layout";
import SliderList from "../slider/SliderList";
import AddSliderModal from "../modals/AddSliderModal";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
import EditSliderModal from "../modals/EditSliderModal";
import { connect } from "react-redux";
import { handleReceiveSliders, handleDeleteSlider } from "../../actions/slider";

class SliderView extends Component {
  state = {
    openAddDialog: false,
    openDeleteDialog: false,
    deleteId: null,
    openEditDialog: false,
    editId: null,
  };
  handleOpenAddDialog = () => {
    this.setState({ openAddDialog: true });
  };
  handleCloseAddDialog = () => {
    this.setState({ openAddDialog: false });
  };
  handleOpenDeleteDialog = (id) => {
    this.setState({ openDeleteDialog: true, deleteId: id });
  };
  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDialog: false, deleteId: null });
  };
  handleOpenEditDialog = (id) => {
    this.setState({ openEditDialog: true, editId: id });
  };
  handleCloseEditDialog = () => {
    this.setState({ openEditDialog: false, editId: null });
  };

  handleConfirmDelete = () => {
    const { deleteSlider } = this.props;
    const { deleteId } = this.state;
    deleteSlider(deleteId);
  };

  componentDidMount() {
    const { getSliders } = this.props;
    getSliders();
  }

  render() {
    const { openAddDialog, openDeleteDialog, openEditDialog, editId } = this.state;
    const {
      slider,
      user: { permissions, is_super_admin },
    } = this.props;

    return (
      <Layout
        title="السلايدر"
        addBtnText="إضافة نص جديد"
        showAddBtn={is_super_admin || permissions.includes(60)}
        handleBtnClicked={this.handleOpenAddDialog}
      >
        <SliderList
          handleDeleteItem={this.handleOpenDeleteDialog}
          handleEditItem={this.handleOpenEditDialog}
          sliders={slider}
        />
        {is_super_admin || permissions.includes(60) ? (
          <AddSliderModal open={openAddDialog} handleClose={this.handleCloseAddDialog} />
        ) : (
          ""
        )}

        <EditSliderModal open={openEditDialog} handleClose={this.handleCloseEditDialog} editId={editId} />
        <ConfirmDeleteModal
          open={openDeleteDialog}
          handleClose={this.handleCloseDeleteDialog}
          text="هل تريد حذف السلايدر؟"
          handleDelete={this.handleConfirmDelete}
        />
      </Layout>
    );
  }
}

const mapStateToProps = ({ slider, user }) => ({ slider, user });

const mapDispatchToProps = (dispatch) => ({
  getSliders: () => dispatch(handleReceiveSliders()),
  deleteSlider: (id) => dispatch(handleDeleteSlider(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SliderView);
