import React from "react";
import { Paper } from "@material-ui/core";
import PropTypes from "prop-types";

const JobsCard = ({ children, title, count }) => (
  <div className="col-12 col-md-6 col-lg-4 mb-4 d-flex">
    <Paper className="p-4 w-100">
      {children}
      <h5 className="my-3 card__subtitle">{title}</h5>
      <h6 className="card__subtitle">{count}</h6>
    </Paper>
  </div>
);

JobsCard.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  children: PropTypes.object.isRequired,
};

export default JobsCard;
