import Cookies from "js-cookie";
import escapeHtml from "escape-html";
import { Text, Node } from "slate";

export function stripTags(content) {
  return content.replace(/<\/?[^>]+(>|$)|&nbsp;/g, "");
}

export function serializeSlateValue(nodes) {
  if (typeof nodes === "string") {
    try {
      nodes = JSON.parse(nodes);
    } catch (e) {
      return "";
    }
  }
  return nodes.map((n) => Node.string(n)).join("\n");
}

function handleAlign(node) {
  switch (node.align) {
    case "left":
      return " style={text-align: left;}"
    case "center":
      return " style={text-align: center;}"
    case "right":
      return " style={text-align: right;}"
    default:
      return ""
  }
}

export function isAuthenticated() {
  return Boolean(Cookies.get("SID"));
}

export function serializeSlateValueToHTML(nodes) {
  if (typeof nodes === "string") {
    try {
      nodes = JSON.parse(nodes);
    } catch (e) {
      return "";
    }
  }
  let html = "";
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    if (Text.isText(node)) {
      let string = escapeHtml(node.text);
      if (node.bold) string = `<strong>${string}</strong>`;
      if (node.italic) string = `<em>${string}</em>`;
      if (node.underline) string = `<u>${string}</u>`;
      if (node.code) string = `<code>${string}</code>`;
      html += string;
      continue;
    }

    const children = serializeSlateValueToHTML(node.children);

    switch (node.type) {
      case "block-quote":
        html += `<blockquote{${handleAlign(node)}}><p>${children}</p></blockquote>`;
        break;
      case "heading-one":
        html += `<h1${handleAlign(node)}>${children}</h1>`;
        break;
      case "heading-two":
        html += `<h2${handleAlign(node)}>${children}</h2>`;
        break;
      case "heading-three":
        html += `<h3${handleAlign(node)}>${children}</h3>`;
        break;
      case "numbered-list":
        html += `<ol${handleAlign(node)}>${children}</ol>`;
        break;
      case "bulleted-list":
        html += `<ul${handleAlign(node)}>${children}</ul>`;
        break;
      case "list-item":
        html += `<li${handleAlign(node)}>${children}</li>`;
        break;
      case "paragraph":
        html += `<p${handleAlign(node)}>${children}</p>`;
        break;
      case "link":
        html += `<a href="${escapeHtml(node.url)}"${handleAlign(node)}>${children}</a>`;
        break;
      default:
        return children;
    }
  }
  return html;
}

export function isUrl(str) {
  let pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};
