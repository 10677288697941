import { showLoading, hideLoading } from "react-redux-loading-bar";
import {
  getJobApplicationNotes,
  storeJobApplicationNote,
  destroyJobApplicationNote,
  updateJobApplicationNote,
} from "../utils/api";
import { showMessageAlert } from "./messageAlert";

export const RECEIVE_JOB_APPLICATION_NOTES = "RECEIVE_JOB_APPLICATION_NOTES";
export const CREATE_JOB_APPLICATION_NOTE = "CREATE_JOB_APPLICATION_NOTE";
export const EDIT_JOB_APPLICATION_NOTE = "EDIT_JOB_APPLICATION_NOTE";
export const DELETE_JOB_APPLICATION_NOTE = "DELETE_JOB_APPLICATION_NOTE";

const receiveJobApplicationNotes = (jobId, notes) => ({
  type: RECEIVE_JOB_APPLICATION_NOTES,
  jobId,
  notes,
});

const createJobApplicationNote = (jobId, note) => ({
  type: CREATE_JOB_APPLICATION_NOTE,
  jobId,
  note,
});

const editJobApplicationNote = (jobId, noteId, note) => ({
  type: EDIT_JOB_APPLICATION_NOTE,
  jobId,
  noteId,
  note,
});

const deleteJobApplicationNote = (jobId, noteId) => ({
  type: DELETE_JOB_APPLICATION_NOTE,
  jobId,
  noteId,
});

export const handleReceiveJobApplicationNotes = (jobId) => (dispatch) => {
  dispatch(showLoading());
  getJobApplicationNotes(jobId)
    .then((res) => res.json())
    .then((res) => {
      dispatch(receiveJobApplicationNotes(jobId, res.data));
      dispatch(hideLoading());
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleCreateJobApplicationNote = (data) => (dispatch) => {
  dispatch(showLoading());
  storeJobApplicationNote(data)
    .then((res) => res.json())
    .then((res) => {
      const { job_application, content, created_at, id } = res.data;
      dispatch(
        createJobApplicationNote(job_application.id, {
          id,
          content,
          created_at,
        })
      );
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم إضافة الملاحظة بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleEditJobApplicationNote = (jobId, noteId, note) => (dispatch) => {
  dispatch(showLoading());
  updateJobApplicationNote(noteId, note)
    .then((res) => res.json())
    .then((res) => {
      const { job_application, content, created_at, id } = res.data;
      dispatch(
        editJobApplicationNote(job_application.id, id, {
          id,
          content,
          created_at,
        })
      );
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم تعديل الملاحظة بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleDeleteJobApplicationNote = (jobId, noteId) => (dispatch) => {
  dispatch(showLoading());
  destroyJobApplicationNote(noteId)
    .then((res) => res.json())
    .then((res) => {
      dispatch(deleteJobApplicationNote(jobId, noteId));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم حذف الملاحظة بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};
