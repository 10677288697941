import React from "react";
import { ListItem, ListItemText, IconButton, ListItemAvatar, Avatar } from "@material-ui/core";
import { DeleteOutline, EditOutlined } from "@material-ui/icons/";
import PropTypes from "prop-types";
import { connect } from "react-redux";
const PartnersListItem = ({
  handleDeleteItem,
  handleEditItem,
  title,
  image,
  user: { permissions, is_super_admin },
}) => (
  <ListItem
    classes={{
      root: "text-right d-flex flex-column justify-content-start align-items-start flex-md-row align-items-md-center justify-content-md-between",
    }}
  >
    <ListItemAvatar>
      <Avatar src={image} />
    </ListItemAvatar>
    <ListItemText primary={title.ar} />
    <div className="pl-2 pr-4 d-flex align-items-center">
      {is_super_admin || permissions.includes(41) ? (
        <IconButton aria-label="edit" onClick={handleEditItem}>
          <EditOutlined />
        </IconButton>
      ) : (
        ""
      )}
      {is_super_admin || permissions.includes(42) ? (
        <IconButton aria-label="delete" onClick={handleDeleteItem}>
          <DeleteOutline color="error" />
        </IconButton>
      ) : (
        ""
      )}
    </div>
  </ListItem>
);

PartnersListItem.propTypes = {
  handleDeleteItem: PropTypes.func.isRequired,
  handleEditItem: PropTypes.func.isRequired,
  title: PropTypes.object.isRequired,
  image: PropTypes.string.isRequired,
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(PartnersListItem);
