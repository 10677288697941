import { showLoading, hideLoading } from "react-redux-loading-bar";
import { getSliders, storeSlider, updateSlider, destroySlider } from "../utils/api";
import { showMessageAlert } from "./messageAlert";

export const RECEIVE_SLIDERS = "RECEIVE_SLIDERS";
export const CREATE_SLIDER = "CREATE_SLIDER";
export const EDIT_SLIDER = "EDIT_SLIDER";
export const DELETE_SLIDER = "DELETE_SLIDER";

const receiveSliders = (sliders) => ({
  type: RECEIVE_SLIDERS,
  sliders,
});

const createSlider = (slider) => ({
  type: CREATE_SLIDER,
  slider,
});

const editSlider = (id, data) => ({
  type: EDIT_SLIDER,
  id,
  data,
});

const deleteSlider = (id) => ({
  type: DELETE_SLIDER,
  id,
});

export const handleReceiveSliders = () => (dispatch) => {
  dispatch(showLoading());
  getSliders()
    .then((res) => res.json())
    .then((res) => {
      dispatch(receiveSliders(res.data));
      dispatch(hideLoading());
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleCreateSlider = (data) => (dispatch) => {
  dispatch(showLoading());
  storeSlider(data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(createSlider(res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم إضافة السلايدر بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleEditSlider = (id, data) => (dispatch) => {
  dispatch(showLoading());
  updateSlider(id, data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(editSlider(id, res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم تعديل السلايدر بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleDeleteSlider = (id) => (dispatch) => {
  dispatch(showLoading());
  destroySlider(id)
    .then((res) => res.json())
    .then((res) => {
      dispatch(deleteSlider(id));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم حذف السلايدر بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};
