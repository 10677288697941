import { showLoading, hideLoading } from "react-redux-loading-bar";
import { loginAdmin, logoutAdmin } from "../utils/api";
import Cookies from "js-cookie";
import { showMessageAlert } from "./messageAlert";

export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS";

const authenticatedUser = (data, permissions, is_super_admin) => ({
  type: AUTHENTICATE_USER,
  payload: { ...data, permissions, is_super_admin },
});

const logoutUser = () => ({
  type: LOGOUT_USER,
});

const updatePermissions = (permissions) => ({
  type: UPDATE_PERMISSIONS,
  payload: permissions,
});

export const handleUserAuthentication = (email, password) => (dispatch) => {
  dispatch(showLoading());
  return loginAdmin(email, password)
    .then((res) => res.json())
    .then((res) => {
      let isAuthenticated = true;
      if (res.errors) {
        const message = res.errors[Object.keys(res.errors)[0]][0];
        dispatch(showMessageAlert(message, "error"));
        isAuthenticated = false;
      } else {
        dispatch(authenticatedUser(res.data, res.permissions, res.is_super_admin));

        Cookies.set("SID", res.token);
        Cookies.set(
          "permissions",
          res.permissions.map((permission) => permission.id)
        );
        Cookies.set("user_id", res.data.id);
        Cookies.set("user_name", res.data.name);
        Cookies.set("is_super_admin", res.is_super_admin);
      }
      dispatch(hideLoading());
      return isAuthenticated;
    })
    .catch((e) => {
      dispatch(showMessageAlert("Error login admin", "error"));
      dispatch(hideLoading());
      if (e.res) {
        e.res.json().then((data) => {
          if (data.errors) {
            const message = data.errors[Object.keys(data.errors)[0]][0];
            dispatch(showMessageAlert(message, "error"));
          }
        });
      }
    });
};

export const handleUserLogout = () => (dispatch) => {
  dispatch(showLoading());
  return logoutAdmin()
    .then((res) => {
      let isLoggedOut = true;
      if (res.ok) {
        dispatch(logoutUser());
        Cookies.remove("SID");
        Cookies.remove("permissions");
        Cookies.remove("user_id");
        Cookies.remove("user_name");
        Cookies.remove("is_super_admin");
      } else {
        dispatch(showMessageAlert(res.message, "error"));
        isLoggedOut = false;
      }
      dispatch(hideLoading());
      return isLoggedOut;
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handlePermissionsUpdate = (permissions) => (dispatch) => {
  Cookies.set(
    "permissions",
    permissions.map((permission) => permission.id)
  );
  dispatch(updatePermissions(permissions));
};
