import { RECEIVE_PARTNERS, CREATE_PARTNER, EDIT_PARTNER, DELETE_PARTNER, PAGINATE_PARTNERS } from "../actions/partners";
import sortBy from "sort-by";

export default function partners(state = [], action) {
  switch (action.type) {
    case RECEIVE_PARTNERS:
      return action.partners;
    case PAGINATE_PARTNERS: {
      return action.partners;
    }
    case CREATE_PARTNER:
      return [action.partner, ...state];
    case EDIT_PARTNER:
      return state.map((partner) => (partner.id === action.id ? Object.assign(partner, action.data) : partner));
    case DELETE_PARTNER:
      return state.filter((partner) => partner.id !== action.id);
    default:
      return state;
  }
}
