import React from "react";

const NotFound = () => (
  <div className="container d-flex justify-content-center align-items-center">
    <div className="row justify-content-center align-items-center">
      <div>
        <h1>404</h1>
        <p>هذه الصفحة غير موجودة</p>
      </div>
    </div>
  </div>
);

export default NotFound;
