import { RECEIVE_ARTICLES, CREATE_ARTICLE, EDIT_ARTICLE, DELETE_ARTICLE } from "../actions/articles";

export default function news(state = [], action) {
  switch (action.type) {
    case RECEIVE_ARTICLES:
      return action.articles;
    case CREATE_ARTICLE:
      return [action.article, ...state];
    case EDIT_ARTICLE:
      return state.map((news) => (news.id === action.id ? Object.assign(news, action.data) : news));
    case DELETE_ARTICLE:
      return state.filter((news) => news.id !== action.id);
    default:
      return state;
  }
}
