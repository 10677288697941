import React from "react";
import { IconButton } from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";
import PropTypes from "prop-types";

const CloseButton = ({ handleClose }) => (
  <IconButton className="dialog-close" onClick={handleClose}>
    <HighlightOff color="error" />
  </IconButton>
);

CloseButton.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default CloseButton;
