import { SHOW_MESSAGE_ALERT, CLOSE_MESSAGE_ALERT } from "../actions/messageAlert";

export default function messageAlert(state = { open: false, message: "", level: "" }, action) {
  switch (action.type) {
    case SHOW_MESSAGE_ALERT:
      return {
        open: true,
        message: action.message,
        level: action.level,
      };
    case CLOSE_MESSAGE_ALERT:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
}
