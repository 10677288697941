import React from "react";
import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from "@material-ui/core";
import JobsRequestsTableRow from "./JobRequestsTableRow";
import PropTypes from "prop-types";

const JobsRequestsTable = ({ handleDeleteItem, jobApplications }) =>
  jobApplications.length === 0 ? (
    <Paper className="p-4 text-center">لا توجد طلبات توظيف</Paper>
  ) : (
    <TableContainer component={Paper} className="table-responsive">
      <Table className="table table-fixed">
        <TableHead>
          <TableRow className="last-news__theader">
            <TableCell align="center">#</TableCell>
            <TableCell align="center">الأسم</TableCell>
            <TableCell align="center">البريد الالكتروني</TableCell>
            <TableCell align="center">نوع الوظيفة</TableCell>
            <TableCell align="center">التاريخ</TableCell>
            <TableCell align="center">السيرة الذاتية</TableCell>
            <TableCell align="center">افعال</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobApplications.map((application, index) => (
            <JobsRequestsTableRow
              handleDeleteItem={() => handleDeleteItem(application.id)}
              {...application}
              key={application.id}
              index={index + 1}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

JobsRequestsTable.propTypes = {
  handleDeleteItem: PropTypes.func.isRequired,
  jobApplications: PropTypes.array.isRequired,
};

export default JobsRequestsTable;
