import { showLoading, hideLoading } from "react-redux-loading-bar";
import { getTestimonials, storeTestimonial, updateTestimonial, destroyTestimonial } from "../utils/api";
import { showMessageAlert } from "./messageAlert";

export const RECEIVE_TESTIMONIALS = "RECEIVE_TESTIMONIALS";
export const CREATE_TESTIMONIAL = "CREATE_TESTIMONIAL";
export const EDIT_TESTIMONIAL = "EDIT_TESTIMONIAL";
export const DELETE_TESTIMONIAL = "DELETE_TESTIMONIAL";

const receiveTestimonials = (testimonials) => ({
  type: RECEIVE_TESTIMONIALS,
  testimonials,
});

const createTestimonial = (testimonial) => ({
  type: CREATE_TESTIMONIAL,
  testimonial,
});

const editTestimonial = (id, data) => ({
  type: EDIT_TESTIMONIAL,
  id,
  data,
});

const deleteTestimonial = (id) => ({
  type: DELETE_TESTIMONIAL,
  id,
});

export const handleReceiveTestimonials = () => (dispatch) => {
  dispatch(showLoading());
  getTestimonials()
    .then((res) => res.json())
    .then((res) => {
      dispatch(receiveTestimonials(res.data));
      dispatch(hideLoading());
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleCreateTestimonial = (data) => (dispatch) => {
  dispatch(showLoading());
  storeTestimonial(data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(createTestimonial(res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم إضافة الشهادة بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleEditTestimonial = (id, data) => (dispatch) => {
  dispatch(showLoading());
  updateTestimonial(id, data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(editTestimonial(id, res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم تعديل الشهادة بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleDeleteTestimonial = (id) => (dispatch) => {
  dispatch(showLoading());
  destroyTestimonial(id)
    .then((res) => res.json())
    .then((res) => {
      dispatch(deleteTestimonial(id));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم حذف الشهادة بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};
