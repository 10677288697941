import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from "@material-ui/core";
import PropTypes from "prop-types";
import CloseButton from "./CloseButton";

const ConfirmDeleteModal = ({ open, handleClose, text, handleDelete }) => {
  const handleConfirmDelete = () => {
    handleDelete();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: "dialog",
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <CloseButton handleClose={handleClose} />
      <DialogTitle id="responsive-dialog-title" className="text-center">
        تأكيد الحذف
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions className="justify-content-between align-items-center px-4">
        <Button onClick={handleConfirmDelete} color="primary" variant="contained">
          تأكيد
        </Button>
        <Button onClick={handleClose} color="primary" variant="contained">
          إلغاء
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default ConfirmDeleteModal;
