import { RECEIVE_COUNTRIES, CREATE_COUNTRY, EDIT_COUNTRY, DELETE_COUNTRY } from "../actions/countries";

export default function countries(state = [], action) {
  switch (action.type) {
    case RECEIVE_COUNTRIES:
      return action.countries;
    case CREATE_COUNTRY:
      return [action.country, ...state];
    case EDIT_COUNTRY:
      return state.map((country) => (country.id === action.id ? Object.assign(country, action.data) : country));
    case DELETE_COUNTRY:
      return state.filter((country) => country.id !== action.id);
    default:
      return state;
  }
}
