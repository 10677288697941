import React, { Component } from "react";
import RequestDetailsTable from "../job-requests/RequestDetailsTable";
import { Button } from "@material-ui/core";
import { NoteOutlined } from "@material-ui/icons";
import RequestNoteCard from "../job-requests/RequestNoteCard";
import AddNoteModal from "../modals/AddNoteModal";
import EditNoteModal from "../modals/EditNoteModal";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
import { handleReceiveJobApplicationDetails } from "../../actions/jobApplications";
import { connect } from "react-redux";
import {
  handleReceiveJobApplicationNotes,
  handleCreateJobApplicationNote,
  handleDeleteJobApplicationNote,
  handleEditJobApplicationNote,
} from "../../actions/jobApplicationNotes";

class JobRequestDetails extends Component {
  state = {
    openAddDialog: false,
    openDeleteDialog: false,
    deleteId: null,
    openEditDialog: false,
    editId: null,
  };
  handleOpenAddDialog = () => {
    this.setState({ openAddDialog: true });
  };
  handleCloseAddDialog = () => {
    this.setState({ openAddDialog: false });
  };
  handleOpenDeleteDialog = (id) => {
    this.setState({ openDeleteDialog: true, deleteId: id });
  };
  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDialog: false, deleteId: null });
  };
  handleOpenEditDialog = (id) => {
    this.setState({ openEditDialog: true, editId: id });
  };
  handleCloseEditDialog = () => {
    this.setState({ openEditDialog: false, editId: null });
  };

  handleSubmitAddNote = (content) => {
    const { createJobApplicationNote, jobApplicationId } = this.props;
    const data = { job_application_id: jobApplicationId, content };
    createJobApplicationNote(data);
  };

  handleSubmitEditNote = (content) => {
    const { updateJobApplicationNote, jobApplicationId } = this.props;
    const { editId } = this.state;
    const data = { content };
    updateJobApplicationNote(jobApplicationId, editId, data);
  };

  handleConfirmDeleteNote = () => {
    const { deleteJobApplicationNote, jobApplicationId } = this.props;
    const { deleteId } = this.state;
    deleteJobApplicationNote(jobApplicationId, deleteId);
  };

  componentDidMount() {
    const {
      targetJobApplication,
      jobApplicationId,
      notes,
      getJobApplicationDetails,
      getJobApplicationNotes,
      user: { permissions, is_super_admin },
    } = this.props;

    if (!targetJobApplication) getJobApplicationDetails(jobApplicationId);

    if (is_super_admin || permissions.includes(26)) {
      if (!notes) getJobApplicationNotes(jobApplicationId);
    }
  }

  render() {
    const { openAddDialog, openDeleteDialog, openEditDialog, editId } = this.state;
    const {
      targetJobApplication,
      notes,
      user: { permissions, is_super_admin },
    } = this.props;
    const note = editId ? notes.find((item) => item.id === editId) : undefined;

    return (
      <div className="pb-5 container overflow-hidden">
        <div className="row px-3 pt-3 main-header">
          <div className="col-12">
            <h2 className="page-title">الوظائف</h2>
          </div>
        </div>
        <div className="row p-3">
          <div className="col d-flex align-items-center">
            <h5 className="mb-0 ml-md-3 text-theme-primary page-subtitle-lead">معلومات التواصل</h5>
          </div>
        </div>
        <div className="row">
          <div className="d-flex px-3">
            <RequestDetailsTable jobApplication={targetJobApplication} />
          </div>
        </div>
        <div className="row p-3">
          <div className="col-12 mb-2">
            <h6 className="text-theme-primary">ملاحظات</h6>
          </div>
          <div className="col-12">
            {is_super_admin || permissions.includes(27) ? (
              <Button
                color="primary"
                variant="contained"
                startIcon={<NoteOutlined className="ml-2" />}
                onClick={this.handleOpenAddDialog}
              >
                إضافة ملاحظة
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
        {notes && (
          <div className="row">
            <div className="col-12 col-md-10">
              {notes.map((note) => (
                <RequestNoteCard
                  handleEditNote={() => this.handleOpenEditDialog(note.id)}
                  handleDeleteNote={() => this.handleOpenDeleteDialog(note.id)}
                  key={note.id}
                  {...note}
                />
              ))}
            </div>
          </div>
        )}
        <AddNoteModal
          open={openAddDialog}
          handleClose={this.handleCloseAddDialog}
          handleAddNote={this.handleSubmitAddNote}
        />
        <EditNoteModal
          open={openEditDialog}
          handleClose={this.handleCloseEditDialog}
          handleEditNote={this.handleSubmitEditNote}
          note={note}
        />
        <ConfirmDeleteModal
          open={openDeleteDialog}
          handleClose={this.handleCloseDeleteDialog}
          text="هل تريد حذف الملاحظة؟"
          handleDelete={this.handleConfirmDeleteNote}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ jobApplications, jobApplicationNotes, user }, props) => {
  const jobApplicationId = parseInt(props.match.params.id);
  const targetJobApplication = jobApplications.find((item) => item.id === jobApplicationId);
  const notes = jobApplicationNotes[jobApplicationId];
  if (targetJobApplication) {
    const { id, job, country, ...person } = targetJobApplication;
    return {
      targetJobApplication,
      job,
      country,
      person,
      jobApplicationId,
      notes,
      user,
    };
  }
  return { targetJobApplication, jobApplicationId, notes, user };
};

const mapDispatchToProps = (dispatch) => ({
  getJobApplicationDetails: (id) => dispatch(handleReceiveJobApplicationDetails(id)),
  getJobApplicationNotes: (jobId) => dispatch(handleReceiveJobApplicationNotes(jobId)),
  createJobApplicationNote: (data) => dispatch(handleCreateJobApplicationNote(data)),
  deleteJobApplicationNote: (jobId, noteId) => dispatch(handleDeleteJobApplicationNote(jobId, noteId)),
  updateJobApplicationNote: (jobId, noteId, note) => dispatch(handleEditJobApplicationNote(jobId, noteId, note)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobRequestDetails);
