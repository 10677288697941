import React, { Component } from "react";
import WorkDetailsForm from "../last-works/WorkDetailsForm";
import ImageUpload from "../last-works/ImageUpload";
import ProjectPictures from "../last-works/ProjectPictures";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { handleCreateProject } from "../../actions/projects";
import slugify from "slugify";
import { uploadMedia } from "../../utils/api";
import { showMessageAlert } from "../../actions/messageAlert";

class AddWork extends Component {
  state = {
    logo: [],
    name_ar: "",
    description_ar: "",
    name_en: "",
    description_en: "",
    url: "",
    submitDisabled: true,
    pictures: [[], []],
    imageLoaders: [false, false, false],
    imagePaths: {
      logo: "",
      first: "",
      second: "",
    },
  };

  handleInputChange = (e, name) => {
    this.setState({ [name]: e.target.value }, this.handleSubmitDisabled);
  };
  handleSubmitDisabled = () => {
    const { imagePaths, name_ar, description_ar, name_en, description_en, url, submitDisabled } = this.state;
    if (
      Object.keys(imagePaths).length !== 3 ||
      !name_ar ||
      !description_ar ||
      !name_en ||
      !description_en ||
      (!url && !submitDisabled)
    )
      this.setState({ submitDisabled: true });
    if (
      Object.keys(imagePaths).length === 3 &&
      name_ar &&
      description_ar &&
      name_en &&
      description_en &&
      url &&
      submitDisabled
    )
      this.setState({ submitDisabled: false });
  };
  handleChangeLogo = (logo) => {
    this.setState(
      (prevState) => {
        let imageLoaders = prevState.imageLoaders;
        imageLoaders[2] = true;

        return {
          logo,
          imageLoaders,
        };
      },
      () => {
        this.handleSubmitDisabled();

        const { showUploadError } = this.props;
        const { logo } = this.state;
        const logoForm = new FormData();
        logoForm.append("file", logo[0]);
        logoForm.append("mime_type", "image");

        uploadMedia(logoForm)
          .then((res) => res.json())
          .then((res) => {
            this.setState((prevState) => {
              let imageLoaders = prevState.imageLoaders;
              imageLoaders[2] = false;
              return {
                imageLoaders,
                imagePaths: { ...prevState.imagePaths, logo: res.path },
              };
            });
          })
          .catch(() => {
            showUploadError("حدث خطأ");
          });
      }
    );
  };
  handleChangeFirstPicture = (picture) => {
    this.setState(
      (prevState) => {
        let pics = prevState.pictures;
        pics[0] = picture;

        let imageLoaders = prevState.imageLoaders;
        imageLoaders[0] = true;

        return { pictures: pics, imageLoaders };
      },
      () => {
        const { pictures } = this.state;
        const { showUploadError } = this.props;
        const firstSliderForm = new FormData();
        firstSliderForm.append("file", pictures[0][0]);
        firstSliderForm.append("mime_type", "image");

        uploadMedia(firstSliderForm)
          .then((res) => res.json())
          .then((res) => {
            this.setState((prevState) => {
              let imageLoaders = prevState.imageLoaders;
              imageLoaders[0] = false;
              return {
                imageLoaders,
                imagePaths: { ...prevState.imagePaths, first: res.path },
              };
            });
          })
          .catch(() => {
            showUploadError("حدث خطأ");
          });
      }
    );
  };

  handleChangeSecondPicture = (picture) => {
    this.setState(
      (prevState) => {
        let pics = prevState.pictures;
        pics[1] = picture;

        let imageLoaders = prevState.imageLoaders;
        imageLoaders[1] = true;

        return { pictures: pics, imageLoaders };
      },
      () => {
        const { pictures } = this.state;
        const { showUploadError } = this.props;
        const secondSliderForm = new FormData();
        secondSliderForm.append("file", pictures[1][0]);
        secondSliderForm.append("mime_type", "image");

        uploadMedia(secondSliderForm)
          .then((res) => res.json())
          .then((res) => {
            this.setState((prevState) => {
              let imageLoaders = prevState.imageLoaders;
              imageLoaders[1] = false;
              return {
                imageLoaders,
                imagePaths: { ...prevState.imagePaths, second: res.path },
              };
            });
          })
          .catch(() => {
            showUploadError("حدث خطأ");
          });
      }
    );
  };
  validURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };
  handleSubmit = () => {
    const { name_ar, description_ar, name_en, description_en, url, imagePaths } = this.state;
    const { createProject, history, showUploadError } = this.props;

    const data = {
      name: { ar: name_ar, en: name_en },
      desc: { ar: description_ar, en: description_en },
      slug: { ar: name_ar, en: slugify(name_en) },
      website: url,
      image: imagePaths.logo,
      sliders: imagePaths.second ? [imagePaths.first, imagePaths.second] : [imagePaths.first],
    };
    var submit = true;
    if (!this.validURL(url)) {
      submit = false;
      showUploadError("يجب إدخال رابط صحيح");
    }

    if (submit) {
      createProject(data)
        .then((res) => {
          history.push("/projects");
        })
        .catch((e) => {
          console.log("error", e);
        });
    }
  };

  render() {
    const { name_ar, description_ar, name_en, description_en, url, submitDisabled, logo, pictures, imageLoaders } =
      this.state;

    return (
      <div className="pb-5 container overflow-hidden">
        <div className="row px-3 pt-3 main-header">
          <div className="col-12">
            <h2 className="page-title">تعديل الصفحة الرئيسية</h2>
          </div>
        </div>
        <div className="row p-3">
          <div className="col-12">
            <h3 className="page-subtitle">إضافة مشروع جديد</h3>
          </div>
        </div>
        <div className="row p-3 justify-content-center align-items-center">
          <div className="col-12 mb-4 text-center">
            <h5 className="text-theme-primary mb-0 page-subtitle-lead">شعار المشروع</h5>
          </div>
          <div className="col-12 col-md-6 image-upload-container">
            <ImageUpload files={logo} setFiles={this.handleChangeLogo} loading={imageLoaders[2]} />
          </div>
        </div>
        <hr />
        <ProjectPictures
          files={pictures}
          handleChangeFirstPicture={this.handleChangeFirstPicture}
          handleChangeSecondPicture={this.handleChangeSecondPicture}
          imageLoaders={imageLoaders}
        />
        <hr />
        <WorkDetailsForm
          name_ar={name_ar}
          description_ar={description_ar}
          name_en={name_en}
          description_en={description_en}
          url={url}
          handleInputChange={this.handleInputChange}
        />
        <div className="row p-3">
          <div className="col-12 text-center">
            <Button
              color="primary"
              variant="contained"
              size="large"
              className="mw-50"
              onClick={this.handleSubmit}
              disabled={submitDisabled}
            >
              حفظ
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createProject: (project) => dispatch(handleCreateProject(project)),
  showUploadError: (message) => dispatch(showMessageAlert(message, "error")),
});

export default connect(null, mapDispatchToProps)(AddWork);
