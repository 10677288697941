import { showLoading, hideLoading } from "react-redux-loading-bar";
import { getCountries, storeCountry, updateCountry, destroyCountry } from "../utils/api";
import { showMessageAlert } from "./messageAlert";

export const RECEIVE_COUNTRIES = "RECEIVE_COUNTRIES";
export const CREATE_COUNTRY = "CREATE_COUNTRY";
export const EDIT_COUNTRY = "EDIT_COUNTRY";
export const DELETE_COUNTRY = "DELETE_COUNTRY";

const receiveCountries = (countries) => ({
  type: RECEIVE_COUNTRIES,
  countries,
});

const createCountry = (country) => ({
  type: CREATE_COUNTRY,
  country,
});

const editCountry = (id, data) => ({
  type: EDIT_COUNTRY,
  id,
  data,
});

const deleteCountry = (id) => ({
  type: DELETE_COUNTRY,
  id,
});

export const handleReceiveCountries = () => (dispatch) => {
  dispatch(showLoading());
  getCountries()
    .then((res) => res.json())
    .then((res) => {
      dispatch(receiveCountries(res.data));
      dispatch(hideLoading());
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleCreateCountry = (data) => (dispatch) => {
  dispatch(showLoading());
  storeCountry(data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(createCountry(res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم إضافة الدولة بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleEditCountry = (id, data) => (dispatch) => {
  dispatch(showLoading());
  updateCountry(id, data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(editCountry(id, res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم تعديل الدولة بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleDeleteCountry = (id) => (dispatch) => {
  dispatch(showLoading());
  destroyCountry(id)
    .then((res) => res.json())
    .then((res) => {
      dispatch(deleteCountry(id));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم حذف الدولة بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};
