import React from "react";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
import { DeleteOutline, VisibilityOutlined, GetAppOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/ar";
import { connect } from "react-redux";
const JobsRequestsTableRow = ({
  handleDeleteItem,
  index,
  name,
  email,
  created_at,
  job,
  id,
  cv,
  user: { permissions, is_super_admin },
}) => (
  <TableRow>
    <TableCell align="center">{index}</TableCell>
    <TableCell align="center">{name}</TableCell>
    <TableCell align="center">{email}</TableCell>
    <TableCell align="center">{job ? job.title.ar : null}</TableCell>
    <TableCell align="center">{moment(created_at).local("ar").format("L")}</TableCell>
    <TableCell align="center">
      <a href={cv} download target="_blank" rel="noopener noreferrer">
        <GetAppOutlined color="primary" aria-label="download" />
      </a>
    </TableCell>
    <TableCell align="center">
      <Link to={`/job-applications/${id}`} className="icon-link">
        <IconButton aria-label="show">
          <VisibilityOutlined />
        </IconButton>
      </Link>
      {is_super_admin || permissions.includes(23) ? (
        <IconButton aria-label="delete" onClick={handleDeleteItem}>
          <DeleteOutline color="error" />
        </IconButton>
      ) : (
        ""
      )}
    </TableCell>
  </TableRow>
);

JobsRequestsTableRow.propTypes = {
  handleDeleteItem: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(JobsRequestsTableRow);
