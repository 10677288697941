import React, { Component } from "react";
import Layout from "../base/Layout";
import { Pagination } from "@material-ui/lab";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
import { connect } from "react-redux";
import { handleReceivePartners, handleDeletePartner, handlePaginatePartners } from "../../actions/partners";
import PartnersList from "../partners/PartnersList";
import AddPartnerModal from "../modals/AddPartnerModal";
import EditPartnerModal from "../modals/EditPartnerModal";

class Partners extends Component {
  state = {
    openAddDialog: false,
    openDeleteDialog: false,
    deleteId: null,
    openEditDialog: false,
    editId: null,
    pagination: {
      per_page: 15,
      current_page: 1,
      last_page: 1,
    },
    paginationDisabled: false,
  };
  handleOpenAddDialog = () => {
    this.setState({ openAddDialog: true });
  };
  handleCloseAddDialog = () => {
    this.setState({ openAddDialog: false });
  };
  handleOpenDeleteDialog = (id) => {
    this.setState({ openDeleteDialog: true, deleteId: id });
  };
  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDialog: false, deleteId: null });
  };
  handleOpenEditDialog = (id) => {
    this.setState({ openEditDialog: true, editId: id });
  };
  handleCloseEditDialog = () => {
    this.setState({ openEditDialog: false, editId: null });
  };

  handleConfirmDelete = () => {
    const { deletePartner } = this.props;
    const { deleteId } = this.state;
    deletePartner(deleteId);
  };

  handlePaginationChange = (e, current_page) => {
    const { last_page } = this.state.pagination;
    const { getPartnersPagination } = this.props;
    if (last_page >= current_page) {
      this.setState({ paginationDisabled: true });
      getPartnersPagination(current_page).then(() => {
        this.setState((prevState) => ({
          pagination: { ...prevState.pagination, current_page },
          paginationDisabled: false,
        }));
      });
    }
  };

  componentDidMount() {
    const { getPartners } = this.props;
    getPartners().then((res) => {
      if (res) {
        const {
          meta: { per_page, current_page, last_page },
        } = res;
        this.setState({
          pagination: {
            per_page,
            current_page,
            last_page,
          },
        });
      }
    });
  }

  render() {
    const {
      openAddDialog,
      openDeleteDialog,
      openEditDialog,
      editId,
      pagination: { current_page, last_page, per_page },
      paginationDisabled,
    } = this.state;
    const {
      partners,
      user: { permissions, is_super_admin },
    } = this.props;
    const paginatedPartners =
      partners.length >= current_page * per_page
        ? partners.slice((current_page - 1) * per_page, current_page * per_page - 1)
        : partners.slice(-per_page);

    return (
      <Layout
        title="شركاؤنا"
        addBtnText="إضافة شريك"
        showAddBtn={is_super_admin || permissions.includes(40)}
        handleBtnClicked={this.handleOpenAddDialog}
      >
        <PartnersList
          handleEditItem={this.handleOpenEditDialog}
          handleDeleteItem={this.handleOpenDeleteDialog}
          partners={partners}
        />
        {Partners && (
          <div className="my-3 d-flex justify-content-center align-items-center flex-grow-1">
            <Pagination
              variant="outlined"
              shape="rounded"
              count={last_page}
              page={current_page}
              boundaryCount={2}
              onChange={this.handlePaginationChange}
              disabled={paginationDisabled}
            />
          </div>
        )}
        <AddPartnerModal open={openAddDialog} handleClose={this.handleCloseAddDialog} />
        <EditPartnerModal open={openEditDialog} handleClose={this.handleCloseEditDialog} editId={editId} />
        <ConfirmDeleteModal
          open={openDeleteDialog}
          handleClose={this.handleCloseDeleteDialog}
          text="هل تريد حذف الشريك؟"
          handleDelete={this.handleConfirmDelete}
        />
      </Layout>
    );
  }
}

const mapStateToProps = ({ partners, user }) => ({ partners, user });

const mapDispatchToProps = (dispatch) => ({
  getPartners: () => dispatch(handleReceivePartners()),
  deletePartner: (id) => dispatch(handleDeletePartner(id)),
  getPartnersPagination: (page) => dispatch(handlePaginatePartners(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Partners);
