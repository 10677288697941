import { RECEIVE_QR_CODES, CREATE_QR_CODE, EDIT_QR_CODE, DELETE_QR_CODE } from "../actions/qrCodes";

export default function qrCodes(state = [], action) {
  switch (action.type) {
    case RECEIVE_QR_CODES:
      return action.qrCodes;
    case CREATE_QR_CODE:
      return [action.qrCode, ...state];
    case EDIT_QR_CODE:
      return state.map((qrCode) => (qrCode.id === action.id ? Object.assign(qrCode, action.data) : qrCode));
    case DELETE_QR_CODE:
      return state.filter((admin) => admin.id !== action.id);
    default:
      return state;
  }
}
