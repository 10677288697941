import React from "react";
import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from "@material-ui/core";
import JobsTableRow from "./JobsTableRow";
import PropTypes from "prop-types";

const JobsTable = ({ handleDeleteItem, jobs, pagination }) => (
  <TableContainer component={Paper} className="table-responsive">
    <Table className="table table-fixed">
      {jobs.length === 0 ? (
        <TableBody>
          <TableRow>
            <TableCell align="center" className="p-4">
              لا توجد وظائف
            </TableCell>
          </TableRow>
        </TableBody>
      ) : (
        <>
          <TableHead>
            <TableRow className="last-news__theader">
              <TableCell align="center">#</TableCell>
              <TableCell align="center">عنوان الوظيفة</TableCell>
              <TableCell align="center">تفاصيل الوظيفة</TableCell>
              <TableCell align="center">المكان</TableCell>
              <TableCell align="center">افعال</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.map((job, index) => (
              <JobsTableRow
                {...job}
                index={index + 1}
                handleDeleteItem={() => handleDeleteItem(job.id)}
                key={job.id}
                pagination={pagination}
              />
            ))}
          </TableBody>
        </>
      )}
    </Table>
  </TableContainer>
);

JobsTable.propTypes = {
  handleDeleteItem: PropTypes.func.isRequired,
  jobs: PropTypes.array.isRequired,
};

export default JobsTable;
