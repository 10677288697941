import { showLoading, hideLoading } from "react-redux-loading-bar";
import { getJobs, storeJob, destroyJob, updateJob, getJobDetails, getJobsPage } from "../utils/api";
import { showMessageAlert } from "./messageAlert";

export const RECEIVE_JOBS = "RECEIVE_JOBS";
export const CREATE_JOB = "CREATE_JOB";
export const EDIT_JOB = "EDIT_JOB";
export const DELETE_JOB = "DELETE_JOB";
export const GET_JOB_DETAILS = "GET_JOB_DETAILS";
export const PAGINATE_JOBS = "PAGINATE_JOBS";

const receiveJobs = (jobs) => ({
  type: RECEIVE_JOBS,
  jobs,
});

const createJob = (job) => ({
  type: CREATE_JOB,
  job,
});

const editJob = (id, data) => ({
  type: EDIT_JOB,
  id,
  data,
});

const deleteJob = (id) => ({
  type: DELETE_JOB,
  id,
});

const receiveJobDetails = (job) => ({
  type: GET_JOB_DETAILS,
  job,
});

const paginateJobs = (jobs) => ({
  type: PAGINATE_JOBS,
  jobs,
});

export const handleReceiveJobs = () => (dispatch) => {
  dispatch(showLoading());
  return getJobs()
    .then((res) => res.json())
    .then((res) => {
      dispatch(receiveJobs(res.data));
      dispatch(hideLoading());
      return res;
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handlePaginateJobs = (page) => (dispatch) => {
  dispatch(showLoading());
  return getJobsPage(page)
    .then((res) => res.json())
    .then((res) => {
      dispatch(paginateJobs(res.data));
      dispatch(hideLoading());
      return res;
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleReceiveJobDetails = (id) => (dispatch) => {
  dispatch(showLoading());
  getJobDetails(id)
    .then((res) => res.json())
    .then((res) => {
      dispatch(receiveJobDetails(res.data));
      dispatch(hideLoading());
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleCreateJob = (data) => (dispatch) => {
  dispatch(showLoading());
  storeJob(data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(createJob(res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم إضافة الوظيفة بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
    });
};

export const handleEditJob = (id, data) => (dispatch) => {
  dispatch(showLoading());
  updateJob(id, data)
    .then((res) => res.json())
    .then((res) => {
      dispatch(editJob(id, res.data));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم تعديل الوظيفة بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};

export const handleDeleteJob = (id) => (dispatch) => {
  dispatch(showLoading());
  destroyJob(id)
    .then((res) => res.json())
    .then((res) => {
      dispatch(deleteJob(id));
      dispatch(hideLoading());
      dispatch(showMessageAlert("تم حذف الوظيفة بنجاح", "success"));
    })
    .catch((e) => {
      dispatch(showMessageAlert(e.message, "error"));
      dispatch(hideLoading());
    });
};
