import React from "react";
import PropTypes from "prop-types";
import { TableRow, TableCell } from "@material-ui/core";

const RequestModelAnswer = ({ question, answer }) => (
  <TableRow>
    <TableCell align="right">{question}:</TableCell>
    <TableCell align="right">{typeof answer === "string" ? answer : Object.values(answer).join(" ،")}</TableCell>
  </TableRow>
);

RequestModelAnswer.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

export default RequestModelAnswer;
