import React, { Component } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NewsImageUpload from "../last-news/NewsImageUpload";
import CloseButton from "./CloseButton";
import moment from "moment";
import { handleEditNews } from "../../actions/news";
import { uploadMedia } from "../../utils/api";
import "moment/locale/en-au";

class EditNewsModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  state = {
    title_ar: "",
    details_ar: "",
    title_en: "",
    details_en: "",
    submitDisabled: true,
    files: [],
    imagePath: "",
    imageLoading: true,
  };

  handleSubmitDisabled = () => {
    const { title_ar, details_ar, title_en, details_en, submitDisabled, imagePath } = this.state;
    if (!title_ar || !title_en || !imagePath || (!details_ar && !details_en && !submitDisabled))
      this.setState({ submitDisabled: true });
    if (title_ar && title_en && imagePath && details_ar && details_en && submitDisabled)
      this.setState({ submitDisabled: false });
  };
  handleTitleArChange = (e) => {
    this.setState({ title_ar: e.target.value }, this.handleSubmitDisabled);
  };
  handleDetailsArChange = (e) => {
    this.setState({ details_ar: e.target.value }, this.handleSubmitDisabled);
  };
  handleTitleEnChange = (e) => {
    this.setState({ title_en: e.target.value }, this.handleSubmitDisabled);
  };
  handleDetailsEnChange = (e) => {
    this.setState({ details_en: e.target.value }, this.handleSubmitDisabled);
  };
  handleSetFiles = (files) => {
    this.setState(
      { files },
      () => {
        const { files } = this.state;
        const form = new FormData();
        form.append("file", files[0]);
        form.append("mime_type", "image");

        uploadMedia(form)
          .then((res) => res.json())
          .then((res) => {
            this.setState({ imagePath: res.path, imageLoading: false });
          });
      },
      this.handleSubmitDisabled
    );
  };

  componentDidUpdate(oldProps) {
    if (oldProps.targetNews !== this.props.targetNews) {
      const { targetNews } = this.props;
      const newsTitle_ar = targetNews ? targetNews.title.ar : "";
      const newsContent_ar = targetNews ? targetNews.content.ar : "";
      const newsTitle_en = targetNews ? targetNews.title.en : "";
      const newsContent_en = targetNews ? targetNews.content.en : "";
      const newsImage = targetNews ? targetNews.image : "";
      const published_at = targetNews ? targetNews.published_at : undefined;
      this.setState(
        {
          title_ar: newsTitle_ar,
          details_ar: newsContent_ar,
          title_en: newsTitle_en,
          details_en: newsContent_en,
          imagePath: newsImage,
          published_at: published_at,
        },
        this.handleSubmitDisabled
      );
    }
  }

  componentDidMount() {
    const { targetNews } = this.props;
    if (targetNews)
      this.setState(
        {
          title_ar: targetNews.title.ar,
          details_ar: targetNews.content.ar,
          title_en: targetNews.title.en,
          details_en: targetNews.content.en,
          imagePath: targetNews.image,
        },
        this.handleSubmitDisabled
      );
  }

  handleSubmit = () => {
    const { updateNews, handleClose, editId } = this.props;
    const { title_ar, details_ar, title_en, details_en, imagePath, published_at } = this.state;
    const data = {
      title: {
        ar: title_ar,
        en: title_en,
      },
      content: {
        ar: details_ar,
        en: details_en,
      },
      published_at: published_at || moment().locale("en").format("YYYY-MM-DD HH:mm:ss"),
      image: imagePath,
    };
    updateNews(editId, data);
    handleClose();
  };

  render() {
    const { open, handleClose } = this.props;
    const { title_ar, details_ar, title_en, details_en, submitDisabled, files, imagePath, imageLoading } = this.state;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: "dialog",
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <CloseButton handleClose={handleClose} />
        <DialogTitle id="responsive-dialog-title" className="text-center">
          تعديل الخبر
        </DialogTitle>
        <DialogContent>
          <div className="form-group">
            <NewsImageUpload files={files} setFiles={this.handleSetFiles} loading={imageLoading} image={imagePath} />
          </div>
          <div className="form-group">
            <TextField
              type="text"
              placeholder="عنوان الخبر بالعربية"
              className="form-control"
              required
              value={title_ar}
              onChange={this.handleTitleArChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              type="text"
              placeholder="عنوان الخبر بالانجليزية"
              className="form-control"
              required
              value={title_en}
              onChange={this.handleTitleEnChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              rows={3}
              placeholder="النص بالعربية"
              fullWidth
              required
              value={details_ar}
              onChange={this.handleDetailsArChange}
              variant="outlined"
              size="small"
              multiline
            />
          </div>
          <div className="form-group">
            <TextField
              rows={3}
              placeholder="النص بالانجليزية"
              fullWidth
              required
              value={details_en}
              onChange={this.handleDetailsEnChange}
              variant="outlined"
              size="small"
              multiline
            />
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center align-items-center">
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
            className="dialog__btn"
            disabled={submitDisabled}
          >
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ news }, { editId }) => {
  const targetNews = news.find((item) => item.id === editId);
  return { targetNews };
};

const mapDispatchToProps = (dispatch) => ({
  updateNews: (id, data) => dispatch(handleEditNews(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditNewsModal);
