import React from "react";
import { TableContainer, Table, TableBody, TableRow, TableCell, Button } from "@material-ui/core";
import { GetAppOutlined } from "@material-ui/icons";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/ar";

const RequestDetailsTable = ({ jobApplication }) => (
  <TableContainer className="table-responsive">
    <Table className="table table-borderless">
      <TableBody>
        <TableRow>
          <TableCell align="right">الاسم</TableCell>
          <TableCell align="right">{jobApplication ? jobApplication.name : "Name"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">البريد الالكتروني</TableCell>
          <TableCell align="right">{jobApplication ? jobApplication.email : "Mohamed@gmail.com"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">رقم الجوال</TableCell>
          <TableCell align="right">{jobApplication ? jobApplication.phone : "0123456789"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">الدولة</TableCell>
          <TableCell align="right">
            {jobApplication ? jobApplication.country.name.ar : "المملكة العربية السعودية"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">تاريخ الميلاد</TableCell>
          <TableCell align="right">
            {jobApplication ? moment(jobApplication.birth_date).local("ar").format("L") : "13/3/1988"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">السيرة الذاتية</TableCell>
          <TableCell align="right">
            <Button
              color="primary"
              variant="contained"
              href={jobApplication ? jobApplication.cv : ""}
              download
              target="_blank"
              rel="noopener noreferrer"
              startIcon={<GetAppOutlined className="ml-2" />}
            >
              تحميل السيرة الذاتية
            </Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">ملاحظات</TableCell>
          <TableCell align="right">{jobApplication ? jobApplication.notes : "notes"}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

RequestDetailsTable.propTypes = {
  jobApplication: PropTypes.object,
};

export default RequestDetailsTable;
