import { RECEIVE_NEWS, CREATE_NEWS, EDIT_NEWS, DELETE_NEWS, GET_NEWS_DETAILS, PAGINATE_NEWS } from "../actions/news";
import sortBy from "sort-by";

export default function news(state = [], action) {
  switch (action.type) {
    case RECEIVE_NEWS:
      return action.news;
    case PAGINATE_NEWS: {
      return action.news;
    }
    case GET_NEWS_DETAILS:
      return [...state, action.news];
    case CREATE_NEWS:
      return [action.news, ...state];
    case EDIT_NEWS:
      return state.map((news) => (news.id === action.id ? Object.assign(news, action.data) : news));
    case DELETE_NEWS:
      return state.filter((news) => news.id !== action.id);
    default:
      return state;
  }
}
