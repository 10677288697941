import React, { Component } from "react";
import { validateEmail } from "../../utils/helperFunctions/validation-functions";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CloseButton from "./CloseButton";
import { handleCreateAdmin } from "../../actions/admins";

class AddSliderModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    roles: PropTypes.array.isRequired,
  };

  state = {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    // default is a placeholder
    roles: ["DEFAULT"],
    submitDisabled: true,
  };

  handleSubmitDisabled = () => {
    const { name, email, password, password_confirmation, submitDisabled, roles } = this.state;
    if (
      name &&
      email &&
      password &&
      password_confirmation &&
      password === password_confirmation &&
      roles[0] !== "DEFAULT" &&
      validateEmail(email)
      // submitDisabled
    ) {
      this.setState({ submitDisabled: false });
    } else if (submitDisabled === false) {
      this.setState({ submitDisabled: true });
    }
  };
  handleInputChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    this.setState({ [name]: value }, this.handleSubmitDisabled);
  };
  handleSelectChange = (e) => {
    var { name, value } = e.target;

    // remove default value which works as placeholder
    const defaultIndex = value.indexOf("DEFAULT");
    if (defaultIndex !== -1) {
      value.splice(defaultIndex, 1);
    }
    if (value.length == 0) {
      value = ["DEFAULT"];
    }
    this.setState({ [name]: value }, this.handleSubmitDisabled);
  };

  handleSubmit = () => {
    const { createAdmin, handleClose } = this.props;
    const { name, email, password, password_confirmation, roles } = this.state;
    const data = {
      name,
      email,
      password,
      password_confirmation,
      roles,
    };
    createAdmin(data);
    handleClose();
  };

  render() {
    const { open, handleClose, roles } = this.props;
    const { name, email, password, password_confirmation, submitDisabled } = this.state;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: "dialog",
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <CloseButton handleClose={handleClose} />
        <DialogTitle id="responsive-dialog-title" className="text-center">
          إضافة مدير جديد
        </DialogTitle>
        <DialogContent>
          <div className="form-group">
            <TextField
              type="text"
              className="form-control"
              required
              placeholder="الاسم "
              value={name}
              name="name"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              type="email"
              className="form-control"
              required
              placeholder="البريد الإلكتروني "
              value={email}
              name="email"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <Select
              className="form-control"
              required
              multiple
              value={this.state.roles}
              name="roles"
              onChange={this.handleSelectChange}
            >
              <MenuItem disabled value="DEFAULT">
                دور المدير
              </MenuItem>
              {roles.map((role) => {
                return (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>

          <div className="form-group">
            <TextField
              type="password"
              className="form-control"
              required
              placeholder="كلمة المرور "
              value={password}
              name="password"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <TextField
              type="password"
              className="form-control"
              required
              placeholder=" تأكيد كلمة المرور "
              value={password_confirmation}
              name="password_confirmation"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center align-items-center">
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
            className="dialog__btn"
            disabled={submitDisabled}
          >
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const dispatchStateToProps = (state) => {
  return {
    roles: state.roles,
  };
};
const mapDispatchToProps = (dispatch) => ({
  createAdmin: (data) => dispatch(handleCreateAdmin(data)),
});

export default connect(dispatchStateToProps, mapDispatchToProps)(AddSliderModal);
