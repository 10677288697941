import React, { Component } from "react";
import Layout from "../base/Layout";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
import AddRoleModal from "../modals/AddRoleModal";
import EditRoleModal from "../modals/EditRoleModal";
import RolesList from "../roles/rolesList";
import { Pagination } from "@material-ui/lab";
import { connect } from "react-redux";
import { handleReceiveRoles, handlePaginateRoles, handleDeleteRole } from "../../actions/roles";
import { handleReceivePermissions } from "../../actions/permissions";

class Roles extends Component {
  state = {
    openAddDialog: false,
    openDeleteDialog: false,
    deleteId: null,
    editId: null,
    pagination: {
      per_page: 15,
      current_page: 1,
      last_page: 1,
    },
    paginationDisabled: false,
  };
  handleOpenAddDialog = () => {
    this.setState({ openAddDialog: true });
  };
  handleCloseAddDialog = () => {
    this.setState({ openAddDialog: false });
  };
  handleOpenDeleteDialog = (id) => {
    this.setState({ openDeleteDialog: true, deleteId: id });
  };
  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDialog: false, deleteId: null });
  };
  handleOpenEditDialog = (id) => {
    this.setState({ editId: id });
  };
  handleCloseEditDialog = () => {
    this.setState({ editId: null });
  };

  handleConfirmDelete = () => {
    const { deleteRole } = this.props;
    const { deleteId } = this.state;
    deleteRole(deleteId);
  };

  handlePaginationChange = (e, current_page) => {
    const { last_page } = this.state.pagination;
    const { getAdminsPagination } = this.props;
    if (last_page >= current_page) {
      this.setState({ paginationDisabled: true });
      getAdminsPagination(current_page).then(() => {
        this.setState((prevState) => ({
          pagination: { ...prevState.pagination, current_page },
          paginationDisabled: false,
        }));
      });
    }
  };

  componentDidMount() {
    const { getRoles, getPermissions } = this.props;
    getRoles().then((res) => {
      if (res) {
        this.setState({
          pagination: { ...this.state.pagination, last_page: res.meta.last_page },
        });
      }
    });
    getPermissions();
  }

  render() {
    const {
      openAddDialog,
      openDeleteDialog,
      editId,
      pagination: { current_page, last_page, per_page },
      paginationDisabled,
    } = this.state;
    const {
      roles,
      user: { permissions, is_super_admin },
    } = this.props;
    return (
      <Layout
        title="أدوار المشرفين"
        addBtnText="إضافة دور"
        showAddBtn={is_super_admin || permissions.includes(48)}
        handleBtnClicked={this.handleOpenAddDialog}
      >
        <RolesList
          handleDeleteItem={this.handleOpenDeleteDialog}
          handleEditItem={this.handleOpenEditDialog}
          roles={roles}
        />
        {roles && (
          <div className="my-3 d-flex justify-content-center align-items-center flex-grow-1">
            <Pagination
              variant="outlined"
              shape="rounded"
              count={last_page}
              page={current_page}
              boundaryCount={2}
              onChange={this.handlePaginationChange}
              disabled={paginationDisabled}
            />
          </div>
        )}
        {is_super_admin || permissions.includes(48) ? (
          <AddRoleModal open={openAddDialog} handleClose={this.handleCloseAddDialog} />
        ) : (
          ""
        )}

        {editId && <EditRoleModal handleClose={this.handleCloseEditDialog} editId={editId} />}
        <ConfirmDeleteModal
          open={openDeleteDialog}
          handleClose={this.handleCloseDeleteDialog}
          text="هل تريد حذف الدور"
          handleDelete={this.handleConfirmDelete}
        />
      </Layout>
    );
  }
}

const mapStateToProps = ({ roles, user }) => ({ roles, user });

const mapDispatchToProps = (dispatch) => ({
  getRoles: () => dispatch(handleReceiveRoles()),
  getRolesPagination: (page) => dispatch(handlePaginateRoles(page)),
  deleteRole: (id) => dispatch(handleDeleteRole(id)),
  getPermissions: () => dispatch(handleReceivePermissions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
