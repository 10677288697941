import React from "react";
import { TableRow, TableCell, IconButton, Link } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
const statuses = [
  { id: 1, name: "نشط" },
  { id: 2, name: "قيد الانتظار" },
  { id: 3, name: "مرفوض" },
  { id: 4, name: "محظور" },
];
const AdminsListItem = ({
  handleDeleteItem,
  handleEditItem,
  id,
  email,
  name,
  status,
  user: { permissions, is_super_admin },
}) => (
  <TableRow>
    <TableCell align="center">{id}</TableCell>
    <TableCell align="center">{name}</TableCell>
    <TableCell align="center">
      <a href={`mailto:${email}`}>{email}</a>
    </TableCell>
    <TableCell align="center">
      {typeof status === "number" ? statuses.filter((item) => item.id === status)[0].name : status}
    </TableCell>

    <TableCell align="center">
      {is_super_admin || permissions.includes(17) ? (
        <IconButton aria-label="edit" onClick={handleEditItem}>
          <EditOutlined />
        </IconButton>
      ) : (
        ""
      )}

      {/* <IconButton aria-label="delete" onClick={handleDeleteItem}>
          <DeleteOutline color="error" />
        </IconButton> */}
    </TableCell>
  </TableRow>
);

AdminsListItem.propTypes = {
  handleDeleteItem: PropTypes.func.isRequired,
  handleEditItem: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  name: PropTypes.object.isRequired,
  image: PropTypes.string.isRequired,
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(AdminsListItem);
