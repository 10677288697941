import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CloseButton from "./CloseButton";
import { handleCreateRole } from "../../actions/roles";

class AddRoleModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    roles: PropTypes.array.isRequired,
  };

  state = {
    name: "",
    permissions: ["DEFAULT"],
    submitDisabled: true,
  };

  handleSubmitDisabled = () => {
    const { name, permissions, submitDisabled } = this.state;
    if (name && permissions[0] !== "DEFAULT") {
      this.setState({ submitDisabled: false });
    } else if (submitDisabled === false) {
      this.setState({ submitDisabled: true });
    }
  };
  handleInputChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    this.setState({ [name]: value }, this.handleSubmitDisabled);
  };
  handleSelectChange = (e) => {
    var { name, value } = e.target;

    // remove default value which works as placeholder
    const defaultIndex = value.indexOf("DEFAULT");
    if (defaultIndex !== -1) {
      value.splice(defaultIndex, 1);
    }
    if (value.length === 0) {
      value = ["DEFAULT"];
    }
    this.setState({ [name]: value }, this.handleSubmitDisabled);
  };

  handleSubmit = () => {
    const { createRole, handleClose } = this.props;
    const { name, permissions } = this.state;
    const data = {
      name,
      permissions,
    };
    createRole(data);
    handleClose();
  };

  render() {
    const { open, handleClose, availablePermissions } = this.props;
    const { name, permissions, submitDisabled } = this.state;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: "dialog",
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <CloseButton handleClose={handleClose} />
        <DialogTitle id="responsive-dialog-title" className="text-center">
          إضافة دور جديد
        </DialogTitle>
        <DialogContent>
          <div className="form-group">
            <TextField
              type="text"
              className="form-control"
              required
              placeholder="الاسم "
              value={name}
              name="name"
              onChange={this.handleInputChange}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="form-group">
            <Select
              className="form-control"
              required
              multiple
              value={permissions}
              name="permissions"
              onChange={this.handleSelectChange}
            >
              <MenuItem disabled value="DEFAULT">
                الأذونات
              </MenuItem>
              {availablePermissions.map((permission) => {
                return (
                  <MenuItem key={permission.id} value={permission.id}>
                    {permission.name_ar}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center align-items-center">
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
            className="dialog__btn"
            disabled={submitDisabled}
          >
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const dispatchStateToProps = (state) => {
  return {
    availablePermissions: state.permissions,
  };
};
const mapDispatchToProps = (dispatch) => ({
  createRole: (data) => dispatch(handleCreateRole(data)),
});

export default connect(dispatchStateToProps, mapDispatchToProps)(AddRoleModal);
