import React, { Component } from "react";
import { AddCircleOutline /* , MailOutline */ } from "@material-ui/icons";
import ProjectsTable from "../home/ProjectsTable";
import CountriesTable from "../home/CountriesTable";
import AddCountryModal from "../modals/AddCountryModal";
import EditCountryModal from "../modals/EditCountryModal";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
import EditProjectStateModal from "../modals/EditProjectStateModal";
//import { Badge, IconButton } from "@material-ui/core"
//import MessagesList from "../messages/MessagesList"
import HomeSlider from "../home/HomeSlider";
import { connect } from "react-redux";
import { handleReceiveCountries, handleDeleteCountry } from "../../actions/countries";
import { handleReceiveProjects, handleDeleteProject } from "../../actions/projects";

class HomeView extends Component {
  state = {
    openAddCountryDialog: false,
    openEditCountryDialog: false,
    editCountryId: null,
    openDeleteCountryDialog: false,
    deleteCountryId: null,
    openEditProjectDialog: false,
    editProjectId: null,
    openDeleteProjectDialog: false,
    deleteProjectId: null,
    openMessagesMenu: false,
  };

  handleOpenAddCountryModal = () => {
    this.setState({ openAddCountryDialog: true });
  };
  handleCloseAddCountryModal = () => {
    this.setState({ openAddCountryDialog: false });
  };
  handleOpenEditCountryDialog = (id) => {
    this.setState({ openEditCountryDialog: true, editCountryId: id });
  };
  handleCloseEditCountryDialog = () => {
    this.setState({ openEditCountryDialog: false, editCountryId: null });
  };
  handleOpenDeleteCountryDialog = (id) => {
    this.setState({ openDeleteCountryDialog: true, deleteCountryId: id });
  };
  handleCloseDeleteCountryDialog = () => {
    this.setState({ openDeleteCountryDialog: false, deleteCountryId: null });
  };

  handleOpenEditProjectDialog = (id) => {
    // this.setState({ openEditProjectDialog: true, editProjectId: id })
    this.setState({ editProjectId: id });
    this.props.history.push(`/projects/edit/${id}`);
  };
  handleCloseEditProjectDialog = () => {
    this.setState({ openEditProjectDialog: false, editProjectId: null });
  };
  handleOpenDeleteProjectDialog = (id) => {
    this.setState({ openDeleteProjectDialog: true, deleteProjectId: id });
  };
  handleCloseDeleteProjectDialog = () => {
    this.setState({ openDeleteProjectDialog: false, deleteProjectId: null });
  };

  handleCloseMessagesMenu = (event) => {
    if (this.messagesIcon.contains(event.target)) {
      return;
    }
    this.setState({ openMessagesMenu: false });
  };
  handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      this.setState({ openMessagesMenu: false });
    }
  };
  handleMessagesMenuToggle = () => {
    this.setState((prevState) => ({
      openMessagesMenu: !prevState.openMessagesMenu,
    }));
  };

  handleConfirmCountryDelete = () => {
    const { deleteCountry } = this.props;
    const { deleteCountryId } = this.state;
    deleteCountry(deleteCountryId);
  };

  handleConfirmProjectDelete = () => {
    const { deleteProject } = this.props;
    const { deleteProjectId } = this.state;
    deleteProject(deleteProjectId);
  };

  componentDidMount() {
    const {
      getCountries,
      getProjects,
      user: { permissions, is_super_admin },
    } = this.props;
    if (is_super_admin || permissions.includes(5)) {
      getCountries();
    }
    if (is_super_admin || permissions.includes(43)) {
      getProjects();
    }
  }

  render() {
    const {
      openAddCountryDialog,
      openEditCountryDialog,
      openDeleteCountryDialog,
      openEditProjectDialog,
      openDeleteProjectDialog,
      //openMessagesMenu,
      editCountryId,
      editProjectId,
    } = this.state;
    const {
      countries,
      projects,
      user: { permissions, is_super_admin },
    } = this.props;

    return (
      <>
        <div className="pb-5 container overflow-hidden">
          <div className="row px-4 pt-3 main-header justify-content-between align-items-center">
            <h2 className="page-title">إحصائيات عامة</h2>
            {/* <IconButton
              ref={(el) => (this.messagesIcon = el)}
              onClick={this.handleMessagesMenuToggle}>
              <Badge badgeContent={2} color="error">
                <MailOutline className="color-grayed" />
              </Badge>
            </IconButton>
            <MessagesList
              open={openMessagesMenu}
              handleClose={this.handleCloseMessagesMenu}
              anchorRef={this.messagesIcon}
              handleListKeyDown={this.handleListKeyDown}
            /> */}
          </div>
          <div className="row p-3">
            <div className="col-12">
              <h3 className="mb-3 page-subtitle">نظرة عامة</h3>
            </div>
          </div>
          <HomeSlider />
          <div className="row p-3">
            <div className="col-12 col-md-6">
              {is_super_admin || permissions.includes(6) ? (
                <button className="add-btn btn pr-0 mb-2" onClick={this.handleOpenAddCountryModal}>
                  <AddCircleOutline /> إضافة دولة جديدة
                </button>
              ) : (
                <button style={{ opacity: "0" }} className="add-btn btn pr-0 mb-2">
                  إضافة دولة جديدة
                </button>
              )}

              <CountriesTable
                handleEditItem={this.handleOpenEditCountryDialog}
                handleDeleteItem={this.handleOpenDeleteCountryDialog}
                countries={countries}
              />
            </div>
            <div className="col-12 col-md-6 mt-5 mt-md-0">
              <h6 className="mb-4 card__subtitle">المشاريع</h6>
              <ProjectsTable
                handleEditItem={this.handleOpenEditProjectDialog}
                handleDeleteItem={this.handleOpenDeleteProjectDialog}
                projects={projects}
              />
            </div>
          </div>
        </div>
        <AddCountryModal open={openAddCountryDialog} handleClose={this.handleCloseAddCountryModal} />

        <EditCountryModal
          open={openEditCountryDialog}
          handleClose={this.handleCloseEditCountryDialog}
          editId={editCountryId}
        />
        <ConfirmDeleteModal
          open={openDeleteCountryDialog}
          handleClose={this.handleCloseDeleteCountryDialog}
          text="هل تريد حذف الدولة؟"
          handleDelete={this.handleConfirmCountryDelete}
        />
        <EditProjectStateModal
          open={openEditProjectDialog}
          handleClose={this.handleCloseEditProjectDialog}
          editId={editProjectId}
        />
        <ConfirmDeleteModal
          open={openDeleteProjectDialog}
          handleClose={this.handleCloseDeleteProjectDialog}
          text="هل تريد حذف المشروع؟"
          handleDelete={this.handleConfirmProjectDelete}
        />
      </>
    );
  }
}

const mapStateToProps = ({ countries, projects, user }) => ({ countries, projects, user });

const mapDispatchToProps = (dispatch) => ({
  getCountries: () => dispatch(handleReceiveCountries()),
  deleteCountry: (id) => dispatch(handleDeleteCountry(id)),
  getProjects: () => dispatch(handleReceiveProjects()),
  deleteProject: (id) => dispatch(handleDeleteProject(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeView);
